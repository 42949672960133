import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  // TextInput,
  // Button,
  Platform,
  // Image,
  // TouchableOpacity,
  // TouchableWithoutFeedback,
  // KeyboardAvoidingView
} from "react-native";
import "../../header/style.css";
const TopNavSignIn = require("../../../components/src/TopNavSignIn").default;
// Customizable Area End
import CompanyProfileControllerWeb, {
  Props,
} from "./CompanyProfileController.Web";
import { Form, Input, Button, Row, Col } from "antd";
import NumberFormat from "react-number-format";
import { FormInstance } from "antd/lib/form";
// import {companySignUp} from "./CompanyProfileService.web.js"
export const companySignUp = require("./CompanyProfileService.web");

// const { Option } = Select;
export default class CompanyProfileWeb extends CompanyProfileControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  formRef = React.createRef<FormInstance>();

  onSubmit = async (values: any) => {
    this.setState({btnLoading:true})
    const result = await companySignUp.companySignUp(values);
    if (result.success) {
      this.setState({btnLoading:false})
      window.localStorage.setItem("cmpName", values.company_name);
      this.props.navigation.history.push("/PromoDecksSignUpWeb");
    } else {
      this.setState({btnLoading:false})
      console.log("error from companySignUp:", result.error);
    }
  };
  handleCancel = async () => {
    window.localStorage.getItem("requestUser")=="true"?(this.props.navigation.history.push("/RequestUser"),window.localStorage.setItem("requestUser","false")) :
    this.props.navigation.history.push("/PromoDecksSignUpWeb")
  };
  render() {
    return (
      <>
      
        <TopNavSignIn />
       
        <View style={{ width: "30%", margin: "auto", marginTop: "3em" }}>
          <View style={styles.headline}>
            {this.isPlatformWeb() ? (
              <Text style={styles.signUpText}>Company Profile</Text>
            ) : null}
          </View>
          <View>
            <Form
              // {...layout}
              layout="vertical"
              ref={this.formRef}
              name="control-ref"
              onFinish={this.onSubmit}
              id="custom-login-id"
              size="middle"
            >
               
              <Form.Item
                name="txn_id"
                label="Tax Id"
                rules={[{ required: true, message: "Tax id is required" }]}
              >
                <NumberFormat
                  format="##-#######"
                  placeholder="12-3456789"
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                />
              
              </Form.Item>
             
              <Form.Item
                name="company_name"
                label="Company Name"
                rules={[
                  { required: true, message: "Company Name is required" },
                ]}
                initialValue={window.localStorage.getItem("companyName")}
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  placeholder={this.labelCompany}
                />
              </Form.Item>{" "}
              <Form.Item
                name="address_line_1"
                label="Address Line1"
                rules={[
                  { required: true, message: "Address line-1 is required" },
                ]}
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  placeholder={this.labelAddressLine1}
                />
              </Form.Item>
              <Form.Item
                name="address_line_2"
                label="Address Line2"
               
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  placeholder={this.labelAddressLine2}
                />
              </Form.Item>
            
              <Form.Item
                name="city"
                label="City"
                rules={[{ required: true, message: "City is required" }]}
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  placeholder={this.labelcity}
                />
              </Form.Item>
              <Form.Item
                name="state"
                label="State/Province"
                rules={[
                  { required: true, message: "State/Province is required" },
                ]}
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  placeholder={this.labelstates}
                />
              </Form.Item>
             
              <Form.Item
                name="postal_code"
                label="Zip/Postal Code"
                rules={[
                  { required: true, message: "Zip/Postal code is required" },
                  { len: 5, message: "enter valid code" },
                ]}
              >
                <Input
                  type="number"
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  placeholder={this.labelzip}
                />
              </Form.Item>
              <Form.Item>
            
                <Row justify="center">
                  <Col span={1} />
                  <Col span={10}> 
                    <Button
                      style={{
                        borderColor: "#008cff",
                        width: "80px",
                        color: "#008cff",
                      }}
                      onClick={this.handleCancel}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col span={1} />
                  <Col span={10}>
                    <Button
                    loading={this.state.btnLoading}
                      type="primary"
                      htmlType="submit"
                      style={{  float:"right",width:"80px" }}
                    >
                      Save
                    </Button>
                  </Col>
                  <Col span={1} />
                </Row>
              </Form.Item>
            </Form>
        
          </View>
        </View> 
      </>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 400,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signInText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    marginRight: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10,
  },
  signUpText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    // marginLeft: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10,
  },
  signInTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginRight: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  signUpTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginLeft: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  // Customizable Area End
});
