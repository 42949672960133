import axios from "axios";
import { openNotification, openErrorNotification } from "../../../components/src/Notification.web";
export const configJSON = require("./config");

const userSignUp = async function (payload) {
  const token = localStorage.getItem("token");
  try {
    const data = {
      "data": {
        "attributes": payload
      }
    } 
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const findCompany = async function (payload) {
  const token = localStorage.getItem("token");
  try {
    var qstring = '';
    if (payload != "") {
      qstring = 'company_name=' + payload;
    }
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies?` + qstring,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token
        }
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e && e.response && e.response.data && e.response.data.message };
  }
};


export { userSignUp, findCompany };
