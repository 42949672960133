import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import search from './search'; 
import { createBrowserHistory } from 'history'
const history = createBrowserHistory();

export default combineReducers({
    router: connectRouter(history),
    search
});
