import React from "react";
import {
  Divider, Col, Button,Popconfirm, Modal, Checkbox, Row, Table, Select, Input, Form, Radio,
  Pagination
} from "antd";
import { FilterFilled, PlayCircleOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import "../../header/style.css";
import moment from "moment";
export const getChannels = require("../../dashboard/src/Service.web.js");
import MultiSelect from "react-multi-select-component";
// Customizable Area End
import MailOnlineRebateControllerWeb, {
  Props,
} from "./MailOnlineRebateController.Web";
import SideNav from "../../../components/src/SideNav";
// import ReactDragListView from "react-drag-listview"
export const getMailOnlineRebate = require("./Service.web");
const ReactDragListView = require("react-drag-listview").default;
const TopNav = require('../../../components/src/TopNav').default;
import EditPromotion from "./EditPromotion";
export const filterData = require("./Service.web");
export const deletePromotionData = require("./Service.web");
// const DragColumn = ReactDragListView?.DragColumn;
import { FormInstance } from "antd/lib/form";
import { openNotification, openErrorNotification } from "../../../components/src/Notification.web";
const { Option } = Select;
export const publishTemp = require("./Service.web");
export const downloadTemp = require("./Service.web");
export const getPromoDataById = require("./Service.web");
export default class MailOnlineRebateWeb extends MailOnlineRebateControllerWeb {
  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex: any, toIndex: any) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns,
        });
      },
      nodeSelector: "th",
    };
  }
  getData = async () => {
    const MailOnlineData = await getMailOnlineRebate.getMailOnlineRebate();
    if (MailOnlineData.data !== "") {
      this.setState({ mailOnlineRebate: MailOnlineData.data });
    } else {
      console.log("error from userSignUp:", MailOnlineData.error);
    }
  };
  formRef = React.createRef<FormInstance>();
  onFinish = async (values: any) => {
    const data = { ...values, is_published: true, channel: this.state.selected.map((data: any) => data.value) };
    const res = await publishTemp.publishTemp(data);
    if (res) {
      this.getTempHeaderData()
      openNotification(res.message, "success")
      this.setEditModalVisible(false);
    }
  };

  handleOk = () => {
    this.setEditModalVisible(false);
  };

  handleCancel = () => {
    this.setEditModalVisible(false);
  };
  onShowFinish = async (values: any) => {
    this.setState({ publishModal: false })
    if (values.show == "edit") {
      this.setState({ publishModal: true }), this.setEditModalVisible(false);
      this.setState({ showing: false })
    } else {
      this.setState({ publishModal: false }), this.setEditModalVisible(true);
      this.setState({ showing: false })
    }
    this.formRef.current?.resetFields();
  };

  handleShowOk = () => {
    this.setState({ showing: false });
  };

  handleShowCancel = () => {
    this.setState({ showing: false });
  };
  csvLinkEl: any = React.createRef();
  onDownload = async (values: any) => {
    const res = await downloadTemp.downloadTemp(values.type);
    if (values.type == "both") {
      if (res.status == "200") {
        this.setState({ downLoadModal: false }, () => {
          setTimeout(() => {
            this.state.pdfData ? this.csvLinkEl.current.link.click() : ""
          });
        });
        window.open(res && res.file_path)
      }
      else {
        this.setState({ downLoadModal: false }, () => {
          setTimeout(() => {
            this.state.pdfData ? this.csvLinkEl.current.link.click() : ""
          });
        });
        openErrorNotification(res.message, "error")
      }
    }
    else if (values.type == "pdf") {
      if (res.status == "200") {
        window.open(res && res.file_path)
        this.setState({ downLoadModal: false })
      } else {
        openErrorNotification(res.message, "error")
        this.setState({ downLoadModal: false })
      }
    }
  };
  onFinishFilter = async (values: any) => {
    const sort = values.sort
    const Id = localStorage.getItem("promo_id") 
    if (values?.Brand) {
      const data: any = {
        sortType: sort,
        column_name: "brand",
        selected_filter: values?.Brand,
        promoId: Id
      };
      const res = await filterData.filterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data })
      }
    } else if (values?.['Business Unit']) {
      const data: any = {
        sortType: sort,
        column_name: "business_unit",
        selected_filter: values?.['Business Unit'],
        promoId: Id
      };
      const res = await filterData.filterData(data);
      if (res) {
        this.setState({ data: res.Data })
        this.setModalVisible(false);
      }
    } else {
      console.log("'ss");
    }
  };
  getTempHeaderData = async () => {
    const data = await getPromoDataById.getPromoDataById();
    if (data.success) {
      this.setState({ tempHeaderData: data.Data, tempHeaderLoading: false })
    }
  };
  getAllChannels = async () => {
    const datas = await getChannels.getChannels();
    this.setState({
      options: datas &&
        datas.Data.map((data: any) => ({ label: data.name, value: data.id })), channelList: datas && datas.Data ? datas.Data : []
    })
  };
  selectCh = async (values: any) => {
    this.setState({ selected: values })
  }
  deletePromotion = async (id: any) => {
    this.setState({ tableLoading: true })
    const result = await deletePromotionData.deletePromotionData(id);
    if (result.success) {
      this.getCData(1) 
      openNotification("Delete promotion successfully ", "success");
    } else {
      console.log("error from updateUser:", result.error);
    }
  }
  render() {
    const { filterBrand, filterBusiness, tempHeaderData }: any = this.state;
    const role = localStorage.getItem("Role")
    return (
      <>
        <TopNav />
        <div className="main-content-div">

          <SideNav />


          <div className="main-content">

            {/* Customizable Area Start */}
            <View>
              <Row>
                <Col span={4} style={{ marginLeft: "0px" }}>
                  <Row>
                    <h3 style={{ fontWeight: "bolder" }}>
                      {tempHeaderData?.promotional_deck_name}
                    </h3>
                  </Row>

                  <Row>
                    <h4 style={{ fontWeight: "bold" }}>{tempHeaderData.promo_id}</h4>
                  </Row>
                </Col>
                <Col span={12} style={{ marginLeft: "0px" }}>
                  <Row>
                    <span style={{ fontWeight: "bold" }}>
                      {tempHeaderData ? "End Execution Details" : ""}
                    </span>
                        : {tempHeaderData.end_execution_detail}
                  </Row>
                  <Row>
                    <span style={{ fontWeight: "bold" }}>
                      {tempHeaderData ? "Dealer Execution Details" : ""}
                    </span>
                        : {tempHeaderData.dealer_execution_detail}
                  </Row>
                </Col>
                {tempHeaderData ? <Col
                  span={8}
                  style={{
                    // display: "flex",
                    padding: "10px",
                    alignItems: "center",
                    textAlign: "right"
                  }}
                >
                  <Button
                    type="primary"
                    ghost
                    onClick={() => this.setState({ downLoadModal: true })}
                    style={{ marginRight: "10px", borderRadius: "20px" }}
                  >
                    Download
                      </Button>
                  <Modal
                    destroyOnClose
                    closable={true}
                    centered
                    width="35%"
                    onOk={() => this.setState({ downLoadModal: false })}
                    onCancel={() => this.setState({ downLoadModal: false })}
                    visible={this.state.downLoadModal}
                    maskClosable={false}
                    footer={false}
                  >
                    <Col>
                      <CSVLink
                        headers={this.state.columnsDaTa}
                        filename={`${localStorage.getItem("promo_type")}.csv`}
                        data={this.state.data}
                        ref={this.csvLinkEl}
                      />
                      <Form
                        layout="vertical"
                        ref={this.formRef}
                        name="control-ref"
                        onFinish={this.onDownload}
                      >
                        <Row>
                          <h2>Download</h2>
                        </Row>
                        <Row justify="center">
                          <h4>Please select the file to download</h4>
                        </Row>
                        <Row justify="center">
                          <Form.Item name="type">
                            <Radio.Group>
                              <Row>
                                {/* <Col span={12}> */}
                                <Radio
                                  value="csv"
                                  onClick={() => this.setState({ csvData: true })}
                                  style={{ lineHeight: "32px" }}
                                >
                                  Excel/CSV
                                    </Radio>
                              </Row>
                              <Row>
                                {/* </Col> */}
                                {/* <Col span={12}> */}
                                <Radio
                                  value="pdf"
                                  onClick={() => this.setState({ csvData: false, pdfData: true })}
                                  style={{ lineHeight: "32px" }}
                                >
                                  Powerpoint/PDF
                                    </Radio>
                              </Row>
                              <Row>
                                {/* </Col>
                                    <Col span={12}> */}
                                <Radio
                                  value="both"
                                  onClick={() => this.setState({ csvData: false, pdfData: true })}
                                  style={{ lineHeight: "32px" }}
                                >
                                  Both
                                    </Radio>
                                {/* </Col> */}
                              </Row>
                            </Radio.Group>
                          </Form.Item>
                        </Row>
                        <Row>
                          <Col span={4}></Col>
                          <Col span={8}>
                            <Button
                              onClick={() =>
                                this.setState({ downLoadModal: false })
                              }
                              // type="primary"
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                                </Button>
                          </Col>
                          <Col span={8}>
                            {this.state.csvData ? <Button
                              onClick={() => this.setState({ downLoadModal: false })}
                              htmlType="submit"
                              type="primary"
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                                float: "right",
                              }}
                            >
                              <CSVLink data={this.state.data} headers={this.state.columnsDaTa} filename={`${localStorage.getItem("promo_type")}.csv`}>Download</CSVLink>
                            </Button> : <Button
                              // onClick={() => this.setState({ showing: false })}
                              htmlType="submit"
                              type="primary"
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                                float: "right",
                              }}
                            >
                              Download
                                </Button>}
                          </Col>
                          <Col span={4}></Col>
                        </Row>
                      </Form>
                    </Col>
                  </Modal>
                  {tempHeaderData.is_published ? window.localStorage.setItem("publish", "true") : window.localStorage.setItem("publish", "false")}
                  {tempHeaderData.is_published ?
                   <Popconfirm disabled={tempHeaderData.deactivate}
                   title="Sure to delete?"
                   onConfirm={() =>
                     this.deletePromotion(tempHeaderData.id)
                   }
                 >
                    {role == "AdminVendor" ?
                          <Button disabled={tempHeaderData.deactivate} style={{ marginLeft: "10px", borderRadius: "20px" }} danger>
                            Delete
                          </Button>
                          : role == "Vendor" ?
                            <Button disabled={tempHeaderData.deactivate} style={{ marginLeft: "10px", borderRadius: "20px" }} danger>
                              Delete
                            </Button>
                            : role == "Manufacturer" ?
                              <Button disabled={tempHeaderData.deactivate} style={{ marginLeft: "10px", borderRadius: "20px" }} danger>
                                Delete
                              </Button>
                              : role == "ChannelManager" ?
                                <Button disabled={tempHeaderData.deactivate} style={{ marginLeft: "10px", borderRadius: "20px" }} danger>
                                  Delete
                                </Button>
                                : ""
                        }
                   {/* <Button disabled={tempHeaderData.deactivate} style={{ marginLeft: "10px",borderRadius: "20px" }} danger>
                     Delete
                   </Button> */}
                 </Popconfirm>: <Button
                      type="primary"
                      ghost
                      style={{ borderRadius: "20px" }}
                      onClick={() => this.setState({ showing: true })}
                    // onClick={() => this.setEditModalVisible(true)}
                    >
                      Edit / Publish
                      </Button>}
                </Col>
                  : ""}</Row>
              <Modal
                destroyOnClose
                closable={true}
                centered
                width="35%"
                onOk={this.handleShowOk}
                onCancel={this.handleShowCancel}
                visible={this.state.showing}
                maskClosable={false}
                footer={false}
              >
                <Col>
                  <Form
                    layout="vertical"
                    ref={this.formRef}
                    name="control-ref"
                    onFinish={this.onShowFinish}
                  >
                    <Row>
                      <h2>Select Edit/Publish Promo Deck</h2>
                    </Row>
                    <Row>
                      <Form.Item name="show">
                        <Radio.Group>
                          <Row>
                            <Col span={12}>
                              <Radio
                                value="edit"
                                style={{ lineHeight: "32px" }}
                              >
                                Edit
                                  </Radio>
                            </Col>
                            <Col span={12}>
                              <Radio
                                value="publish"
                                style={{ lineHeight: "32px" }}
                              >
                                Publish
                                  </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                    <Row style={{}}>
                      {/* <Button
                            onClick={() => this.setEditModalVisible(false)}
                            // type="primary"
                            style={{ width: "80px", borderRadius: "10px" }}
                          >
                            Cancel
                          </Button> */}
                      <Col span={20}></Col>
                      <Col span={4}>
                        <Button
                          // onClick={() => this.setState({ showing: false })}
                          htmlType="submit"
                          type="primary"
                          style={{
                            width: "80px",
                            borderRadius: "10px",
                            float: "right",
                          }}
                        >
                          Apply
                            </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Modal>
              <EditPromotion type="Mail/Online Rebate" show={this.state.publishModal} />
              <Modal
                destroyOnClose
                closable={true}
                centered
                width="35%"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                visible={this.state.editModalVisible}
                maskClosable={false}
                footer={false}
              >
                <Col>
                  <Form
                    layout="vertical"
                    ref={this.formRef}
                    name="control-ref"
                    onFinish={this.onFinish}
                  >
                    <Row>
                      <h2>Publish Deck</h2>
                    </Row>
                    <Row>
                      <Form.Item
                        label="Promotional Deck Name"
                        name="promotional_deck_name"
                        initialValue={tempHeaderData.promotional_deck_name}
                        rules={[{ required: true, message: "please enter name" }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                            width: "150%",
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        name="year"
                        label="Select year"
                        rules={[{ required: true, message: "please select year" }]}
                      // rules={[{ required: true }]}
                      >
                        <Select
                          style={{ width: "110%" }}
                          placeholder="Select a Year"
                          // onChange={this.onYearChange}
                          allowClear
                        >
                          <Option value={moment().format("YYYY")}>{moment().format("YYYY")}</Option>
                          <Option value={moment().years() + 1}>{moment().years() + 1}</Option>
                          <Option value={moment().years() + 2}>{moment().years() + 2}</Option>
                        </Select>
                      </Form.Item>
                    </Row>
                    <Row style={{ marginBottom: "15px" }}>
                      <Col span={24}>
                        <label
                          htmlFor="email"
                          style={{
                            display: "block",
                            // marginTop: "15px",
                            marginBottom: "5px",
                          }}
                        >
                          Select the channel
                  </label>
                        <MultiSelect
                          onChange={this.selectCh}
                          value={this.state.selected}
                          // onChange={setSelected}
                          // labelledBy="Select"
                          // onSelect={onSelectOrder}
                          // onRemove={onRemoveOrder}
                          options={this.state.options}
                          labelledBy="name"
                        // displayValue="name"
                        // showCheckbox={true}
                        // placeholder="Select channels"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Form.Item name="quarter" rules={[{ required: true, message: "please select Quarter" }]} label="Select Quarter(S)">
                        <Radio.Group>
                          <Row>
                            <Col span={6}>
                              <Radio
                                value="quarter_1"
                                style={{ lineHeight: "32px" }}
                              >
                                Quarter 1
                                  </Radio>
                            </Col>
                            <Col span={6}>
                              <Radio
                                value="quarter_2"
                                style={{ lineHeight: "32px" }}
                              >
                                Quarter 2
                                  </Radio>
                            </Col>
                            <Col span={6}>
                              <Radio
                                value="quarter_3"
                                style={{ lineHeight: "32px" }}
                              >
                                Quarter 3
                                  </Radio>
                            </Col>
                            <Col span={6}>
                              <Radio
                                value="quarter_4"
                                style={{ lineHeight: "32px" }}
                              >
                                Quarter 4
                                  </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                    <Row style={{}}>
                      {/* <Button
                            onClick={() => this.setEditModalVisible(false)}
                            // type="primary"
                            style={{ width: "80px", borderRadius: "10px" }}
                          >
                            Cancel
                          </Button> */}
                      <Col span={20}></Col>
                      <Col span={4}>
                        <Button
                          // onClick={() => this.setEditModalVisible(false)}
                          htmlType="submit"
                          type="primary"
                          style={{
                            width: "80px",
                            borderRadius: "10px",
                            float: "right",
                          }}
                        >
                          Publish
                            </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Modal>
              <Modal
                // destroyOnClose
                closable={false}
                centered
                width="25%"
                visible={this.state.modalVisible}
                maskClosable={false}
                footer={false}
              >
                <Form
                  layout="vertical"
                  ref={this.formRef}
                  name="control-ref"
                  onFinish={this.onFinishFilter}
                >
                  {" "}
                  <Col style={{}}>
                    <Row>
                      {
                        <h2>
                          <PlayCircleOutlined
                            translate="yes"
                            style={{ marginRight: "5px" }}
                          />
                          {this.state.title}
                        </h2>
                      }
                    </Row>
                    <Divider style={{ margin: "0px" }} />
                    <Form.Item name="sort">
                      <Radio.Group>
                        <Row
                          style={{
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <Radio value="A-Z" style={{ marginRight: "5px" }}>
                            {"Sort A-Z"}
                          </Radio>
                        </Row>
                        <Row
                          style={{
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <Radio value="Z-A" style={{ marginRight: "5px" }}>
                            {"Sort Z-A"}
                          </Radio>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name={this.state.title} style={{ display: this.state.title == "Brand" ? "" : this.state.title == "Business Unit" ? "" : "none" }}>
                    <Checkbox.Group>
                      {this.state.title == "Brand"
                        ? (<><Row>
                          {
                            <h2>
                              <FilterFilled
                                translate="yes"
                                style={{ marginRight: "5px" }}
                              />
                              Filter By
                            </h2>
                          }
                        </Row>
                          <Divider style={{ margin: "0px" }} />{filterBrand &&
                            filterBrand.map((data: any) => (
                              <Row
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <Checkbox
                                  value={data}
                                  style={{ marginRight: "5px" }}
                                />
                                {data}
                              </Row>
                            ))}</>)
                        : this.state.title == "Business Unit" ? (<><Row>
                          {
                            <h2>
                              <FilterFilled
                                translate="yes"
                                style={{ marginRight: "5px" }}
                              />
                              Filter By
                            </h2>
                          }
                        </Row>
                          <Divider style={{ margin: "0px" }} />{filterBusiness &&
                            filterBusiness.map((data: any) => (
                              <Row
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <Checkbox
                                  value={data}
                                  style={{ marginRight: "5px" }}
                                />
                                {data}
                              </Row>
                            ))}</>) : ""}
                    </Checkbox.Group>
                  </Form.Item>
                    <Form.Item>
                      <Row style={{ display: "inherit" }}>
                        <Button
                          onClick={() => this.setModalVisible(false)}
                          // type="primary"
                          style={{ width: "80px", borderRadius: "10px" }}
                        >
                          Cancel
                            </Button>
                        <Button
                          // onClick={() => this.setModalVisible(false)}
                          type="primary"
                          htmlType="submit"
                          style={{
                            width: "80px",
                            borderRadius: "10px",
                            float: "right",
                          }}
                        >
                          Apply
                            </Button>
                      </Row>
                    </Form.Item>
                  </Col>
                </Form> </Modal>
                {ReactDragListView?.DragColumn &&
              <ReactDragListView.DragColumn {...this.dragProps}>
                <Table
                  columns={this.state.columns}
                  pagination={false}
                  dataSource={this.state.data}
                  bordered
                  loading={this.state.tableLoading}
                  scroll={{ x: 500 }}
                />
                <Pagination style={{ float: "right", marginTop: "15px" }} defaultCurrent={1} total={this.state.totalPage} onChange={this.pageChange} />

              </ReactDragListView.DragColumn>
  }</View>
            {/* Customizable Area End */}

          </div>
        </div>
      </>
    );
  }
  pageChange = (page: any) => {
     this.setState({ page: page }, () => { this.getCData(this.state.page) })
  }
  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getData()
    this.getTempHeaderData()
    this.getFilters()
    this.getAllChannels()
    this.getCData(1)
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  // Customizable Area End
});
