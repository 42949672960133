import React from "react";
import { Row, Col, Card, Button, Radio } from "antd";
import "antd/dist/antd.css";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View, 
  Platform, 
} from "react-native";
import "../../header/style.css";
import "./style.css";
// Customizable Area End
const TopNavSignIn = require("../../../components/src/TopNavSignIn").default;
import MultipleCompanyControllerWeb, {
  Props,
} from "./MultipleCompanyController.Web";
export const findCompany = require("./PromoDecksSignUpService.web");
export const companyList = require("./MultipleCompanyService.Web");
export default class MultipleCompanyWeb extends MultipleCompanyControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getCompany = this.getCompany.bind(this);
    // Customizable Area End
  }

  getCompany = async () => {
    const companyName = window.localStorage.getItem("companyName");
    const findComp = await findCompany.findCompany(companyName);
    if (findComp.data !== "") {
      this.setState({ companyList: findComp.data }); 
    } else {
      console.log("error from userSignUp:", findComp.error);
    }
  };

  handleCancel = async () => {
    window.localStorage.getItem("requestUser")=="true"?(this.props.navigation.history.push("/RequestUser"),window.localStorage.setItem("requestUser","false")) :
    this.props.navigation.history.push("/PromoDecksSignUpWeb")
  };

  handleSubmit = async () => {
    const cmpName =
      this.state.companyList &&
      this.state.companyList.find(
        (o: any) => o.attributes.txn_id === this.state.selectedCompany
      );
    window.localStorage.setItem(
      "cmpName",
      cmpName && cmpName.attributes && cmpName.attributes.company_name
    );
    window.localStorage.getItem("requestUser")=="true"?(this.props.navigation.history.push("/RequestUser"),window.localStorage.setItem("requestUser","false")):
    this.props.navigation.history.push("/PromoDecksSignUpWeb")
  };
  onChange = (e: any) => { 
    this.setState({
      selectedCompany: e.target.value,
    }); 
  };
  render() {
    return (
      <>
        
        <TopNavSignIn />
        
        <Row style={{width:"70%",marginLeft:"auto",marginRight:"auto"}}>
          <Row style={styles.headline}>
            <Col span={24} style={{textAlign:"center"}}>{this.isPlatformWeb() ? (
              <Text style={styles.signUpText}>
                There are multiple company matches
              </Text>
            ) : null}</Col>
            <Col span={24} style={{textAlign:"center",marginTop:"20px"}}><Text style={styles.titleWhySignUp}>
              {this.labelCompanyMatches}
            </Text></Col>
          </Row>
          <Row style={{display:"grid",width:"100%"}}>
            <Card bordered={false} bodyStyle={{ padding: "0px" }}>
              <Col>
               {window.localStorage.getItem("requestUser")=="true"?"" :<Button
                  type="primary"
                  style={{ width: "200px" }}
                  onClick={() =>
                    this.props.navigation.history.push("/CompanyProfileWeb")
                  }
                >
                  Add New Company
                </Button> }
              </Col>
            </Card>
            <Card bodyStyle={{ display: "flex" }} bordered={false}>
              <Col span={6}>{"Select"}</Col>
              <Col span={6}>{"Tax ID"}</Col>
              <Col span={6}>{"Company name"}</Col>
              <Col span={6} style={{ textAlign: "center" }}>
                {"Address"}
              </Col>
            </Card>
            <Radio.Group style={{minHeight:"200px"}}
              onChange={this.onChange}
              value={this.state.selectedCompany}
            >
              {this.state.companyList &&
                this.state.companyList.map((data: any) => (
                  <Card
                    key={data.id}
                    bodyStyle={{ display: "flex", fontWeight: "bold" }}
                    style={{ marginBottom: "15px" }}
                  >
                     <Radio
                        value={data.attributes.txn_id}
                        style={{ display: "flex", width: "100%" }}
                      >
                        <Row>
                          <Col span={4} />
                          <Col span={8}>{data.attributes.txn_id}</Col>
                          <Col span={6}>{data.attributes.company_name}</Col>
                          <Col span={6} style={{ textAlign: "center" }}>
                            {
                              (data.attributes.address_line_1,
                              data.attributes.address_line_1,
                              data.attributes.city,
                              data.attributes.state)
                            }
                          </Col>
                        </Row>
                      </Radio> 
                    
                  </Card>
                ))}
            </Radio.Group>
            <Row style={{ marginTop: "3em" }}>
              <Col span={4} />
              <Col span={8}>
                <Button
                  style={{
                    borderColor: "#008cff",
                    width: "200px",
                    color: "#008cff",
                  }}
                  onClick={this.handleCancel}
                >
                  Back
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  type="primary"
                  style={{ width: "200px" }}
                  onClick={this.handleSubmit}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
             
          </Row>
          </Row>
         
      </>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getCompany();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 950,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 15,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signInText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    marginRight: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10,
  },
  signUpText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    marginLeft: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10,
  },
  signInTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginRight: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  signUpTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginLeft: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  // Customizable Area End
});
