import React from "react";

// Customizable Area Start
import {
  // StyleSheet,
  // Text,
  View
  // TextInput,
  // Button,
  // ScrollView,
  // Platform
  // Image,
  // TouchableOpacity,
  // TouchableWithoutFeedback,
  // KeyboardAvoidingView
} from "react-native";
import "../../header/style.css";
// Customizable Area End
const TopNavSignIn = require("../../../components/src/TopNavSignIn").default;
import VerifyEmailControllerWeb, { Props } from "./VerifyEmailController.Web";
import { Spin ,Row,Col} from "antd";
// import { FormInstance } from "antd/lib/form";
import { openNotification } from "../../../components/src/Notification.web";
// import {userForgotPass} from "./VerifyEmailService.web.js"
export const activateUser = require("./VerifyEmailService.web");
// import axios from "axios";
// const { Option } = Select;
export default class VerifyEmailWeb extends VerifyEmailControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  verifyAcc = async () => {
    const vToken = this.props.navigation.history.location.pathname
      .split("/")
      .pop();
    const result = await activateUser.activateUser(vToken);
    if (result.success) {
      openNotification(result.message, "success");
      this.props.navigation.history.push("/");
    } else {
      this.props.navigation.history.push("/");
    }
  };
  
  render() {
    return (
      <>
       
        <TopNavSignIn />
       
        <Row align="middle" justify="center"><Col>
          <Spin style={{ marginTop: "20%" }} /></Col>
        </Row>
         
      </>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.verifyAcc();
    // Customizable Area End
  }
}
