Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.serverURL="https://promdecks-23137-ruby.b23137.dev.eastus.az.svc.builder.cafe/";
exports.dashboardGetUrl = "/dashboard/dashboard";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.labelCompany="Company Name";
exports.labelEmail="Email"
exports.labelUsername = "Username"; 
exports.labelLastname = "Last Name";
exports.labelVendor = "Vendor Name";
exports.labelPassword = "Password";
exports.labelRePassword = "Confirm Password";
// Customizable Area End