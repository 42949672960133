import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import "../../header/style.css";
// Customizable Area End
import RetailerAdminAdministrationControllerWeb, {
  Props,
} from "./RetailerAdminAdministrationController.Web";
const ReactDragListView = require("react-drag-listview").default;
import {
  Tabs,
  Divider,
  Card,
  Col,
  Button,
  Modal,
  Checkbox,
  Row,
  Switch,
  Input,
  Radio,
  Form,
  Select,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  WarningTwoTone,
  HomeFilled,
  CarryOutFilled,
  SearchOutlined,
} from "@ant-design/icons";
const TopNav = require("../../../components/src/TopNav").default;
const { TabPane } = Tabs;
const { DragColumn } = ReactDragListView;
const { Option } = Select;
export const inviteVendor = require("./Service.web");
export const inviteCaUser = require("./Service.web");

export const vendorToggle = require("./Service.web");
export const searchVendor = require("./Service.web");
export const selectUser = require("./Service.web");
export const selectVendor = require("./Service.web");
export const deleteVendor = require("./Service.web");
export const deleteRUser = require("./Service.web");
export const getVendorReqData = require("./Service.web");
export const vendorRequest = require("./Service.web");
import EditableTable from "./UsersTable";
import { FormInstance } from "antd/lib/form";
import { openNotification } from "../../../components/src/Notification.web";
import SideNav from "../../../components/src/SideNav";
export default class RetailerAdminAdministrationWeb extends RetailerAdminAdministrationControllerWeb {
  formRef = React.createRef<FormInstance>();

  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex: any, toIndex: any) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns,
        });
      },
      nodeSelector: "th",
    };
  }
  setModalVisible(modalVisible: any) {
    this.setState({ modalVisible });
  }
  setDeleteModalVisible(deletemodalVisible: any) {
    this.setState({ deletemodalVisible });
  }
  setVendorModalVisible(vendorModalVisible: any) {
    this.setState({ vendorModalVisible });
  }
  // Customizable Area Start
  onFinish = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = await selectUser.selectUser(values);
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.getUserList();
      this.setModalVisible(false);
    } else {
      this.setState({ btnLoading: false })
    }
  };
  onVendorFinish = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = values == {} ? "" : await selectVendor.selectVendor(values.vender);
    if (datas) {
      this.setState({ btnLoading: false })
      openNotification(datas.response.data.message, "success");
      this.setVendorModalVisible(false);
      this.getVenderList();
      this.getVendorList()
    } else {
      this.setState({ btnLoading: false })
    }

  };
  async handleDeleteVendor() {
    this.setState({ btnLoading: true })
    const id = this.state.editId;
    const datas = await deleteVendor.deleteVendor(id);
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.getVenderList();
      this.setDeleteModalVisible(false);
    } else {
      this.setState({ btnLoading: false })
    }
  }
  async handleDeleteUser() {
    const id = this.state.editId;
    const datas = await deleteRUser.deleteRUser(id);
    if (datas) {
      openNotification(datas.message, "success")
      this.getUserList();
      this.setDeleteModalVisible(false);
    }
  }
  // Customizable Area End
  handleSearch = async (e: any) => {
    if (e.target.value == "") {
      this.getVendorList()
    } else {
      const datas = await searchVendor.searchVendor(e.target.value);
      if (datas.status == "ok") {
        this.setState({ vendorList: datas && datas.vendors });
      } else {
        this.getVendorList()
      }
    }
  };
  getRetailersRequestData = async () => {
    const datas = await getVendorReqData.getVendorReqData();
    this.setState({ vendorReqData: datas && datas.data && datas.data });
    if (datas) {
    }
  };
  onRetailerRequest = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = await vendorRequest.vendorRequest(this.state.editStatusData);
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.getVenderList();
      this.getVendorList()
      this.getRetailersRequestData();
      this.setState({
        editModalVisible: false,
        editStatusData: { email: "", status: "", id: "" },
      });
    } else {
      this.setState({ btnLoading: false })
    }

  };
  onChange = (e: any, data: any) => {
    this.setState({
      editStatusData: { email: data.email, status: e.target.value, id: data.id },
    });
  };
  handletoggle = async (id: any) => {
    const datas = await vendorToggle.vendorToggle(id);
    if (datas) {
      openNotification(datas.message, "success")
      this.getVenderList();
    }
  };
  onSubmitInvite = async (values: any) => {
    this.setState({ btnLoading: true })
    const result = await inviteVendor.inviteVendor(values);
    if (result.success) {
      this.setState({ btnLoading: false })
      openNotification(result.message, "success")
      this.formRef.current?.resetFields();
      this.setState({ inviteModalVisible: false })
    } else {
      this.setState({ btnLoading: false })
    }
  };
  onSubmitInviteCaUser = async (values: any) => {
    this.setState({ btnLoading: true })
    const result = await inviteCaUser.inviteCaUser(values);
    if (result.success) {
      this.setState({ btnLoading: false })
      openNotification(result.message, "success")
      this.formRef.current?.resetFields();
      this.setState({ inviteCompanyUser: false })
    } else {
      this.setState({ btnLoading: false })
    }
  };

  render() {
    const {
      showing,
      venderDetails,
      userList,
      vendorReqData,
      vendorList,
    }: any = this.state;
    const filterVendorList = this.state.vendorList && this.state.vendorList.filter(function (el: any) {
      return el.company_name != "" && el.company_name != null;
    });
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <TopNav />

        <div className="main-content-div">
          <SideNav />
          <div className="main-content">
            {/* Customizable Area Start */}
            <View>
              <h2 style={{ marginTop: "10px" }}>Admin</h2>
              <Divider style={{ margin: "0px" }} />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Vendors" key="1">
                  <Button
                    type="primary"
                    onClick={() => this.setVendorModalVisible(true)}
                  >
                    Add new Vendors
                  </Button>
                  <Button
                    type="primary"
                    onClick={
                      () =>
                        vendorReqData && vendorReqData.length > 0
                          ? this.setState({ editModalVisible: true })
                          : ""
                    }
                    style={{ marginLeft: "20px" }}
                  >
                    Pending request (
                    {vendorReqData && vendorReqData.length > 0
                      ? vendorReqData && vendorReqData.length
                      : 0}
                    )
                  </Button>
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      backgroundColor: "#EFEFEF",
                      margin: "15px",
                    }}
                    title={
                      <h2 style={{ marginBottom: "0px" }}>Vendor request</h2>
                    }
                    centered
                    width="70%"
                    visible={this.state.editModalVisible}
                    footer={false}
                    maskClosable={false}
                    onOk={() => (this.setState({ editModalVisible: false }), this.getVendorList())}
                    onCancel={() => (this.setState({ editModalVisible: false }), this.getVendorList())}
                  >
                    <Col style={{}}>
                      <Card bodyStyle={{ display: "flex" }} bordered={false}>
                        <Col span={4}>{"Vendor Name"}</Col>
                        <Col span={4}>{"First Name"}</Col>
                        <Col span={4}>{"Last Name"}</Col>
                        <Col span={6}>{"Email"}</Col>
                      </Card>
                      <Form
                        onFinish={this.onRetailerRequest}
                        ref={this.formRef}
                        name="control-ref"
                        id="custom-login-id"
                      >
                        {vendorReqData &&
                          vendorReqData.map((data: any) => (
                            <>
                              <Card
                                bodyStyle={{
                                  display: "flex",
                                  fontWeight: "bold",
                                }}
                                style={{ marginBottom: "15px" }}
                              >
                                <Col span={4}>{data.username}</Col>
                                <Col span={4}>{data.first_name}</Col>
                                <Col span={4}>{data.last_name}</Col>

                                <Col span={6}>
                                  {data.email}
                                  <Form.Item
                                    name="email"
                                    initialValue={data.email}
                                  >
                                    {" "}
                                  </Form.Item>
                                </Col>

                                <Form.Item
                                  name="status"
                                  initialValue={data.status}
                                >
                                  <Col style={{ fontSize: "20px" }}>
                                    <Radio.Group
                                      onChange={(e: any) =>
                                        this.onChange(e, data)
                                      }
                                      defaultValue={data.status}
                                    >
                                      <Radio value="accept">Accept</Radio>
                                      <Radio value="decline">Decline</Radio>
                                    </Radio.Group>
                                  </Col>
                                </Form.Item>
                              </Card>
                            </>
                          ))}
                        <Form.Item>
                          <Col style={{ textAlign: "center" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={this.state.btnLoading}
                              style={{
                                width: "200px",
                                borderRadius: "10px",
                              }}
                            >
                              Save
                            </Button>
                          </Col>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Modal>

                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2 style={{ marginBottom: "0px", color: "#0B518E" }}>
                          Select Vendor
                        </h2>
                        <h4 style={{ marginTop: "3em", textAlign: "center" }}>
                          Enter Vendor to request access to
                        </h4>
                      </>
                    }
                    centered
                    onCancel={() => (
                      this.setVendorModalVisible(false), this.getVendorList()
                    )}
                    onOk={() => (
                      this.setVendorModalVisible(false), this.getVendorList()
                    )}
                    width="50%"
                    maskClosable={false}
                    visible={this.state.vendorModalVisible}
                    footer={false}
                  >
                    <Form
                      onFinish={this.onVendorFinish}
                      ref={this.formRef}
                      name="control-ref"
                      id="custom-login-id"
                    >
                      <Col style={{ backgroundColor: "#EFEFEF" }}>
                        <Row style={{ paddingTop: "20px" }}>
                          <Col span={2} />
                          <Col span={20}>
                            <Form.Item
                              rules={[
                                {
                                  type: "string",
                                  whitespace: true,
                                },
                              ]}
                            >
                              <Input
                                onChange={(e) => this.handleSearch(e)}
                                placeholder="Search by Vendor name, Vendor email, Company name"
                                suffix={
                                  <SearchOutlined
                                    translate="yes"
                                    style={{ color: "rgba(0,0,0,.45)" }}
                                  />
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} />{" "}
                        </Row>
                        <Col
                          style={{
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          span={24}
                        >
                          {"Company Name"}
                        </Col>

                        {filterVendorList &&
                          filterVendorList.map((data: any) => (
                            <>
                              <Form.Item name="vender" rules={[{ required: true, message: 'Input should not be empty!' }]}>
                                <Row>
                                  <Col span={2} />
                                  <Col span={20}>
                                    <Radio.Group style={{ width: "100%" }}>
                                      <Card
                                        bodyStyle={{
                                          fontWeight: "bold",
                                        }}
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <Row align="middle"
                                          style={{ textAlign: "center" }}
                                        >
                                          <Col span={2}>
                                            <Radio
                                              value={data.id}
                                            />

                                          </Col>
                                          <Col span={20}>
                                            <Row justify="center">
                                              {data.company_name}</Row><Row justify="center">{data.txn_id}</Row>
                                          </Col>
                                          <Col span={2}></Col>
                                        </Row>
                                        {/* <Row style={{ textAlign: "center" }}><Col span={2}></Col>
                                          <Col span={20}><span style={{ fontSize: "12px" }}>{data.txn_id}</span></Col>
                                          <Col span={2}></Col></Row> */}
                                      </Card>
                                    </Radio.Group>
                                  </Col>
                                  <Col span={2} />
                                </Row>
                              </Form.Item>
                            </>
                          ))}

                        <Form.Item>
                          <Row
                            style={{
                              backgroundColor: "white",
                              paddingTop: "30px",
                            }}
                          >
                            <Col span={1} />
                            <Col span={7} style={{ textAlign: "center" }}>
                              <Button
                                onClick={() => (
                                  this.setState({ vendorModalVisible: false }),
                                  this.getVenderList()
                                )}
                                style={{
                                  width: "100px",
                                  borderRadius: "10px",
                                }}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col span={1} />
                            <Col span={7} style={{ textAlign: "center" }}>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={this.state.btnLoading}
                                style={{
                                  width: "100px",
                                  borderRadius: "10px",
                                }}
                              >
                                Confirm
                              </Button>
                            </Col>
                            <Col span={1} />
                            <Col span={7}>
                              <Button
                                onClick={() => (this.setState({ vendorModalVisible: false, inviteModalVisible: true })
                                )}
                                style={{ width: "100px", borderRadius: "10px" }}
                              >
                                Invite
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Form>
                  </Modal>
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2
                          style={{ marginBottom: "0px", color: "#0B518E" }}
                        >
                          Invite new Vendor
                        </h2>
                        <h4
                          style={{ marginTop: "3em", textAlign: "center" }}
                        >
                          To Invite a new vendor please provide the
                          following fields
                        </h4>
                      </>
                    }
                    centered
                    width="25%"
                    onCancel={() => this.setState({ inviteModalVisible: false })}
                    onOk={() => this.setState({ inviteModalVisible: false })}
                    visible={this.state.inviteModalVisible}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onSubmitInvite}
                      id="custom-login-id"
                    >
                      <Form.Item
                        name="email"
                        rules={[{ required: true }, { type: "email", message: "enter valid email" }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelEmail}
                        />
                      </Form.Item>
                      <Form.Item
                        name="company_name"
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelCompany}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={2}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              onClick={() => this.setState({ inviteModalVisible: false })
                              }
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={this.state.btnLoading}
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Send
                            </Button>
                          </Col>

                          <Col span={2}></Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  </Modal>
                  <Card bodyStyle={{ display: "flex" }} bordered={false}>
                    <Col span={4}>{"View"}</Col>
                    <Col span={6}>{"Vendor name"}</Col>
                    <Col span={12}>{"Brands"}</Col>
                  </Card>
                  {venderDetails &&
                    venderDetails.map((data: any) => (
                      <Card
                        bodyStyle={{ display: "flex", fontWeight: "bold" }}
                        style={{ marginBottom: "15px" }}
                      >
                        <Col span={4}>
                          <Switch defaultChecked={data.visible} onClick={() => this.handletoggle(data.id)} />
                        </Col>
                        <Col span={6}>
                          {data.first_name} {data.last_name}
                        </Col>
                        <Col span={12} style={{ overflowWrap: "break-word" }}>{data.brands}</Col>

                        <Col span={2} style={{ fontSize: "20px" }}>
                          <DeleteFilled style={{ cursor: "pointer" }}
                            translate="yes"
                            onClick={() => (
                              this.setDeleteModalVisible(true),
                              this.setState({ editId: data.request_id })
                            )}
                          />
                        </Col>
                      </Card>
                    ))}

                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2 style={{ marginBottom: "0px", color: "#0B518E" }}>
                          Please Confirm
                        </h2>
                      </>
                    }
                    centered
                    width="30%"
                    maskClosable={false}
                    onCancel={() => this.setState({ deletemodalVisible: false })}
                    onOk={() => this.setState({ deletemodalVisible: false })}
                    visible={this.state.deletemodalVisible}
                    footer={
                      <Row>
                        <Col span={2} />
                        <Col span={9} style={{ textAlign: "center" }}>
                          <Button
                            onClick={() => this.setDeleteModalVisible(false)}
                            style={{ width: "100px", borderRadius: "10px" }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col span={2} />
                        <Col span={9} style={{ textAlign: "center" }}>
                          <Button
                            onClick={() => this.handleDeleteVendor()}
                            type="primary"
                            htmlType="submit"
                            loading={this.state.btnLoading}
                            style={{ width: "100px", borderRadius: "10px" }}
                          >
                            Delete
                          </Button>
                        </Col>
                        <Col span={2} />
                      </Row>
                    }
                  >
                    <Col style={{}}>
                      <Row
                        style={{
                          fontSize: "100px",
                          justifyContent: "center",
                        }}
                      >
                        <WarningTwoTone translate="yes" />
                      </Row>
                      <Row style={{ justifyContent: "center" }}>
                        Please Confirm that you want to remove this item.
                      </Row>
                    </Col>
                  </Modal>
                </TabPane>
                <TabPane tab="Users" key="2">
                  {window.localStorage.getItem("Role") == "Buyer" ? <Button
                    style={{ marginLeft: "12px" }}
                    type="primary"
                    onClick={() => this.setState({ inviteCompanyUser: true })}
                  >
                    Invite company user
                  </Button> :
                    <Button
                      type="primary"
                      onClick={() => this.setModalVisible(true)}
                    >
                      Add new Users
                    </Button>
                  }

                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2
                          style={{ marginBottom: "0px", color: "#0B518E" }}
                        >
                          Invite company user
                        </h2>
                        <h4
                          style={{ marginTop: "3em", textAlign: "center" }}
                        >
                          To Invite a company user please provide the
                          following fields
                        </h4>
                      </>
                    }
                    centered
                    width="25%"
                    onCancel={() => this.setState({ inviteCompanyUser: false })}
                    onOk={() => this.setState({ inviteCompanyUser: false })}
                    visible={this.state.inviteCompanyUser}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onSubmitInviteCaUser}
                      id="custom-login-id"
                    >
                      <Form.Item
                        name="first_name"
                        rules={[
                          { required: true, message: "First name is required" },
                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="First name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="last_name"
                        rules={[
                          { required: true, message: "last name is required" },
                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="Last Name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="company_name"
                        rules={[{ required: true, message: "company name is required" }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelCompany}
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[{ required: true }, { type: "email", message: "enter valid email" }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelEmail}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={2}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              onClick={() =>
                                this.setState({ inviteCompanyUser: false })
                              }
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={this.state.btnLoading}
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Send
                            </Button>
                          </Col>

                          <Col span={2}></Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  </Modal>
                  <EditableTable data={this.state.userList} />
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",

                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2
                          style={{
                            marginBottom: "0px",
                            color: "#0B518E",
                          }}
                        >
                          New user
                        </h2>
                      </>
                    }
                    centered
                    width="35%"
                    visible={this.state.modalVisible}
                    onCancel={() => this.setModalVisible(false)}
                    onOk={() => this.setModalVisible(false)}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onFinish}
                      id="custom-login-id"
                    >
                      <Form.Item
                        name="first_name"
                        rules={[
                          { required: true, message: "First name is required" },
                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="First name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="last_name"
                        rules={[
                          { required: true, message: "last name is required" },
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="Last Name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true },
                          { type: "email", message: "enter valid email" },
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelEmail}
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true },
                          {
                            pattern: new RegExp(
                              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                            ),
                            message:
                              "Password must be 8 character and contains at least one A-Z, a-z, 0-9, @$%#",
                          },
                        ]}
                      >
                        <Input
                          type="password"
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelPassword}
                        />
                      </Form.Item>
                      <Form.Item
                        name="confirm_password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="password"
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelRePassword}
                        />
                      </Form.Item>
                      <Form.Item
                      >
                        <Input disabled
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                            color: "black"
                          }}
                          value="Buyer"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={2} />
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              onClick={() => this.setModalVisible(false)}
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col span={1} />
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={this.state.btnLoading}
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Save
                            </Button>
                          </Col>

                          <Col span={2} />
                        </Row>
                      </Form.Item>
                    </Form>
                  </Modal>
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2 style={{ marginBottom: "0px", color: "#0B518E" }}>
                          Please Confirm
                        </h2>
                      </>
                    }
                    centered
                    width="30%"
                    visible={this.state.deletemodalVisible}
                    maskClosable={false}
                    footer={
                      <Row>
                        <Col span={2} />
                        <Col span={9} style={{ textAlign: "center" }}>
                          <Button
                            onClick={() => this.setDeleteModalVisible(false)}
                            style={{ width: "100px", borderRadius: "10px" }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col span={2} />
                        <Col span={9} style={{ textAlign: "center" }}>
                          <Button
                            onClick={() => this.handleDeleteUser()}
                            type="primary"
                            style={{ width: "100px", borderRadius: "10px" }}
                          >
                            Delete
                          </Button>
                        </Col>
                        <Col span={2} />
                      </Row>
                    }
                  >
                    <Col style={{}}>
                      <Row
                        style={{
                          fontSize: "100px",
                          justifyContent: "center",
                        }}
                      >
                        <WarningTwoTone translate="yes" />
                      </Row>
                      <Row style={{ justifyContent: "center" }}>
                        Please Confirm that you want to remove this item.
                      </Row>
                    </Col>
                  </Modal>
                </TabPane>
              </Tabs>
            </View>
            {/* Customizable Area End */}
          </div>
        </div>
      </KeyboardAvoidingView>
    );
  }
  checkAuth = () => {
    const role = window.localStorage.getItem("Role");
    if (role == "AdminVendor") {
      this.props.navigation.history.goBack();
    } else if (role == "Vendor") {
      this.props.navigation.history.goBack();
    } else if (role == "Manufacturer") {
      this.props.navigation.history.goBack();
    } else if (role == "ChannelManager") {
      this.props.navigation.history.goBack();
    } else if (role == "AccountManager") {
      this.props.navigation.history.goBack();
    }
  };
  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getVenderList();
    this.getVendorList();
    this.getUserList();
    this.checkAuth();
    this.getRetailersRequestData();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  // Customizable Area End
});
