import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { withRouter,useHistory } from "react-router-dom";
import {
  Avatar,
  Modal,
  Col,
  Card,
  Row,
  Tabs,
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Select,
  Spin,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { setSearchData } from "../../web/src/redux/actions/search";
import {
  UserOutlined,
  SearchOutlined,
  BellFilled,
  LoginOutlined,
} from "@ant-design/icons";
import logo from "./assests/logoTop.png";
import { openNotification } from "./Notification.web";
import Cookies from "js-cookie";
import { connect } from "react-redux";

export const updateUser = require("../../blocks/email-account-registration/src/EmailLoginRegistrationService.Web");
export const updateUserAvatar = require("../../blocks/email-account-registration/src/EmailLoginRegistrationService.Web");
export const updateUserPassword = require("../../blocks/email-account-registration/src/EmailLoginRegistrationService.Web");
export const getProfile = require("../../blocks/email-account-registration/src/EmailLoginRegistrationService.Web");
export const searchData = require("../../blocks/email-account-registration/src/EmailLoginRegistrationService.Web");

const { TabPane } = Tabs;
const { Option } = Select;
const TopNav = (props) => {
  const [form] = Form.useForm();
  const [showing, setShowing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    getProfileData();
  }, []);
  const history=useHistory()
  const handleSearch = async (e) => {
    props.setSearchData(e.target.value);
    localStorage.setItem("search", e.target.value);
    // props.search(e.target.value)
  };

  const getProfileData = async (values) => {
    const result = await getProfile.getProfile();
    if (result.success) {
      setUserData(result.data);
      setLoading(false);
      // console.log(result.status);
      result && result.status == 401 ? history.push(`/`) : "";
    } else {
      result.error.response && result.error.response.status == 400
        ? history.push(`/`)
        : "";
    }
  };
  const onSubmit = async (values) => {
    const result = await updateUser.updateUser(values);
    if (result.success) {
      getProfileData();
      openNotification("personal info saved successfully", "success");
      setModalVisible(false);
      form.resetFields();
      result && result.status == 401 ? history.push(`/`) : "";
    } else {
      console.log("error from updateUser:", result.error);
    }
  };
  const onChangeAvtar = async (values) => {};

  const onChangePassword = async (values) => {
    const result = await updateUserPassword.updateUserPassword(values);
    if (result.success) {
      getProfileData();
      openNotification(result.message, "success");
      setModalVisible(false);
      form.resetFields();
      result && result.status == 401 ? history.push(`/`) : "";
    } else {
      console.log("error from updateUserPassword:", result.error);
    }
  };
  const [selectedFile, setSelectedFile] = useState();
  const [imgFile, setImgFile] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
      setImgFile(file);
    };

    reader.readAsDataURL(file);
  };

  const handleSubmission = async () => {
    const formData = new FormData();

    formData.append("attachment", selectedFile);
    const result = await updateUserAvatar.updateUserAvatar(formData);
    if (result.success) {
      getProfileData();
      openNotification(result.message, "success");
      setModalVisible(false);
      form.resetFields();
      result && result.status == 401 ? history.push(`/`) : "";
    } else {
      console.log("error from updateUserPassword:", result.error);
    }
  };
  return (
    <>
      <Row align="middle" className="main-header flex-nowrap">
        {loading ? (
          <Spin />
        ) : (
          <>
            <Col xs={2} sm={0} />
            <Col
              xs={8}
              sm={6}
              md={8}
              lg={9}
              xl={10}
              xxl={10}
              className="header-logo"
            >
              <Row>
                <div className="logo-container">
                  <img
                    src={logo}
                    height="30"
                    width="30"
                    style={{ marginRight: "10px" }}
                  />
                  <div>
                    <strong>PromoDecks</strong>
                  </div>
                </div>
              </Row>
            </Col>

            <Col
              xs={10}
              sm={15}
              md={15}
              lg={14}
              xl={13}
              xxl={12}
              className="header-account"
            >
              <Row>
                <Col
                  xs={0}
                  sm={6}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  className="header-search"
                >
                  <Input
                    onChange={(e) => handleSearch(e)}
                    name="search"
                    placeholder="Search Promotions by name"
                    suffix={
                      <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                    }
                  />
                </Col>
                <Col
                  xs={10}
                  sm={6}
                  md={10}
                  lg={9}
                  xl={8}
                  xxl={8}
                  onClick={() => setModalVisible(true)}
                >
                  <Row className="profile_items">
                    {" "}
                    <Col span={20}>
                      Hello {userData?.first_name} {userData?.last_name}{" "}
                    </Col>
                    <Col span={4}>
                      {userData?.image !== "" ? (
                        <Avatar
                          shape="circle"
                          src={userData?.image}
                          icon={
                            <UserOutlined
                              style={{ width: "30px", height: "30x" }}
                            />
                          }
                        />
                      ) : (
                        <Avatar
                          shape="circle"
                          icon={
                            <UserOutlined
                              style={{ width: "30px", height: "30x" }}
                            />
                          }
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} sm={1} md={2} lg={2} xl={2} xxl={2}>
                  <BellFilled
                    style={{ fontSize: "25px" }}
                    translate="yes"
                    onClick={() => history.push("/AlertWeb")}
                  />
                </Col>
                <Col xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                  <Button
                    type="text"
                    onClick={() => {
                      localStorage.clear();
                      Cookies.remove("Role");
                      Cookies.remove("token");
                      history.push("/");
                    }}
                    style={{ color: "white", borderColor: "transparent" }}
                  >
                    <LoginOutlined />
                    Log Out
                  </Button>
                </Col>
              </Row>
            </Col>
          </>
        )}
        <Modal
          destroyOnClose
          bodyStyle={{
            marginTop: "-20px",
            margin: "15px",
          }}
          title={<h2 style={{ marginBottom: "0px" }}>Edit profile account</h2>}
          centered
          width="85%"
          visible={modalVisible}
          maskClosable={false}
          footer={false}
          onCancel={() => (
            setModalVisible(false), setImgFile(""), setImagePreviewUrl("")
          )}
          onOk={() => (
            setModalVisible(false), setImgFile(""), setImagePreviewUrl("")
          )}
        >
          <Row>
            {showing ? (
              <Col span={6} style={{ padding: "10px" }}>
                <Card style={{ textAlign: "center" }}>
                  <Avatar
                    src={userData?.image}
                    size={100}
                    icon={<UserOutlined />}
                  />
                  <Row style={{ justifyContent: "center" }}>
                    <Col>
                      <h1 style={{ marginBottom: "-5px" }}>
                        {userData?.first_name} {userData?.last_name}
                      </h1>
                    </Col>
                  </Row>
                  <Row style={{ justifyContent: "center" }}>
                    <Col>
                      <h4 style={{ marginBottom: "-3px" }}>
                        {userData?.email}
                      </h4>
                    </Col>
                  </Row>
                  <Row style={{ justifyContent: "center" }}>
                    <Col>User Occupation</Col>
                  </Row>
                </Card>
              </Col>
            ) : (
              ""
            )}
            <Col span={showing ? 18 : 24} style={{ padding: "10px" }}>
              <Card>
                <Tabs
                  tabBarExtraContent={{
                    left: (
                      <h2 style={{ marginRight: "20px", color: "#1890ff" }}>
                        PROFILE ACCOUNT
                      </h2>
                    ),
                  }}
                >
                  <TabPane
                    tab={
                      <span
                        className="profileTabs"
                        onClick={() => setShowing(true)}
                      >
                        Personal Info
                      </span>
                    }
                    key="1"
                  >
                    <Form layout="vertical" onFinish={onSubmit} size="middle">
                      <Form.Item
                        label={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            First Name
                          </span>
                        }
                        name="first_name"
                      >
                        <Input type="text" placeholder={userData?.first_name} />
                      </Form.Item>
                      <Form.Item
                        label={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            Last Name
                          </span>
                        }
                        name="last_name"
                      >
                        <Input type="text" placeholder={userData?.last_name} />
                      </Form.Item>{" "}
                      <Form.Item
                        name="phone_number"
                        initialValue={userData?.phone_number}
                        label={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            Phone Number
                          </span>
                        }
                        rules={[
                          {
                            message: "Please enter your phone number!",
                          },
                        ]}
                      >
                        <PhoneInput
                          value={userData?.phone_number}
                          inputStyle={{
                            height: "32px",
                            width: "inherit",
                            borderRadius: "0px",
                          }}
                          country={"us"}
                        />
                      </Form.Item>
                      <Form.Item
                        name="role"
                        label={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            Role
                          </span>
                        }
                        hasFeedback
                        style={{ width: "60%" }}
                      >
                        <Select
                          disabled
                          placeholder={
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              {userData?.role}
                            </span>
                          }
                        >
                          <Option value="Manufacturer">Manufacturer</Option>
                          <Option value="Retailer">Retailer</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          type="primary"
                          style={{ marginRight: "20px" }}
                        >
                          Save Changes
                        </Button>
                        <Button onClick={() => setModalVisible(false)}>
                          Cancel
                        </Button>
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab={
                      <span
                        className="profileTabs"
                        onClick={() => setShowing(false)}
                      >
                        Change Photo
                      </span>
                    }
                    key="2"
                  >
                    <Row>
                      <p style={{ color: "black" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nulla blandit elit eget magna faucibus eleifend. Nam nec
                        magna non massa dictum ultrices id id elit. Donec ac
                        odio erat. Nam sodales sollicitudin mollis.{" "}
                      </p>
                    </Row>

                    <Row>
                      {imagePreviewUrl ? (
                        <img src={imagePreviewUrl} width="164" height="164" />
                      ) : (
                        <Avatar
                          src={userData?.image}
                          shape="square"
                          size={164}
                          icon={<UserOutlined />}
                        />
                      )}
                    </Row>
                    <Row
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                      className="uploadImg"
                    >
                      <input
                        type="file"
                        className="custom-file-input"
                        name="file"
                        onChange={changeHandler}
                      />
                    </Row>
                    <Row>
                      <Button
                        type="primary"
                        onClick={handleSubmission}
                        style={{ marginRight: "20px" }}
                      >
                        Submit
                      </Button>
                      <Button onClick={() => setModalVisible(false)}>
                        Cancel
                      </Button>
                    </Row>
                  </TabPane>
                  <TabPane
                    key="3"
                    tab={
                      <span
                        className="profileTabs"
                        onClick={() => setShowing(false)}
                      >
                        Change password
                      </span>
                    }
                  >
                    <Form
                      layout="vertical"
                      onFinish={onChangePassword}
                      size="middle"
                    >
                      <Form.Item
                        label={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            Current Password
                          </span>
                        }
                        name="current_password"
                        rules={[
                          {
                            required: true,
                            message: "Current Password is required",
                          },
                        ]}
                      >
                        <Input type="password" />
                      </Form.Item>
                      <Form.Item
                        label={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            New Password
                          </span>
                        }
                        name="new_password"
                        rules={[
                          {
                            required: true,
                            message: "New Password is required",
                          },
                        ]}
                      >
                        <Input type="password" />
                      </Form.Item>
                      <Form.Item
                        label={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            Re-type Password
                          </span>
                        }
                        name="retype_password"
                        rules={[
                          {
                            required: true,
                            message: "Re-type Password is required",
                          },
                        ]}
                      >
                        <Input type="password" />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          htmlType="submit"
                          type="primary"
                          style={{ marginRight: "20px" }}
                        >
                          Change password
                        </Button>
                        <Button onClick={() => setModalVisible(false)}>
                          Cancel
                        </Button>
                      </Form.Item>
                    </Form>
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Modal>
      </Row>
    </>
  );
};

const styles = StyleSheet.create({
  main: {
    backgroundColor: "#0b518e",
    width: "100%",
  },
});

// export default withRouter(TopNav);
const mapStateToProps = (state) => ({
  searchResult: state,
});

const mapDispatchToProps = (dispatch) => ({
  setSearchData: (data) => dispatch(setSearchData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNav);
