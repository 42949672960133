import { notification } from "antd";
import React from "react"
import EmailAccountRegistrationController from "../../blocks/email-account-registration/src/EmailAccountRegistrationController";
import "./assests/notification.css";
export const openNotification = (success: any, types: any) => {
  notification.open({
    message: types,
    type: types,
    duration: 3,
    description: success,
  });
};
export const openErrorNotification = (error: any, types: any) => {
  notification.open({
    message: <h1 style={{color:"red"}}>Error</h1>,
    type: types,
    duration: 0,
    description: error,
    style: { fontWeight: 600 ,color:"red"},
  });
};

export const openErrorNotificationForPromotions = (error: any, types: any) => {
  var errorMsg = error.replaceAll(",", "\n\n• ");
  var completeLineBreak = errorMsg.replace(":-", ":-\n\n• ");
  notification.open({
    message: <h1 style={{color:"red"}}>Error</h1>,
    type: types,
    duration: 0,
    description: completeLineBreak,
    style: { fontWeight: 600 ,color:"red"}
  });
};
