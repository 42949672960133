import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  // TextInput,
  // Button,
  // ScrollView,
  Platform
  // Image,
  // TouchableOpacity,
  // TouchableWithoutFeedback,
  // KeyboardAvoidingView
} from "react-native";
import "../../header/style.css";
// Customizable Area End
const TopNavSignIn = require("../../../components/src/TopNavSignIn").default;
import ForgotPasswordControllerWeb, {
  Props
} from "./ForgotPasswordController.web";
import { Form, Input, Button } from "antd";
import { FormInstance } from "antd/lib/form";
import { openNotification } from "../../../components/src/Notification.web";
// import {userForgotPass} from "./ForgotPasswordService.web.js"
export const userForgotPass = require("./ForgotPasswordService.web");
export const findCompany = require("./ForgotPasswordService.web");
export default class ForgotPasswordWeb extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  formRef = React.createRef<FormInstance>();
  onSubmit = async (values: any) => {
    const result = await userForgotPass.userForgotPass(values);
    if (result.success) {
      this.props.navigation.history.push("/");
      openNotification(result.message, "success");
    } else {
      console.log("error from userSignUp:", result.error);
      
    }
  };
  render() {
    return (
      <>
       
        <TopNavSignIn />
        <View style={{ width: "35%", margin: "auto" }}>
          <View style={styles.headline}>
            {this.isPlatformWeb() ? (
              <Text style={styles.signUpText}>Forgot Password</Text>
            ) : null}
            </View>
          <View>
            <Form 
              ref={this.formRef}
              name="control-ref"
              onFinish={this.onSubmit}
              id="custom-login-id"
              style={{ width: "80%", margin: "auto" }}
            >
              <Form.Item
                name="email"
                rules={[{ required: true,message:"Email is required" }]}
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black"
                  }}
                  // name="first_name"
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
              // name="email"
              // rules={[{ required: true }]}
              >
                <Button
                  type="primary"
                  // name="Continue"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Send Email
                </Button>
              </Form.Item>
               
            </Form>
          </View>
        </View>
        
      </>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    // marginBottom: 32,
    fontSize: 16,
    width: 300,
    // marginLeft: "auto",
    // marginRight: "auto",
    textAlign: "center",
    marginVertical: 8
    // marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    // marginTop: "100px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "40px"
  },
  signInText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    marginRight: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10
  },
  signUpText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    // marginLeft: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10
  },
  signInTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginRight: 50,
    paddingBottom: 10,
    opacity: 0.5
  },
  signUpTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginLeft: 50,
    paddingBottom: 10,
    opacity: 0.5
  }
  // Customizable Area End
});
