import React from "react";
// import { withRouter } from "react-router-dom";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  // TextInput,
  // Button,
  // ScrollView,
  Platform,
  // Image,
  // TouchableOpacity,
  // TouchableWithoutFeedback,
  // KeyboardAvoidingView
} from "react-native";
import { Link } from "react-router-dom";
// import axios from "axios";
// Customizable Area End
import * as qs from "query-string";
import EmailLoginRegistrationControllerWeb, {
  Props,
} from "./EmailLoginRegistrationController.Web";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Form,
  Input,
  Button,
  Tabs,
  Row,
  Checkbox,
  Col,
  // notification
} from "antd";
import { FormInstance } from "antd/lib/form";
import "./style.css";
import { openNotification } from "../../../components/src/Notification.web";
import Cookies from "js-cookie";
const TopNavSignIn = require("../../../components/src/TopNavSignIn").default;
export const userSignUp = require("./EmailLoginRegistrationService.Web");
export const userSignIn = require("./EmailLoginRegistrationService.Web");
const { TabPane } = Tabs;
export default class EmailLoginRegistrationWeb extends EmailLoginRegistrationControllerWeb {
  formRef = React.createRef<FormInstance>();
  recaptchaRef: any = "";

  onRegister = async (values: any) => {
    this.setState({ btnLoading: true });
    const { email, password, confirm_password, recaptcha_response } = values;
    let value = { email, password, confirm_password, recaptcha_response };
    const result = await userSignUp.userSignUp(value);
    if (result.success) {
      this.setState({ btnLoading: false });
      window.localStorage.setItem(
        "token",
        result && result.meta && result.meta.token
      );
      this.props.navigation.history.push(`/PromoDecksSignUpWeb`);
    } else {
      this.setState({ btnLoading: false });
      this.recaptchaRef.reset();
      console.log("error from userSignUp:", result.error);
    }
  };

  onSubmit = async (values: any) => {
    this.setState({ btnLoading: true });
    const result = await userSignIn.userSignIn(values);
    if (result.success) {
      this.setState({ btnLoading: false });
      window.localStorage.setItem("Role", result && result.role);
      window.localStorage.setItem(
        "token",
        result && result.meta && result.meta.token
      );
      if (values.remember) {
        Cookies.set("token", result && result.meta && result.meta.token);
        Cookies.set("Role", result && result.role);
      }
      openNotification("Successfully login", "success");
      if (result && result.role == "AdminRetailer") {
        this.props.navigation.history.push(`/RetailerAdminAdministrationWeb`);
      } else if (result && result.role == "AdminVendor") {
        this.props.navigation.history.push(`/VendorAdminDashboardWeb`);
      } else if (result && result.role == "Manufacturer") {
        this.props.navigation.history.push(`/VendorAdminDashboardWeb`);
      } else if (result && result.role == "Retailer") {
        this.props.navigation.history.push(`/RetailerAdminDashboardWeb`);
      } else {
        this.props.navigation.history.push(`/HomeLoginMenuWeb`);
      }
    } else {
      this.setState({ btnLoading: false });
      console.log("error from userSignUp:", result.error);
    }
  };

  onReset = () => {
    this.formRef.current!.resetFields();
  };

  onFill = () => {
    this.formRef.current!.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };

  handleHide = () => {
    this.setState({
      isActive: false,
    });
  };

  handleShow = () => {
    this.setState({
      isActive: true,
    });
  };

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  //  userSignUp: string;
  render() {
    return (
      <>
        <TopNavSignIn />

        <Row
          style={{ marginLeft: "auto", marginRight: "auto" }}
          className="loginTab"
        >
          <Tabs
            activeKey={this.state.signup}
            centered
            onChange={(key: any) => {
              this.setState({ signup: key });
            }}
          >
            <TabPane
              tab={
                <span
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  Login
                </span>
              }
              key="1"
            >
              <Text style={styles.titleWhySignUp}>{this.labelLoginHeader}</Text>
              <Form
                // {...layout}
                style={{ marginTop: "20px" }}
                ref={this.formRef}
                name="control-ref"
                onFinish={this.onSubmit}
                id="custom-login-id"
              >
                <Row>
                  <Col span={24}>
                    {" "}
                    <Form.Item
                      name="email"
                      style={{ marginBottom: "0px" }}
                      id="email"
                      // label="Note"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                        placeholder={this.labelEmail}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={24}
                    onClick={() =>
                      this.props.navigation.history.push("/ForgotPassword")
                    }
                  >
                    <span className="forgotLink">
                      {" "}
                      Forgot password or can't sign in
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {" "}
                    <Form.Item
                      id="password"
                      name="password"
                      // label="Note"
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                        placeholder={this.labelPassword}
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={4} />
                  <Col span={8}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.btnLoading}
                      >
                        Log In
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane
              tab={
                <span
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    // color:"#00518B"
                  }}
                >
                  Sign Up
                </span>
              }
              key="2"
            >
              <Row>
                <Col style={{ textAlign: "center" }}>
                  {" "}
                  <Text style={styles.titleWhySignUp}>{this.labelHeader}</Text>
                  <Form
                    // {...layout}
                    style={{ marginTop: "20px" }}
                    ref={this.formRef}
                    name="control-ref"
                    id="custom-register-id"
                    onFinish={this.onRegister}
                  >
                    <Form.Item
                      name="email"
                      initialValue={
                        qs.parse(this.props.navigation.history.location.search)
                          .user_email
                      }
                      // label="Note"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input
                        disabled={
                          qs.parse(
                            this.props.navigation.history.location.search
                          ).user_email
                            ? true
                            : false
                        }
                        name="email"
                        style={{
                          width: "80%",
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                        placeholder={this.labelEmail}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      // label="Note"
                      rules={[
                        { required: true },
                        {
                          pattern: new RegExp(
                            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                          ),
                          message:
                            "Password must be 8 character and contains at least one A-Z, a-z, 0-9, @$%#",
                        },
                      ]}
                    >
                      <Input
                        name="password"
                        style={{
                          width: "80%",
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                        placeholder={this.labelPassword}
                        type="password"
                      />
                    </Form.Item>

                    <Form.Item
                      name="confirm_password"
                      // label="Note"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        style={{
                          width: "80%",
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                        placeholder={this.labelRePassword}
                        type="password"
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: "80%", margin: "auto" }}
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Please agree tearms & condition")
                                ),
                        },
                      ]}
                      // {...tailFormItemLayout}
                    >
                      <Checkbox style={{ marginBottom: "1em" }}>
                        I have read and agree to the Terms of Use as will as
                        Privacy and Cookies Policy
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="recaptcha_response"
                      style={{ width: "80%", margin: "auto" }}
                    >
                      <ReCAPTCHA
                        ref={(el: any) => {
                          this.recaptchaRef = el;
                        }}
                        sitekey="6LdwFY4dAAAAAG1BZeMpZ8S6KxVrNr5fFGaC8Omj"
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      name="Submit"
                      loading={this.state.btnLoading}
                      htmlType="submit"
                      style={{ marginTop: "1em", width: "80%" }}
                    >
                      Sign Up
                    </Button>
                  </Form>
                </Col>{" "}
              </Row>{" "}
            </TabPane>
            )
          </Tabs>
        </Row>
        {/* </Row> */}
      </>
    );
  }
  // authToken=()=>{
  //   localStorage.getItem("token")?this.props.navigation.history.push("/HomeLoginMenuWeb"):""
  // }
  getRole() {
    const userData: any = qs.parse(
      this.props.navigation.history.location.search
    );
    window.localStorage.setItem(
      "Role",
      userData.user_role == undefined ? "" : userData.user_role
    );
    window.localStorage.setItem(
      "Email",
      userData.user_email == undefined ? "" : userData.user_email
    );
  }
  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getRole();
    let url: any = new URL(window.location.href);
    if (url.pathname.substring(1) == "signup") {
      return this.setState({ signup: "2" });
    } else if (url.pathname.substring(1) == "/") {
      return this.setState({ signup: "1" });
    }

    // Cookies.set('token',result && result.meta && result.meta.token)
    // Cookies.set('Role',result && result.role)
    if (Cookies.get("Role") && Cookies.get("token")) {
      if (Cookies.get("Role") == "AdminRetailer") {
        this.props.navigation.history.push(`/RetailerAdminAdministrationWeb`);
      } else if (Cookies.get("Role") == "AdminVendor") {
        this.props.navigation.history.push(`/VendorAdminDashboardWeb`);
      } else if (Cookies.get("Role") == "Manufacturer") {
        this.props.navigation.history.push(`/VendorAdminDashboardWeb`);
      } else if (Cookies.get("Role") == "Retailer") {
        this.props.navigation.history.push(`/RetailerAdminDashboardWeb`);
      } else {
        this.props.navigation.history.push(`/HomeLoginMenuWeb`);
      }
    }

    // this.authToken()
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signInText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    marginRight: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10,
  },
  signUpText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    marginLeft: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10,
  },
  signInTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginRight: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  signUpTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginLeft: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  // Customizable Area End
});
