import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Menu,
  Button,
  Select,
  Row,
  Collapse,
  Modal,
  Form,
  Input,
  Pagination,
  Divider,
  Spin,
} from "antd";
import { Prompt, useHistory } from "react-router-dom";
// import alert from ;
const alert = require("../assets/alert.png");
const TopNav = require("../../../components/src/TopNav").default;
import SideNav from "../../../components/src/SideNav";

import axios from "axios";

export const configJSON = require("./config");
const Alertweb = () => {
  const [cardLoading, setCardLoading] = useState(true);
  const [alertDetails, setAlertDetails] = useState([]);
  const [paginationData, setPaginationData] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  useEffect(() => {
    getData(1);
  }, []);

  const showModal = (data: any) => {
    setVisible(true);
    setModalData(data);
    console.log(data, "dddd");

    data?.attributes?.is_read ? "" : updateData(data.id);

    // updateData(data.id);
  };

  const updateData = async (id: any) => {
    const result = await axios
      .put(
        `${configJSON.serverURL}bx_block_email_notifications/notifications/${id}`,
        { id: id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((result) => {})
      .catch((err) => {});
  };

  const handleCancel = () => {
    //  setConfirmLoading(true);
    // setVisible(false);

    modalData?.attributes?.is_read
      ? setVisible(false)
      : (setVisible(false), getData(1));
  };
  const getData = async (pageNumber: number) => {
    setCardLoading(true);
    const result = await axios
      .get(
        `${configJSON.serverURL}bx_block_email_notifications/notifications?page=${pageNumber}`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((result) => {
        setAlertDetails(result.data.data);
        setPaginationData(result.data.meta);
        setCardLoading(false);
      })
      .catch((err) => {
        setCardLoading(false);
      });
  };
  const onPageChange = (pageNumber: number) => {
    getData(pageNumber);
  };
  return (
    <>
      <TopNav />
      <div className="main-content-div">
        <SideNav />

        <div className="main-content">
          <Row>
            <Col span={24}>
              <Card style={{ padding: "4px" }} bordered={false}>
                <Row
                  style={{
                    color: "#545861",
                    fontSize: "22px",
                    fontWeight: 700,

                    paddingBottom: "1em",
                  }}
                >
                  Alerts
                </Row>
                {cardLoading ? (
                  <Row align="middle" justify="center">
                    <Col>
                      <Spin style={{ marginTop: "20%" }} />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row
                      align="middle"
                      style={{
                        borderRadius: 6.4,
                        background: "#f2f2f2",
                        height: "60px",
                        fontSize: "17px",
                        fontWeight: "bold",
                        padding: "0px 0px 0px 16px",

                        // padding: "20px 20px 10px 20px"
                      }}
                    >
                      <Col span={24}>Alerts</Col>
                    </Row>
                    {/* <Row
                    style={{
                      marginTop: "7px",
                      marginBottom: "7px",
                      paddingLeft: "20px",

                      fontSize: 14,
                      fontWeight: 500,
                      color: "#999999",
                    }}
                  >
                    New
                  </Row>
                  */}
                    <Row
                      justify="center"
                      style={{ backgroundColor: "rgb(233, 247, 252)" }}
                    >
                      <Col xs={5} sm={1} md={0} xl={0} />
                      <Col xs={20} sm={21} md={22} xl={23}>
                        {" "}
                        <Divider
                          style={{
                            fontSize: "1px",
                            // backgroundColor: "#f5f5f5",
                            marginBottom: "0px",
                            marginTop: "0px",
                          }}
                        />
                      </Col>
                    </Row>
                    {alertDetails &&
                      alertDetails.map((message: any, id) => (
                        <>
                          {!message.attributes.is_read ? (
                            <>
                              <Row
                                onClick={() => showModal(message)}
                                align="middle"
                                justify="center"
                                style={{
                                  background: "#E9F7FC",
                                  minHeight: "60px",
                                  // borderBottom:"1px solid #d9d9d9",
                                }}
                              >
                                {/* <Col sm={1}  md={1} /> */}
                                <Col
                                  xs={4}
                                  sm={3}
                                  md={2}
                                  xl={1}
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={alert} />
                                </Col>
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  <Row
                                    className="cursor_pointer"
                                    style={{
                                      marginBottom: "0",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.title}
                                    </Col>
                                  </Row>
                                  <Row
                                    className="cursor_pointer"
                                    style={{
                                      fontSize: 10,
                                      color: "#99999",
                                      marginBottom: 0,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.name}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row
                                justify="center"
                                style={{
                                  backgroundColor: "rgb(233, 247, 252)",
                                }}
                              >
                                <Col xs={5} sm={1} md={0} xl={0} />
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  <Divider
                                    style={{
                                      fontSize: "1px",
                                      backgroundColor: "f5f5f5",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                    }}
                                  />
                                </Col>
                                <Col xs={5} sm={1} md={0} xl={0} />
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ))}

                    {/* <Row
                    style={{
                      marginTop: "7px",
                      marginBottom: "7px",
                      paddingLeft: "20px",

                      fontSize: 14,
                      fontWeight: 500,
                      color: "#999999",
                    }}
                  >
                    Previous
                  </Row> */}

                    <Row>
                      <Col span={1} />
                      <Col span={22}>
                        {" "}
                        <Divider
                          style={{
                            backgroundColor: "f5f5f5",
                            marginBottom: "0px",
                            marginTop: "0px",
                          }}
                        />
                      </Col>
                      <Col span={1} />
                    </Row>
                    {alertDetails &&
                      alertDetails.map((message: any, id) => (
                        <>
                          {message.attributes.is_read ? (
                            <>
                              <Row
                                onClick={() => showModal(message)}
                                align="middle"
                                justify="center"
                                style={{
                                  minHeight: "60px",
                                }}
                              >
                                <Col
                                  xs={4}
                                  sm={3}
                                  md={2}
                                  xl={1}
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={alert} />
                                </Col>
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  <Row
                                    className="cursor_pointer"
                                    style={{
                                      marginBottom: "0",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.title}
                                    </Col>
                                  </Row>
                                  <Row
                                    className="cursor_pointer"
                                    style={{
                                      fontSize: 10,
                                      color: "#99999",
                                      marginBottom: 0,
                                    }}
                                  >
                                    <Col span={24}>
                                      {message.attributes.name}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row justify="center">
                                <Col xs={5} sm={1} md={0} xl={0} />
                                <Col xs={20} sm={21} md={22} xl={23}>
                                  {" "}
                                  <Divider
                                    style={{
                                      backgroundColor: "f5f5f5",
                                      marginBottom: "0px",
                                      marginTop: "0px",
                                    }}
                                  />
                                </Col>
                                <Col xs={5} sm={1} md={0} xl={0} />
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    <Modal
                      maskClosable={false}
                      centered
                      title={modalData?.attributes?.title}
                      // title={<h2>{message.attributes.title}</h2>}
                      visible={visible}
                      confirmLoading={confirmLoading}
                      onCancel={handleCancel}
                      footer={false}
                      // width="35%"
                      className="alert_modal"
                    >
                      <Row
                        align="middle"
                        style={{
                          fontSize: 14,
                          color: "#99999",
                          marginBottom: 0,
                        }}
                      >
                        <Col span={24}>{modalData?.attributes?.name}</Col>
                      </Row>
                      <Row
                        align="middle"
                        justify="center"
                        style={{ marginTop: "14px" }}
                      >
                        <Button
                          type="primary"
                          style={{ borderRadius: "15px", width: "100px" }}
                          onClick={() =>
                            modalData?.attributes?.is_read
                              ? setVisible(false)
                              : (setVisible(false), getData(1))
                          }
                          // onClick={() => (setVisible(false), getData(1))}
                        >
                          Ok
                        </Button>
                      </Row>{" "}
                    </Modal>

                    <Row
                      justify="end"
                      style={{ marginTop: "15px", marginBottom: "2rem" }}
                    >
                      <Pagination
                        total={paginationData?.total_entries}
                        showTotal={(total, range) =>
                          range[0] > 0
                            ? `Showing ${range[0]}-${range[1]} of ${total} `
                            : `Showing 1-10 of ${total} `
                        }
                        defaultPageSize={paginationData?.per_page}
                        defaultCurrent={paginationData?.current_page}
                        onChange={onPageChange}
                      />
                    </Row>
                  </>
                )}
              </Card>
            </Col>{" "}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Alertweb;
