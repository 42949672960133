import React, { useState } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Col,
  Row,
  Card,
  Button,
} from "antd";
const originData: any = [];
export const edituser = require("./Service.web");
export const getUsersList = require("./Service.web");
export const deleteUser = require("./Service.web");

import {
  DeleteFilled,
  SearchOutlined,
  EditFilled,
  UserOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";
import { openNotification } from "../../../components/src/Notification.web";
for (let i = 0; i < 10; i++) {
  originData.push({
    id: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}

const EditableTable = (props: any) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<any>([]);
  const [editingKey, setEditingKey] = useState<any>("");
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const isEditing = (record: any) => record.id === editingKey;
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }: any) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Card bodyStyle={{ border: "1px solid #DFDFDF" }}>
            <Row>
              <Col span={7}>
                <Form.Item
                  name={`first_name`}
                  style={{
                    margin: 0,
                    width: "95%",
                  }}
                  rules={
                    []
                  }
                >
                  {inputNode}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="username"
                  style={{
                    margin: 0,
                    width: "95%",
                  }}
                  rules={
                    []
                  }
                >
                  {inputNode}
                </Form.Item>
              </Col>{" "}
              <Col span={5}>
                <Form.Item
                  style={{
                    margin: 0,
                    width: "95%",
                  }}

                  rules={
                    []
                  }
                >
                  <Input disabled defaultValue={record.email} />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Row>
                  <Col>
                    {" "}
                    <span>
                      <Button
                        onClick={cancel}
                        style={{
                          marginRight: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        Cancel
                      </Button>{" "}
                      <Button
                        onClick={() => save(record.id)}
                        type="primary"
                        htmlType="submit"
                        style={{
                          marginRight: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        Save
                      </Button>
                    </span>
                  </Col>
                  <Col style={{ fontSize: "20px", marginRight: "10px" }}>
                    <EditFilled
                      translate="yes"
                      disabled={editingKey !== ""}
                      onClick={() => edit(record)}
                    />
                  </Col>
                  <Popconfirm
                    title="Sure to delete?"
                  >
                    <Col style={{ fontSize: "20px" }}>
                      <DeleteFilled style={{ cursor: "pointer" }}
                        translate="yes"
                      />
                    </Col>
                  </Popconfirm>
                  <Col span={2}></Col>
                </Row>
              </Col>
            </Row>
          </Card>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      first_name: "",
      username: "",
      email: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const handleDelete = async (id: any) => {
    const datas = await deleteUser.deleteUser(id);
    getBrandsList();
    if (datas.success) {
      openNotification(datas.message, "success")
    }
  };
  const getBrandsList = async () => {

    const datas = await getUsersList.getUsersList();
    setData(datas && datas.Data ? datas.Data : []);

  };
  const save = async (id: any) => {
    try {
      const row = await form.validateFields();
      const datas = await edituser.edituser(row, id, "edit");
      if (datas) {
        openNotification(datas.message, "success")
        getBrandsList();
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: (
        <Card bodyStyle={{ display: "flex", padding: "0px" }} bordered={false}>
          <Col span={7}>{"User First name & Last name"}</Col>
          <Col span={5}>{"Username"}</Col>
          <Col span={10}>{"Email"}</Col>
        </Card>
      ),
      dataIndex: "name",
      width: "75%",
      editable: true,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Card bodyStyle={{ border: "1px solid #DFDFDF", padding: "15px" }}>
            <Row>
              <Col span={7} style={{ color: "#0E89EE" }}>
                {record.first_name} {record.last_name}
              </Col>
              <Col span={5}>{record.username}</Col>
              <Col span={10}>{record.email}</Col>
              <Col span={2}>
                <Row>
                  <Col style={{ fontSize: "20px", marginRight: "10px" }}>
                    <EditFilled
                      translate="yes"
                      disabled={editingKey !== ""}
                      onClick={() => edit(record)}
                    />
                  </Col>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => handleDelete(record.id)}
                  >
                    <Col style={{ fontSize: "20px" }}>
                      <DeleteFilled style={{ cursor: "pointer" }}
                        translate="yes"
                      />
                    </Col>
                  </Popconfirm>
                </Row>
              </Col>{" "}
            </Row>
          </Card>
        );
      },
    },
  ];
  const mergedColumns: any = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        className="editBrand"
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};
export default EditableTable;
