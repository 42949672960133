import axios from "axios";
import { openNotification, openErrorNotification } from "../../../components/src/Notification.web";
export const configJSON = require("./config");

const activateUser = async function (payload) {
  try {
    const response = await axios.get(
      `${configJSON.serverURL}account_block/accounts/account_activation?token=${payload}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};


export { activateUser };
