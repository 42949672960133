import React from "react";
import { Divider, Col, Button, Modal, Checkbox, Row, Table } from "antd";
import {
  FilterFilled,
  PlayCircleOutlined,
  HomeFilled,
  CarryOutFilled,
} from "@ant-design/icons";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import "../../header/style.css";
// Customizable Area End
import RetailerAdminDashboardControllerWeb, {
  Props,
} from "./RetailerAdminDashboardController.Web";
import SideNav from "../../../components/src/SideNav";
const ReactDragListView = require("react-drag-listview").default;
const TopNav = require("../../../components/src/TopNav").default;
export default class RetailerAdminDashboardWeb extends RetailerAdminDashboardControllerWeb {
  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex: any, toIndex: any) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns,
        });
      },
      nodeSelector: "th",
    };
  }
  setModalVisible(modalVisible: any) {
    this.setState({ modalVisible });
  }
  render() {
    const { showing } = this.state;

    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <TopNav />
        <div className="main-content-div">
          <SideNav />
          <div className="main-content">
            <ScrollView keyboardShouldPersistTaps="always">
              <TouchableWithoutFeedback
                testID={"Background"}
                onPress={() => {
                  this.hideKeyboard();
                }}
              >
                {/* Customizable Area Start */}
                <View>
                  <Modal
                    destroyOnClose
                    closable={false}
                    centered
                    width="25%"
                    visible={this.state.modalVisible}
                    maskClosable={false}
                    footer={false}
                  >
                    <Col style={{}}>
                      <Row>
                        {
                          <h2>
                            <PlayCircleOutlined
                              translate="yes"
                              style={{ marginRight: "5px" }}
                            />
                            {this.state.title}
                          </h2>
                        }
                      </Row>
                      <Divider style={{ margin: "0px" }} />
                      <Row style={{ marginBottom: "15px", marginTop: "15px" }}>
                        <Checkbox style={{ marginRight: "5px" }} />
                        {"Sort A-Z"}
                      </Row>
                      <Row style={{ marginBottom: "15px", marginTop: "15px" }}>
                        <Checkbox style={{ marginRight: "5px" }} />
                        {"Sort Z-A"}
                      </Row>

                      <Row>
                        {
                          <h2>
                            <FilterFilled
                              translate="yes"
                              style={{ marginRight: "5px" }}
                            />
                            Filter By
                          </h2>
                        }
                      </Row>
                      <Divider style={{ margin: "0px" }} />
                      <Row style={{ marginBottom: "15px", marginTop: "15px" }}>
                        <Checkbox style={{ marginRight: "5px" }} />
                        {"Brand A"}
                      </Row>
                      <Row style={{ marginBottom: "15px", marginTop: "15px" }}>
                        <Checkbox style={{ marginRight: "5px" }} />
                        {"Brand B"}
                      </Row>

                      <Row style={{ display: "inherit" }}>
                        <Button
                          onClick={() => this.setModalVisible(false)}
                          style={{ width: "80px", borderRadius: "10px" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => this.setModalVisible(false)}
                          type="primary"
                          style={{
                            width: "80px",
                            borderRadius: "10px",
                            float: "right",
                          }}
                        >
                          Apply
                        </Button>
                      </Row>
                    </Col>
                  </Modal>
                  <ReactDragListView.DragColumn {...this.dragProps}>
                    <Table
                      columns={this.state.columns}
                      pagination={false}
                      loading={this.state.tableLoading}
                      dataSource={this.state.data}
                      bordered
                      scroll={{ x: 500 }}
                    />
                  </ReactDragListView.DragColumn>
                </View>
                {/* Customizable Area End */}
              </TouchableWithoutFeedback>
            </ScrollView>
          </div>
        </div>
      </KeyboardAvoidingView>
    );
  }
  checkAuth = () => {
    const role = window.localStorage.getItem("Role"); 
    if (role == "AdminVendor") {
      this.props.navigation.history.goBack();
    }
    else if (role == "Vendor") {
      this.props.navigation.history.goBack();
    }
    else if (role == "Manufacturer") {
      this.props.navigation.history.goBack();
    }
    else if (role == "ChannelManager") {
      this.props.navigation.history.goBack();
    }
    else if (role == "AccountManager") {
      this.props.navigation.history.goBack();
    }
  };
  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getCData();
    this.checkAuth();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  // Customizable Area End
});
