import { IBlock } from "../../../framework/src/IBlock";

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const getDeletedPromotion = require("./Service.web");
import React from "react";
import { DownCircleFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
export const getTamplateData1 = require("./Service.web");
export const configJSON = require("./config");
export const getFilterData = require("./Service.web");
export const getDeleteFilterData = require("./Service.web");
export const searchData = require("../../email-account-registration/src/EmailLoginRegistrationService.Web.js")
export interface Props {
  navigation: any;
  id: string;
  searchResult: any
}

export interface S {
  // Customizable Area Start
  firstName: string;
  type: string;
  search: any;
  pdfData: boolean;
  lastName: string;
  modalVisible: boolean;
  title: string;
  editModalVisible: boolean;
  deletePromotionData: any;
  publishModal: boolean;
  showing: boolean;
  email: string;
  tableLoading: boolean;
  columns: any[];
  columnsDaTa: any[];
  csvData: boolean;
  password: string;
  tempHeaderData: string;
  selected: any[];
  options: any[];
  filterData: any[];
  page: number;
  totalPage: number;
  channelList: any[];
  tempHeaderLoading: boolean;
  otpAuthToken: string;
  reTypePassword: string;
  downLoadModal: boolean;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  filterBrand: any[];
  filterBusiness: any[];
  phone: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DeletedPromotionControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelPromoHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelUsername: string;
  labelCompany: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  btnTxtLogin: string;
  btnTxtContinue: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      columnsDaTa: [],
      tempHeaderData: "",
      selected: [],
      options: [],
      channelList: [],
      filterData: [],
      totalPage: 1,
      search: localStorage.getItem("search"),
      page: 1,
      tempHeaderLoading: true,
      csvData: false,
      pdfData: false,
      // Customizable Area Start
      firstName: "",
      publishModal: false,
      filterBusiness: [],
      showing: false,
      editModalVisible: false,
      lastName: "",
      title: "",
      modalVisible: false,
      downLoadModal: false,
      filterBrand: [],
      type: "Deleted Promotion",
      email: "",
      data: [],
      columns: [
        {
          title: () => {
            return (
              <Row>
                <Col>Year</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true), this.setTitle("Year")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "year",
          render: (text: any) => <h5 style={{ width: "45px" }}>{text}</h5>,
        },
        {
          title: () => {
            return (
              <Row>
                <Col>Quarter</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true), this.setTitle("Quarter")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "quarter",
          render: (text: any) => <h5 style={{ width: "65px" }}>{text}</h5>,
        },
        {
          title: () => {
            return (
              <Row>
                <Col>Promotional Name</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true),
                      this.setTitle("Promotional Name")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "promotional_deck_name",
          render: (text: any) => <h5 style={{ width: "140px" }}>{text}</h5>,
        },
        {
          title: () => {
            return (
              <Row>
                <Col>Promotional Id</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true),
                      this.setTitle("Promotional Id")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "promo_id",
          render: (text: any, row: any) => (
            <h5
              className="cursor_pointer"
              style={{ width: "120px" }}
              onClick={() => {
                this.handleChange(row.promo_type);
                window.localStorage.setItem("promo_id", row.promo_id);
                window.localStorage.setItem("promotion_id", row.id);
                window.localStorage.setItem("promo_type", row.promo_type);
                window.localStorage.setItem(
                  "promo_name",
                  row.promotional_deck_name
                );
              }}
            >
              {text}
            </h5>
          ),
        },
        {
          title: () => {
            return (
              <Row>
                <Col>End Execution Detail</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true),
                      this.setTitle("End Execution Detail")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "end_execution_detail",
          render: (text: any) => <h5 style={{ width: "160px" }}>{text}</h5>,
        },
        {
          title: () => {
            return (
              <Row>
                <Col>Promo Type</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true), this.setTitle("Promo Type")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "promo_type",
          render: (text: any, row: any) => (
            <h5
              className="cursor_pointer"
              style={{ width: "120px" }}
              onClick={() => (
                this.handleChange(row.promo_type),
                window.localStorage.setItem("promo_id", row.promo_id),
                window.localStorage.setItem("promotion_id", row.id),
                window.localStorage.setItem("promo_type", row.promo_type),
                window.localStorage.setItem(
                  "promo_name",
                  row.promotional_deck_name
                )
              )}
            >
              {text}
            </h5>
          ),
        },
        {
          title: () => {
            return (
              <Row>
                <Col>Dealer Execution Detail</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true),
                      this.setTitle("Dealer Execution Detail")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "dealer_execution_detail",
          render: (text: any) => <h5 style={{ width: "160px" }}>{text}</h5>,
        },
        {
          title: () => {
            return (
              <Row>
                <Col>Account Id</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true), this.setTitle("Account Id")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "account_id",
          render: (text: any) => <h5 style={{ width: "100px" }}>{text}</h5>,
        },
        {
          title: () => {
            return (
              <Row>
                <Col>Published</Col>
                <Col style={{ marginLeft: "2px" }}>
                  <DownCircleFilled
                    translate="yes"
                    onClick={() => (
                      this.setModalVisible(true), this.setTitle("Published")
                    )}
                  />
                </Col>
              </Row>
            );
          },
          dataIndex: "is_published",
          render: (text: any) => (
            <h5 style={{ width: "80px" }}>{text ? "Yes" : "No"}</h5>
          ),
        },
      ],
      deletePromotionData: [],
      tableLoading: true,
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelPromoHeader = configJSON.labelPromoHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelUsername = configJSON.labelUsername;
    this.labelCompany = configJSON.labelCompany;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.btnTxtLogin = configJSON.btnTxtLogin;
    this.btnTxtContinue = configJSON.btnTxtContinue;
    // Customizable Area End
  }
  getSearch = async (data: any) => {
    const result = await searchData.searchData(data,true);
    if (result) {
      window.localStorage.setItem("search", "");
      this.setState({
        data: result && result.promotion,
        totalPage: result ? result.length : "",
      });
      this.setState({ tableLoading: false });
    } else {
      console.log("error from search:", result.error);
    }
  };

  getFilters = async () => {
    const datas = await getDeleteFilterData.getDeleteFilterData();
    this.setState({ filterData: datas && datas.Data });
  };
  getCData = async (page: any) => {
    const token = localStorage.getItem("token");
    const deletePromotionData = await getDeletedPromotion.getDeletedPromotion(page,
      token
    );
    if (deletePromotionData) {
      this.setState({
        totalPage: deletePromotionData ? deletePromotionData.length : "",
        data: deletePromotionData && deletePromotionData.data,

        tableLoading: false,
      });
    }
  };
  async handleChange(data: any) {
    if (data == "Blanket Dollar Off") {
      this.props.navigation.history.push("/BlanketDollarOffWeb");
    }
    if (data == "New Lower Price") {
      this.props.navigation.history.push("/NewLowerPriceWeb");
    }
    if (data == "Blanket Percent Off") {
      this.props.navigation.history.push("/BlanketPercentOffWeb");
    }
    if (data == "Buy X Get Y") {
      this.props.navigation.history.push("/BuyXGetYWeb");
    }
    if (data == "Mail/Online Rebate") {
      this.props.navigation.history.push("/MailOnlineRebateWeb");
    }
    if (data == "Promotional SKU") {
      this.props.navigation.history.push("/PromotionalSKUWeb");
    }
    if (data == "Tiered Discounts") {
      this.props.navigation.history.push("/TieredDiscountsWeb");
    }
    if (data == "Trade In") {
      this.props.navigation.history.push("/TradeInWeb");
    }
    if (data == "Warranty") {
      this.props.navigation.history.push("/WarrantyWeb");
    }
    if (data == "Deleted Promotion") {
      this.props.navigation.history.push("/DeletedPromotionWeb");
    }
  }
  setEditModalVisible(editModalVisible: any) {
    this.setState({ editModalVisible });
  }
  setModalVisible(modalVisible: any) {
    this.setState({ modalVisible });
  }
  setTitle(title: any) {
    this.setState({ title });
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules,
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );
            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };
  btnContinueProps = {
    onPress: () => this.createAccount(),
  };
  btnEmailLogInProps = {
    color: "#6200EE",
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };
  // Customizable Area End
}
