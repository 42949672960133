import React, { useRef, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { withRouter, NavLink } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
export const WorkAround = require("./WorkAround.js").default;
import MultiSelect from "react-multi-select-component";
import * as yup from "yup";
import {
  DeleteFilled,
  EditFilled,
  WarningTwoTone,
  HomeFilled,
  CarryOutFilled,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Spin,
  Input,
  Button,
  Row,
  Select,
  Radio,
  Col,
  Upload,
  message,
} from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

export const importTempt = require("../../blocks/XmlCsvTemplateBuilder/src/Service.web.js");
export const getProfileList = require("../../blocks/XmlCsvTemplateBuilder/src/Service.web.js");
export const downloadTempData = require("../../blocks/XmlCsvTemplateBuilder/src/Service.web.js");
export const publishTemp = require("../../blocks/XmlCsvTemplateBuilder/src/Service.web");
export const getProfile = require("../../blocks/email-account-registration/src/EmailLoginRegistrationService.Web");
import moment from "moment";
import {
  openNotification,
  openErrorNotification,
  openErrorNotificationForPromotions,
} from "./Notification.web";
import axios from "axios";
import { AnyAaaaRecord } from "dns";
export const getChannels = require("../../blocks/dashboard/src/Service.web.js");

const SideNav = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [value, setValue] = useState("attachment");
  const [csvData, setCsvData] = useState([]);
  const [typeOption, setTypeOption] = useState<any>("");
  const [headers, setHeaders] = useState<any[]>([]);
  const [duplicateHeaders, setDuplicateHeaders] = useState<any[]>([]);
  const [errorChannelMsg, setErrorMessage] = useState<any>("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  React.useEffect(() => {
    // debugger;
    if (csvData.length > 0) {
      // @ts-ignore
      document.getElementById("download").click();
      setShowing(true);
    }
  }, [duplicateHeaders]);

  const handleOk = () => {
    setSelectedFile(null);
    setSelectedFile1(null);
    setFileName("");
    setFileName1("");
    setIsModalVisible(false);
    setSelected([]);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setSelectedFile1(null);
    setFileName("");
    setFileName1("");
    setIsModalVisible(false);
    setSelected([]);
  };
  const handleChange = async (data: any) => {
    if (data == "Blanket Dollar Off") {
      props.history.push("/BlanketDollarOffWeb");
    }
    if (data == "New Lower Price") {
      props.history.push("/NewLowerPriceWeb");
    }
    if (data == "Blanket Percent Off") {
      props.history.push("/BlanketPercentOffWeb");
    }
    if (data == "Buy X Get Y") {
      props.history.push("/BuyXGetYWeb");
    }
    if (data == "Mail/Online Rebate") {
      props.history.push("/MailOnlineRebateWeb");
    }
    if (data == "Promotional SKU") {
      props.history.push("/PromotionalSKUWeb");
    }
    if (data == "Tiered Discounts") {
      props.history.push("/TieredDiscountsWeb");
    }
    if (data == "Trade In") {
      props.history.push("/TradeInWeb");
    }
    if (data == "Warranty") {
      props.history.push("/WarrantyWeb");
    }
  };

  const promotionValidation = () => {
    const validations = yup.object().shape({
      promotional_deck_name: yup
        .string()
        .required("Please select promotional deck name"),
      promo_type: yup.string().required("Please select promo type"),
      end_execution_detail: yup
        .string()
        .required("Please enter the end execution detail"),
      dealer_execution_detail: yup
        .string()
        .required("Please enter the dealer execution detail"),
    });
    return validations;
  };
  const onFinish = async (values: any, { resetForm }: any) => {
    if (selectedFile !== undefined) {
      const datas = await getProfileList.getProfileList();
      let channel_id = selected.map((data: any) => data.value);
      if (channel_id == "") {
        return setErrorMessage("Please select the channels");
      } else {
        setErrorMessage("");
      }
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("attachment", selectedFile1);
      formData.append("channel", JSON.stringify(channel_id));

      formData.append("promotional_deck_name", values.promotional_deck_name);
      formData.append("promo_type", values.promo_type);
      setTypeOption(values.promo_type);
      formData.append(
        "dealer_execution_detail",
        values.dealer_execution_detail
      );
      formData.append("end_execution_detail", values.end_execution_detail);
      const result = await importTempt.importTempt(formData);

      if (result.success) {
        setIsModalVisible(false);
        setSelectedFile1(null);
        setSelectedFile(null);
        setFileName("");
        setFileName1("");
        setValue("attachment");
        setSelected([]);
        localStorage.setItem("UPDATE", "true");
        openNotification(result.message, "success");
        if (
          result &&
          result.NewPromotionData &&
          result.NewPromotionData.data &&
          result.NewPromotionData.data.attributes
        ) {
          window.localStorage.setItem(
            "promo_id",
            result.NewPromotionData.data.attributes.promo_id
          );
          window.localStorage.setItem(
            "promotion_id",
            result.NewPromotionData.data.attributes.id
          );
          window.localStorage.setItem(
            "promo_name",
            result.NewPromotionData.data.attributes.promotional_deck_name
          );
          window.localStorage.setItem(
            "promo_type",
            result.NewPromotionData.data.attributes.promo_type
          );
          setTimeout(() => {
            handleChange(result.NewPromotionData.data.attributes.promo_type);
          }, 2000);
        }
      } else {
        // let errorMessageData = result.error.response.data.message;
        // let completeMsg = errorMessageData.includes(",").join("\n")
        openErrorNotificationForPromotions(
          result &&
          result.error &&
          result.error.response &&
          result.error.response.data &&
          result.error.response.data.message,
          "error"
        );
        downloadExcelData(
          result &&
          result.error &&
          result.error.response &&
          result.error.response.data
        );
        setExcelData(
          result &&
          result.error &&
          result.error.response &&
          result.error.response.data &&
          result.error.response.data.data
        );
        console.log("error from promotion:", result);
      }
    } else {
      setErrorMsg(true);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const role = window.localStorage.getItem("Role");
    if (role == "AdminVendor") {
      getAllChannels();
      window.localStorage.getItem("UPDATE") == "true"
        ? (getAllChannels(), window.localStorage.setItem("UPDATE", "false"))
        : "";
    } else if (role == "Vendor") {
      getAllChannels();
      window.localStorage.getItem("UPDATE") == "true"
        ? (getAllChannels(), window.localStorage.setItem("UPDATE", "false"))
        : "";
    } else if (role == "Manufacturer") {
      getAllChannels();
      window.localStorage.getItem("UPDATE") == "true"
        ? (getAllChannels(), window.localStorage.setItem("UPDATE", "false"))
        : "";
    } else if (role == "ChannelManager") {
      getAllChannels();
      window.localStorage.getItem("UPDATE") == "true"
        ? (getAllChannels(), window.localStorage.setItem("UPDATE", "false"))
        : "";
    } else if (role == "AccountManager") {
      getAllChannels();
      window.localStorage.getItem("UPDATE") == "true"
        ? (getAllChannels(), window.localStorage.setItem("UPDATE", "false"))
        : "";
    }
  }, [window.localStorage.getItem("UPDATE") == "true"]);
  const [showPublishBtn, setshowPublishBtn] = useState<any>(false);
  useEffect(() => {
    window.localStorage.getItem("publish") == "true"
      ? (setshowPublishBtn(true),
        window.localStorage.setItem("publish", "false"))
      : "";
  }, [window.localStorage.getItem("publish") == "true"]);
  const getAllChannels = async () => {
    const datas = await getChannels.getChannels();
    setchannelList(datas && datas.Data ? datas.Data : []);
    setOptions(
      datas && datas.Data
        ? datas &&
        datas.Data.map((data: any) => ({
          label: data.name,
          value: data.id,
        }))
        : ""
    );
  };
  const downloadExcelData = async (result: any) => {
    if (result.status == "401") {
      await excelDownloadHandler(result);
    }
  };
  const excelDownloadHandler = async (data: any) => {
    // eslint-disable-line
    let datas = [];
    if (data && data?.data?.length > 0) {
      datas =
        data &&
        data.data.map(function (item: any) {
          return { ...item[0], error1: item[1], error2: item[2] };
        });
    } else {
      console.log("Pleas pass the data list");
    }
    let array = datas.map((obj: any) => Object.values(obj));
    const headers = [...data.columns];
    setCsvData(array);
    setHeaders(headers);
    setDuplicateHeaders(headers);
  };

  const onPublishFinish = async (values: any) => {
    const data = {
      year: parseInt(values.year),
      promotional_deck_name: values.promotional_deck_name,
      quarter: values.quarter,
      is_published: true,
      channel: selected.map((data: any) => data.value),
    };
    const res = await publishTemp.publishTemp(data);

    if (res.success) {
      openNotification(res.message, "success");
      setShowPublishModal(false);
    } else {
      console.log("error from promotion:", res.error);
    }
  };
  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const [channelList, setchannelList] = useState([]);
  const [options, setOptions] = useState<any>([]);

  const [selectedChannel, setselectedChannel] = useState<any>([]);
  function onSelectOrder(selectedList: any, selectedItem: any) {
    var selectorder: any = [];
    for (var i = 0; i < selectedList.length; ++i)
      selectorder.push(selectedList[i]["id"]);
    setselectedChannel(selectorder);
  }
  function onRemoveOrder(selectedList: any, selectedItem: any) {
    var deselectorder: any = [];
    for (var i = 0; i < selectedList.length; ++i)
      deselectorder.push(selectedList[i]["id"]);
    setselectedChannel(deselectorder);
  }

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFile1, setSelectedFile1] = useState<any>(null);
  const [fileName, setFileName] = useState("");
  const [fileName1, setFileName1] = useState("");

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setErrorMsg(false);
  };
  const discardFile = (val: any) => {
    val == "attachment" ? setSelectedFile1(null) : setSelectedFile(null);
    val == "file" ? setFileName("") : setFileName1("");
  };

  const changeHandler1 = (event: any) => {
    setSelectedFile1(event.target.files[0]);
    setFileName1(event.target.files[0].name);
  };

  const [showing, setShowing] = useState(true);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const isRequired = (message: any) => (value: any) =>
    !!value ? undefined : message;
  const validateName = (value: any) => {
    let error: any;
    if (!value || value == "") {
      error = "Required";
    }
    return error;
  };
  const role = localStorage.getItem("Role");
  let homeButton;
  if (role == "AdminRetailer") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  } else if (role == "Retailer") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  } else if (role == "Buyer") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  } else if (role == "Manufacturer") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  } else if (role == "AdminVendor") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  } else if (role == "Vendor") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  } else if (role == "ChannelManager") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  } else if (role == "AccountManager") {
    homeButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/HomeLoginMenuWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <HomeFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink
            to="/HomeLoginMenuWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Home
          </NavLink>
        </Row>
      </li>
    );
  }
  let adminiButton;
  if (role == "AdminRetailer") {
    adminiButton = (
      <li style={{ marginTop: "68vh" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/RetailerAdminAdministrationWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/RetailerAdminAdministrationWeb">Administration</NavLink>
        </Row>
      </li>
    );
  } else if (role == "Retailer") {
    adminiButton = (
      <li style={{ marginTop: "68vh" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/RetailerAdminAdministrationWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/RetailerAdminAdministrationWeb">Administration</NavLink>
        </Row>
      </li>
    );
  } else if (role == "Buyer") {
    adminiButton = (
      <li style={{ marginTop: "68vh" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/RetailerAdminAdministrationWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/RetailerAdminAdministrationWeb">Administration</NavLink>
        </Row>
      </li>
    );
  } else if (role == "Manufacturer") {
    adminiButton = (
      <li style={{ marginTop: "3em" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/VendorAdminDashboardWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/VendorAdminDashboardWeb">Administration</NavLink>
        </Row>
      </li>
    );
  } else if (role == "AdminVendor") {
    adminiButton = (
      <li style={{ marginTop: "3em" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/VendorAdminDashboardWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/VendorAdminDashboardWeb">Administration</NavLink>
        </Row>
      </li>
    );
  } else if (role == "Vendor") {
    adminiButton = (
      <li style={{ marginTop: "3em" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/VendorAdminDashboardWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/VendorAdminDashboardWeb">Administration</NavLink>
        </Row>
      </li>
    );
  } else if (role == "ChannelManager") {
    adminiButton = (
      <li style={{ marginTop: "3em" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/VendorAdminDashboardWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/VendorAdminDashboardWeb">Administration</NavLink>
        </Row>
      </li>
    );
  } else if (role == "AccountManager") {
    adminiButton = (
      <li style={{ marginTop: "68vh" }}>
        <Row
          align="middle"
          className={
            props.location.pathname == "/VendorAdminDashboardWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <CarryOutFilled translate="yes" style={{ marginRight: "10px" }} />
          <NavLink to="/VendorAdminDashboardWeb">Administration</NavLink>
        </Row>
      </li>
    );
  }

  let remiButton;
  if (role == "AdminRetailer") {
    remiButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >    <NavLink
          to="/DeletedPromotionWeb"
        // onClick={() => localStorage.setItem("UPDATE", "true")}
        >
            Deleted Promotion
          </NavLink>

        </Row></li>
    );
  } else if (role == "Retailer") {
    remiButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >    <NavLink
          to="/DeletedPromotionWeb"
        // onClick={() => localStorage.setItem("UPDATE", "true")}
        >
            Deleted Promotion
          </NavLink>

        </Row></li>
    );
  } else if (role == "Buyer") {
    remiButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >    <NavLink
          to="/DeletedPromotionWeb"
        // onClick={() => localStorage.setItem("UPDATE", "true")}
        >
            Deleted Promotion
          </NavLink>

        </Row></li>
    );
  } else if (role == "Manufacturer") {
    remiButton = (
      <><li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >    <NavLink
          to="/DeletedPromotionWeb"
        // onClick={() => localStorage.setItem("UPDATE", "true")}
        >
            Deleted Promotion
          </NavLink>

        </Row></li>

        <li className="menuLabel" onClick={showModal}>
          New Promotion
        </li>

        {props.location.pathname == "/HomeLoginMenuWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/VendorAdminDashboardWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/DeletedPromotionWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : props.location.pathname == "/AlertWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : (
          <li
            className="menuLabel"
            style={{ opacity: showPublishBtn ? "0.5" : "1" }}
            onClick={() => (showPublishBtn ? "" : setShowPublishModal(true))}
          >
            Publish Promotional Deck
          </li>
        )}
        <li className="menuLabel">Download Templates</li>
        <hr />
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Percent Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Percent Off"
          >
            Blanket Percent Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Dollar Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Dollar Off"
          >
            Blanket Dollar Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Buy X Get Y"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Buy X Get Y"
          >
            Buy X Get Y
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Mail/Online Rebate"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Mail/Online Rebate"
          >
            Mail/Online Rebate
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "New Lower Price"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="New Lower Price"
          >
            New Lower Price
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Promotional SKU"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Promotional SKU"
          >
            Promotional SKU
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Tiered Discounts"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Tiered Discounts"
          >
            Tiered Discounts
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Warranty"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Warranty"
          >
            Warranty
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Trade In"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Trade In"
          >
            Trade In
          </WorkAround>
        </li>
      </>
    );
  } else if (role == "AdminVendor") {
    remiButton = (
      <><li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <NavLink
            to="/DeletedPromotionWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Deleted Promotion
          </NavLink>

        </Row></li>
        <li className="menuLabel" onClick={showModal}>
          New Promotion
        </li>

        {props.location.pathname == "/HomeLoginMenuWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/VendorAdminDashboardWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/DeletedPromotionWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : props.location.pathname == "/AlertWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : (
          <li
            className="menuLabel"
            style={{ opacity: showPublishBtn ? "0.5" : "1" }}
            onClick={() => (showPublishBtn ? "" : setShowPublishModal(true))}
          >
            Publish Promotional Deck
          </li>
        )}
        <li className="menuLabel">Download Templates</li>
        <hr />
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Percent Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Percent Off"
          >
            Blanket Percent Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Dollar Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Dollar Off"
          >
            Blanket Dollar Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Buy X Get Y"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Buy X Get Y"
          >
            Buy X Get Y
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Mail/Online Rebate"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Mail/Online Rebate"
          >
            Mail/Online Rebate
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "New Lower Price"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="New Lower Price"
          >
            New Lower Price
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Promotional SKU"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Promotional SKU"
          >
            Promotional SKU
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Tiered Discounts"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Tiered Discounts"
          >
            Tiered Discounts
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Warranty"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Warranty"
          >
            Warranty
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Trade In"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Trade In"
          >
            Trade In
          </WorkAround>
        </li>
      </>
    );
  } else if (role == "Vendor") {
    remiButton = (
      <><li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >
          <NavLink
            to="/DeletedPromotionWeb"
            onClick={() => localStorage.setItem("UPDATE", "true")}
          >
            Deleted Promotion
          </NavLink>

        </Row></li>
        <li className="menuLabel" onClick={showModal}>
          New Promotion
        </li>

        {props.location.pathname == "/HomeLoginMenuWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/VendorAdminDashboardWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/DeletedPromotionWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : props.location.pathname == "/AlertWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : (
          <li
            className="menuLabel"
            style={{ opacity: showPublishBtn ? "0.5" : "1" }}
            onClick={() => (showPublishBtn ? "" : setShowPublishModal(true))}
          >
            Publish Promotional Deck
          </li>
        )}
        <li className="menuLabel">Download Templates</li>
        <hr />
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Percent Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Percent Off"
          >
            Blanket Percent Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Dollar Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Dollar Off"
          >
            Blanket Dollar Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Buy X Get Y"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Buy X Get Y"
          >
            Buy X Get Y
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Mail/Online Rebate"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Mail/Online Rebate"
          >
            Mail/Online Rebate
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "New Lower Price"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="New Lower Price"
          >
            New Lower Price
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Promotional SKU"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Promotional SKU"
          >
            Promotional SKU
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Tiered Discounts"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Tiered Discounts"
          >
            Tiered Discounts
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Warranty"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Warranty"
          >
            Warranty
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Trade In"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Trade In"
          >
            Trade In
          </WorkAround>
        </li>
      </>
    );
  } else if (role == "ChannelManager") {
    remiButton = (
      <><li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >    <NavLink
          to="/DeletedPromotionWeb"
          onClick={() => localStorage.setItem("UPDATE", "true")}
        >
            Deleted Promotion
          </NavLink>

        </Row></li>
        <li className="menuLabel" onClick={showModal}>
          New Promotion
        </li>

        {props.location.pathname == "/HomeLoginMenuWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/VendorAdminDashboardWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        ) : props.location.pathname == "/DeletedPromotionWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : props.location.pathname == "/AlertWeb" ? (
          <li className="menuLabel" style={{ opacity: "0.5" }}>
            Publish Promotional Deck
          </li>
        )  : (
          <li
            className="menuLabel"
            style={{ opacity: showPublishBtn ? "0.5" : "1" }}
            onClick={() => (showPublishBtn ? "" : setShowPublishModal(true))}
          >
            Publish Promotional Deck
          </li>
        )}
        <li className="menuLabel">Download Templates</li>
        <hr />
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Percent Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Percent Off"
          >
            Blanket Percent Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Blanket Dollar Off"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Blanket Dollar Off"
          >
            Blanket Dollar Off
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Buy X Get Y"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Buy X Get Y"
          >
            Buy X Get Y
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Mail/Online Rebate"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Mail/Online Rebate"
          >
            Mail/Online Rebate
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "New Lower Price"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="New Lower Price"
          >
            New Lower Price
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Promotional SKU"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Promotional SKU"
          >
            Promotional SKU
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Tiered Discounts"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Tiered Discounts"
          >
            Tiered Discounts
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Warranty"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Warranty"
          >
            Warranty
          </WorkAround>
        </li>
        <li className="menuSubLabel">
          <WorkAround
            asyncExportMethod={() => {
              return new Promise((resolve) => setTimeout(resolve, 1000)).then(
                async () => {
                  const result: any = await downloadTempData.downloadTempData(
                    "Trade In"
                  );
                  return result.columns.map((dd: any) => dd);
                }
              );
            }}
            fileName="Trade In"
          >
            Trade In
          </WorkAround>
        </li>
      </>
    );
  } else if (role == "AccountManager") {
    remiButton = (
      <li>
        <Row
          align="middle"
          className={
            props.location.pathname == "/DeletedPromotionWeb"
              ? "menuSubLabel active"
              : "menuLabel"
          }
        >    <NavLink
          to="/DeletedPromotionWeb"
        // onClick={() => localStorage.setItem("UPDATE", "true")}
        >
            Deleted Promotion
          </NavLink>

        </Row></li>
    );
  }
  const [selected, setSelected] = useState<any>([]);

  return (
    <Row style={{ display: "inline" }}>
      <button onClick={() => setShowing(!showing)} className="menuButton">
        <div className="header-menu">
          <div className="header-menu1" />
          <div className="header-menu1" />
          <div className="header-menu1" />
        </div>
        <CSVLink
          data={csvData}
          headers={headers}
          style={{ display: "none" }}
          filename={`${typeOption}.csv`}
          id="download"
          target="_blank"
        >
          download
        </CSVLink>
      </button>
      {showing ? (
        <Row>
          <Col sm={24} lg={24} className={showing ? "sidebar" : "sidebar-none"}>
            <ul className="sidebar-ui">
              {homeButton}
              {remiButton}
              {adminiButton}
            </ul>
            <Modal
              destroyOnClose
              title={<h2 style={{ color: "#1890ff" }}>New promotion</h2>}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              maskClosable={false}
              centered
              footer={false}
            >
              <Formik
                enableReinitialize={true}
                initialValues={{
                  promotional_deck_name: "",
                  promo_type: "",
                  end_execution_detail: "",
                  dealer_execution_detail: "",
                }}
                onSubmit={onFinish}
                validationSchema={promotionValidation}
              >
                {({
                  touched,
                  errors,
                  submitForm,
                  values,
                  isValidating,
                }: any) => {
                  return (
                    <Form translate className="needs-validation">
                      <Row>
                        <Col span={24}>
                          <label
                            htmlFor="email"
                            style={{ display: "block", marginTop: "15px" }}
                          >
                            Promotional Deck Name
                          </label>{" "}
                          <Field
                            style={{
                              border: "none",
                              borderBottom: "1px solid black",
                              width: "100%",
                            }}
                            type="text"
                            name="promotional_deck_name"
                          />
                          {errors.promotional_deck_name &&
                            touched.promotional_deck_name && (
                              <div style={{ color: "red" }}>
                                {errors.promotional_deck_name}
                              </div>
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {" "}
                          <label
                            htmlFor="email"
                            style={{
                              display: "block",
                              marginTop: "15px",
                              marginBottom: "5px",
                            }}
                          >
                            Select the promo Type
                          </label>
                          <Field
                            style={{
                              width: "100%",
                              height: "30px",
                            }}
                            as="select"
                            name="promo_type"
                          >
                            <option value="">Select promo type</option>
                            <option value="Blanket Percent Off">
                              Blanket Percent Off
                            </option>
                            <option value="Blanket Dollar Off">
                              Blanket Dollar Off
                            </option>
                            <option value="Buy X Get Y">Buy X Get Y</option>
                            <option value="Mail/Online Rebate">
                              Mail/Online Rebate
                            </option>
                            <option value="New Lower Price">
                              New Lower Price
                            </option>
                            <option value="Promotional SKU">
                              Promotional SKU
                            </option>
                            <option value="Tiered Discounts">
                              Tiered Discounts
                            </option>
                            <option value="Trade In">Trade In</option>
                            <option value="Warranty">Warranty</option>
                          </Field>
                          {errors.promo_type && touched.promo_type && (
                            <div style={{ color: "red" }}>
                              {errors.promo_type}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <label
                            htmlFor="email"
                            style={{
                              display: "block",
                              marginTop: "15px",
                              marginBottom: "5px",
                            }}
                          >
                            Select the channel
                          </label>
                          <MultiSelect
                            onChange={setSelected}
                            value={selected}
                            options={options}
                            labelledBy="name"
                          />
                          {errorChannelMsg !== "" && (
                            <span style={{ color: "red" }}>
                              {errorChannelMsg}
                            </span>
                          )}
                          <br />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {" "}
                          <label
                            htmlFor="email"
                            style={{ display: "block", marginTop: "15px" }}
                          >
                            End Execution Detail
                          </label>
                          <Field
                            style={{
                              border: "none",
                              borderBottom: "1px solid black",
                              width: "100%",
                            }}
                            type="text"
                            autoComplete="off"
                            name="end_execution_detail"
                          />
                          {errors.end_execution_detail &&
                            touched.end_execution_detail && (
                              <div style={{ color: "red" }}>
                                {errors.end_execution_detail}
                              </div>
                            )}
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          {" "}
                          <label
                            htmlFor="email"
                            style={{ display: "block", marginTop: "15px" }}
                          >
                            Dealer Execution Detail
                          </label>
                          <Field
                            style={{
                              border: "none",
                              borderBottom: "1px solid black",
                              width: "100%",
                            }}
                            type="text"
                            autoComplete="off"
                            name="dealer_execution_detail"
                          />
                          {errors.dealer_execution_detail &&
                            touched.dealer_execution_detail && (
                              <div style={{ color: "red" }}>
                                {errors.dealer_execution_detail}
                              </div>
                            )}
                        </Col>
                      </Row>
                      <h3 style={{ fontWeight: "bolder", marginTop: "20px" }}>
                        Import File Options
                      </h3>
                      <Row>
                        <span>Click Browser to find the file to import</span>
                      </Row>
                      <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <span>Select type of file to upload</span>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Row>
                            <Col span={15}>
                              Upload Promo Visual file(PPT/PDF)
                            </Col>
                            <Col span={5}>
                              <input
                                type="file"
                                name="attachment"
                                className="custom-file-input"
                                style={{ width: "100%" }}
                                id="upload-photo"
                                onChange={(e) => {
                                  changeHandler1(e);
                                  e.target.value = "";
                                }}
                                accept=".pptx,.ppt,.pdf,.ppsx"
                              />
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "10px" }}>
                            <Col span={15}>
                              Upload Promo Data File (XLS/CSV)
                            </Col>
                            <Col span={5}>
                              <input
                                type="file"
                                name="file"
                                style={{ width: "100%" }}
                                className="custom-file-input"
                                id="upload-photo"
                                onChange={(e) => {
                                  changeHandler(e);
                                  e.target.value = "";
                                }}
                                accept=".xls,.xlsx,.csv"
                              />
                            </Col>
                          </Row>
                        </Col>
                        {errorMsg ? (
                          <Col>
                            <h3 style={{ color: "red" }}>
                              User must choose csv/xls file
                            </h3>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      <h4 style={{ marginTop: "20px" }}>
                        Selected file:{" "}
                        <Row>
                          {fileName}{" "}
                          {fileName ? (
                            <Button
                              type="primary"
                              onClick={() => discardFile("file")}
                              ghost
                              style={{ marginRight: "10px" }}
                            >
                              Discard
                            </Button>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Row>
                          {fileName1}{" "}
                          {fileName1 ? (
                            <Button
                              type="primary"
                              onClick={() => discardFile("attachment")}
                              ghost
                              style={{ marginRight: "10px" }}
                            >
                              Discard
                            </Button>
                          ) : (
                            ""
                          )}
                        </Row>
                      </h4>

                      <Row style={{ marginTop: "30px" }}>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Button
                            type="primary"
                            ghost
                            style={{ marginRight: "10px" }}
                            onClick={() => (
                              setIsModalVisible(false),
                              setFileName(""),
                              setFileName1(""),
                              setSelectedFile(null),
                              setSelectedFile1(null),
                              setSelected([])
                            )}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: "left" }}>
                          <Button type="primary" htmlType="submit">
                            Create
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </Modal>

            <Modal
              destroyOnClose
              closable={false}
              centered
              width="35%"
              onCancel={() => setShowPublishModal(false)}
              onOk={() => setShowPublishModal(false)}
              visible={showPublishModal}
              maskClosable={false}
              footer={false}
            >
              <Col>
                <Col>
                  <h2>Publish Deck</h2>
                </Col>
                <Formik
                  initialValues={{
                    "promotional_deck_name": window.localStorage.getItem("promo_name"),
                  }}
                  enableReinitialize
                  onSubmit={(values) => onPublishFinish(values)}
                >
                  {({ touched, errors, submitForm, values }: any) => {
                    return (
                      <Form translate className="needs-validation">
                        <Row>
                          <Col span={24}>
                            <label
                              htmlFor="email"
                              style={{ display: "block", marginTop: "15px" }}
                            >
                              Promotional Deck Name
                            </label>{" "}
                            <Field
                              style={{
                                border: "none",
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                              validate={validateName}
                              type="text"
                              autoComplete="off"
                              name="promotional_deck_name"
                            // initialValues={props.tempHeaderData.promotional_deck_name}
                            />
                            {errors.promotional_deck_name &&
                              touched.promotional_deck_name && (
                                <div style={{ color: "red" }}>
                                  {"Please select promotional deck name"}
                                </div>
                              )}
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24}>
                            {" "}
                            <label
                              htmlFor="email"
                              style={{
                                display: "block",
                                marginTop: "15px",
                                marginBottom: "5px",
                              }}
                            >
                              Select year
                            </label>
                            <Field
                              style={{
                                width: "100%",
                                height: "30px",
                              }}
                              validate={validateName}
                              as="select"
                              name="year"
                            >
                              <option value=" ">Select promo year</option>
                              <option value={moment().format("YYYY")}>
                                {moment().format("YYYY")}
                              </option>
                              <option value={moment().years() + 1}>
                                {moment().years() + 1}
                              </option>
                              <option value={moment().years() + 2}>
                                {moment().years() + 2}
                              </option>
                            </Field>
                            {errors.year && touched.year && (
                              <div style={{ color: "red" }}>
                                {"Please select promotional year"}
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "15px" }}>
                          <Col span={24}>
                            <label
                              htmlFor="email"
                              style={{
                                display: "block",
                                marginTop: "15px",
                                marginBottom: "5px",
                              }}
                            >
                              Select the channel
                            </label>
                            <MultiSelect
                              onChange={setSelected}
                              value={selected}
                              options={options}
                              labelledBy="name"
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            marginTop: "20px",
                            marginBottom: "10px",
                            display: "block",
                          }}
                        >
                          <label
                            style={{
                              marginTop: "15px",
                              marginBottom: "5px",
                            }}
                          >
                            Select Quarter(S)
                          </label>
                          <div role="group" aria-labelledby="radio-group">
                            <label style={{ marginRight: "10px" }}>
                              <Field
                                type="radio"
                                name="quarter"
                                value="quarter_1"
                                style={{ marginRight: "5px" }}
                              />
                              Quarter 1
                            </label>
                            <label style={{ marginRight: "10px" }}>
                              <Field
                                type="radio"
                                name="quarter"
                                style={{ marginRight: "5px" }}
                                value="quarter_2"
                              />
                              Quarter 2
                            </label>
                            <label style={{ marginRight: "10px" }}>
                              <Field
                                type="radio"
                                name="quarter"
                                style={{ marginRight: "5px" }}
                                value="quarter_3"
                              />
                              Quarter 3
                            </label>
                            <label style={{ marginRight: "10px" }}>
                              <Field
                                type="radio"
                                name="quarter"
                                style={{ marginRight: "5px" }}
                                value="quarter_4"
                              />
                              Quarter 4
                            </label>
                          </div>
                        </Row>

                        <Row style={{ marginTop: "30px" }}>
                          <Col span={12} style={{ textAlign: "right" }}>
                            <Button
                              type="primary"
                              ghost
                              style={{ marginRight: "10px" }}
                              onClick={() => setShowPublishModal(false)}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col span={12} style={{ textAlign: "left" }}>
                            <Button type="primary" onClick={submitForm}>
                              Publish
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>
            </Modal>
          </Col>
        </Row>
      ) : null}
    </Row>
  );
};

export default withRouter (SideNav)
