import axios from "axios";
import { openNotification, openErrorNotification } from "../../../components/src/Notification.web";
export const configJSON = require("./config");
const companySignUp = async function (payload) {
  const token = localStorage.getItem("token");
  try {
    const data = {
      "data": {
        "attributes": payload
      }
    }
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_company/companies`,
      data, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        token: token
      },
    }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    console.log("error from companySignUp:", e);
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

export { companySignUp };