import React, { useContext, useState, useRef } from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import "../../header/style.css";
// Customizable Area End
import PhoneInput from "react-phone-input-2";
import VendorAdminDashboardControllerWeb, {
  Props,
} from "./VendorAdminDashboardController.Web";
const ReactDragListView = require("react-drag-listview").default;
import {
  Tabs,
  Divider,
  Select,
  Upload,
  Card,
  Col,
  Button,
  Modal,
  Checkbox,
  Row,
  Input,
  Form,
  Popconfirm,
  Avatar,
  Radio,
  notification,
} from "antd";
import {
  DeleteFilled,
  SearchOutlined,
  EditFilled,
  UserOutlined,
  UploadOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import SideNav from "../../../components/src/SideNav";
import { FormInstance } from "antd/lib/form";
const TopNav = require("../../../components/src/TopNav").default;
import EditableTable from "./BrandTable";
import CEditableTable from "./ChannelTab";
import BEditableTable from "./BusinessTable";
import { openNotification } from "../../../components/src/Notification.web";
import MultiSelect from "react-multi-select-component";
const { TabPane } = Tabs;
const { DragColumn } = ReactDragListView;
const { Option } = Select;
const EditableContext = React.createContext(null);

export const userResetPassLink = require("./Service.web");
export const searchRetailer = require("./Service.web");
export const selectRetailer = require("./Service.web");
export const updateUserPassword = require("./Service.web");
export const updateUserAvatar = require("./Service.web");
export const inviteRetailer = require("./Service.web");
export const inviteAcManager = require("./Service.web");
export const getChannelReqdata = require("./Service.web");

export const channelRequest = require("./Service.web");
export const retailerRequest = require("./Service.web");
export const deleteProfile = require("./Service.web");
export const addUser = require("./Service.web");
export const edituser = require("./Service.web");
export const deleteUser = require("./Service.web");
export const getCurrentUserD = require("./Service.web");
export const deleteBrand = require("./Service.web");
export const addBusiness = require("./Service.web");
export const addChannels = require("./Service.web");
export const addBrand = require("./Service.web");
export const assignedChannel = require("./Service.web");
export const getChannel = require("./Service.web");
export default class VendorAdminDashboardWeb extends VendorAdminDashboardControllerWeb {
  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);

  }
  formRef = React.createRef<FormInstance>();
  onRegister = async (values: any) => {
    const datas = await addBrand.addBrand(values, "edit");
  };

  assignTheChannel = async (values: any) => {
    var userID = 0;
    this.state.profileList.map((id: any) => {
      userID = id.id;
    })
    let idChannel: any = 0;
    this.state.profileList.map((channelID: any) => {
      idChannel = channelID.channel_id
    })
    const newArray: any = [values].concat(idChannel)
    let removeDuplicate: any = newArray.filter((item: any, i: any, ar: any) => ar.indexOf(item) === i);
    let removeString: any = removeDuplicate.filter((item: any) => item)
    let fd = new FormData();
    fd.append("channel", JSON.stringify(removeString));
    fd.append("user_id", userID.toString());
    let result = await assignedChannel.assignedChannelToRetailer(fd);
    if (result.success) {
      this.getProfilesList();
      openNotification(result.message, "success")
    }
  }
  handleDelete = async (id: any) => {
    const datas = await deleteBrand.deleteBrand(id, "add");
    if (datas.success) {
      this.getBrandsList();
    }
  };
  handleEditBrand = async () => {
    this.setEditRow(false);
    const datas = await addBrand.addBrand(this.state.editId, "edit");
    if (datas.success) {
      this.getBrandsList();
    }
  };

  handleAdd = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = await addBrand.addBrand(values, "add");
    if (datas.success) {
      this.setState({ btnLoading: false })
      this.getBrandsList();
      this.setState({ addBrandModal: false })
      openNotification(datas.message, "success")
    } else {
      this.setState({ btnLoading: false })
    }
  };
  handleSave = (row: any) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
  };
  handleDeleteBusiness = (key: any) => {
    const dataSourceBusiness = [...this.state.dataSourceBusiness];
    this.setState({
      dataSourceBusiness: dataSourceBusiness.filter((item) => item.key !== key),
    });
  };
  handleAddBusiness = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = await addBusiness.addBusiness(values, "add");
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.getBusinessUnitsList();
      this.setState({
        addBuModal: false,
      });
    } else {
      this.setState({ btnLoading: false })
    }
  };

  handleAddChannels = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = await addChannels.addChannels(values, "add");
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.getChannels()
      window.localStorage.setItem("UPDATE", "true")
      this.setState({
        addChannelModal: false,
      });
    } else {
      this.setState({ btnLoading: false })
    }
  };
  getChannel = async () => {
    const datas = await getChannel.getChannels();
    this.setState({
      options: datas && datas.Data ? datas.Data.map((data: any) => ({ label: data.name, value: data.id })) : [], channelList: datas && datas.Data ? datas.Data : []
    })
  };
  handleSaveBusiness = (row: any) => {
    const newData = [...this.state.dataSourceBusiness];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });

    this.setState({
      dataSourceBusiness: newData,
    });
  };
  handleDeleteProfile = async () => {
    this.setState({ btnLoading: true })
    const id = this.state.editId;
    const datas = await deleteProfile.deleteProfile(id);
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.getProfilesList();
      this.setState({ deleteModalVisible: false });
    } else {
      this.setState({ btnLoading: false })
    }
  };

  handleDeleteUsers = async (id: any) => {
    const datas = await deleteUser.deleteUser(id);
    if (datas.success) {
      openNotification(datas.message, "success")
      this.getRetailersList();
      this.getRetailerdata()
      this.setState({ deleteModalVisible: false });
    }
  };
  onSubmit = async (values: any) => {
    this.setState({ btnLoading: true })
    const result = await edituser.edituser(values, "", "add");
    if (result.success) {
      this.setState({ btnLoading: false })
      openNotification(result.message, "success")
      this.getRetailersList();
      this.getRetailerdata()
      this.setInviteUserVisible(false);
    } else {
      this.setState({ btnLoading: false })
    }
  };
  onEditSubmit = async (values: any) => {
    this.setState({ btnLoading: true })
    const idd: any = this.state.editId;
    const result = await edituser.edituser(values, idd, "edit");
    this.formRef.current?.resetFields();
    if (result.success) {
      this.setState({ btnLoading: false })
      openNotification(result.message, "success")
      this.getRetailersList();
      this.getRetailerdata()
      this.setModalVisible(false);
    } else {
      this.setState({ btnLoading: false })
    }
  };

  onSubmitInvite = async (values: any) => {
    this.setState({ btnLoading: true })
    const result = await inviteRetailer.inviteRetailer(values);
    if (result.success) {
      this.setState({ btnLoading: false })
      openNotification(result.message, "success")
      this.formRef.current?.resetFields();
      this.setInviteModalVisible(false);
    } else {
      this.setState({ btnLoading: false })
    }
  };

  onSubmitInviteAc = async (values: any) => {
    this.setState({ btnLoading: true })
    const result = await inviteAcManager.inviteAcManager(values);
    if (result.success) {
      this.setState({ btnLoading: false })
      openNotification(result.message, "success")
      this.formRef.current?.resetFields();
      this.setState({ inviteAcManager: false })
    } else {
      this.setState({ btnLoading: false })
    }
  };
  setModalVisible(modalVisible: any) {
    this.setState({ modalVisible });
  }
  setVendorModalVisible(vendorModalVisible: any) {
    this.setState({ vendorModalVisible });
  }
  setInviteModalVisible(inviteModalVisible: any) {
    this.setState({ inviteModalVisible });
  }
  setEditRow(editRow: any) {
    this.setState({ editRow });
  }
  onRetailerRequest = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = await retailerRequest.retailerRequest(this.state.editStatusData);
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.getRetailersList();
      this.getProfilesList()
      this.getRetailersRequestData()
      this.setState({ editModalVisible: false, editStatusData: { email: "", status: "", id: "" }, editChData: { email: "", status: "", channel: [] } });
    } else {
      this.setState({ btnLoading: false })
    }
  };
  onChange = (e: any, data: any) => {
    this.setState({
      editStatusData: { "email": data.email, "status": e.target.value, "id": data.id },
      editChData: { "email": data.email, "status": e.target.value, "channel": data.channel_id }
    });
  };
  setInviteUserVisible(inviteUserVisible: any) {
    this.setState({ inviteUserVisible });
  }
  handleCancelPassword = () => { 
    this.setModalVisible(false)
    this.setState({
      selectedFile: [],
      imagePreviewUrl: "",
      imgFile: ""
    })
  }
  changeHandler = (event: any) => {
    this.setState({ selectedFile: event.target.files[0] });
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imgFile: file,
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  };

  handleSubmission = async () => {
    const formData: any = new FormData();

    formData.append("attachment", this.state.selectedFile);
    const result = await updateUserAvatar.updateUserAvatar(
      formData,
      this.state.editId
    );
    if (result.success) {
      openNotification(result.message, "success")
      this.setModalVisible(false);
    } else {
      console.log("error from updateUserAvatar:", result.error);
    }
  };
  onChangePassword = async (values: any) => {
    this.setState({ btnLoading: true })
    const result = await updateUserPassword.updateUserPassword(
      values,
      this.state.editId
    );
    if (result.success) {
      this.setState({ btnLoading: false })
      openNotification(result.message, "success")
      this.setModalVisible(false);
      this.getRetailerdata()
    } else {
      this.setState({ btnLoading: false })
      notification.open({
        message: "Error",
        type: "error",
        description: result.error,
      });
      console.log("error from updateUserPassword:", result.error);
    }
  };
  normFile = (e: any) => {
  };
  handleSearch = async (e: any) => {
    if (e.target.value == "") {
      this.getRetailersList()
    }
    else {
      const datas = await searchRetailer.searchRetailer(e.target.value);
      if (datas.status == "ok") {
        this.setState({ retailerList: datas && datas.retailers })
      }
      else {
        this.getRetailersList()
      }
    }
  }

  // Customizable Area Start
  onRetailerFinish = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = values == {} ? "" : await selectRetailer.selectRetailer(values);
    if (datas.success) {
      this.setState({ btnLoading: false })
      openNotification(datas.message, "success")
      this.setVendorModalVisible(false);
      this.getRetailersList();
      this.getProfilesList()
    } else {
      this.setState({ btnLoading: false })
    }
  };
  // Customizable Area End

  sendResetLink = async (values: any) => {
    const result = await userResetPassLink.userResetPassLink(values);
    if (result.success) {
      openNotification(result.message, "success");
    } else {
      console.log("error from userSignUp:", result.error);
    }
  };
  getCurrentUser = async (id: any) => {
    const datas = await getCurrentUserD.getCurrentUserD(id);
    if (datas) {
      this.setState({ editProfileData: datas && datas.Data ? datas.Data : [] });
      const { editProfileData }: any = this.state;
      this.formRef.current?.setFieldsValue({
        first_name: editProfileData.first_name,
        last_name: editProfileData.last_name,
        phone_number: editProfileData.phone_number,
        role: editProfileData.role,
      });
    }
  };

  selectCh = async (userID: any) => {
    let selectedData: any = this.state.selected.map((value: any) => {
      return value.value
    })
    let removeDuplicate: any = selectedData.filter((item: any, i: any, ar: any) => ar.indexOf(item) === i);
    let removeString: any = removeDuplicate.filter((item: any) => item)
    let fd = new FormData();
    fd.append("channel", JSON.stringify(removeString));
    fd.append("user_id", userID);
    let result = await assignedChannel.assignedChannelToRetailer(fd);
    if (result.success) {
      this.getProfilesList();
      openNotification(result.message, "success")
    }
  }

  getSelectedOptions = (idx: number) => {
    let values: any = []
    if (this.state.profileList[idx]?.channel_name !== "") {
      values = this.state.profileList[idx]?.channel_name?.map((item: string, index: number) => { return { label: item, value: this.state.profileList[idx]?.channel_id[index] ?? 0 } }) ?? [];
    }
    return values;
  };
  getChannelRequestdata = async () => {
    const result = await getChannelReqdata.getChannelReqdata();
    if (result.success) {
      this.setState({ channelReqData: result.data })
      // this.props.navigation.history.push("/"); 
    } else {
      console.log("error from userSignUp:", result.error);
      // setBtnLoading(false);
      // addToast(result.error, ERROR_TOAST);
    }
  }
  onChannelRequest = async (values: any) => {
    this.setState({ btnLoading: true })
    const datas = await channelRequest.channelRequest(this.state.editChData);
    if (datas.success) {
      this.setState({ btnLoading: false })
      // this.setVendorModalVisible(false);
      openNotification(datas.message, "success")
      this.getChannelRequestdata()
      this.setState({ channelModalVisible: false, editStatusData: { email: "", status: "", id: "" }, editChData: { email: "", status: "", channel: [] } });
      // this.setState({vendorModalVisible:false})
    } else {
      this.setState({ btnLoading: false })
    }
  };
  render() {
    const {
      channelReqData, userList,
      retailerReqData,
      profileList,
      editProfileData, imagePreviewUrl
    }: any = this.state;
    const filterRetailerList = this.state.retailerList.filter(function (el: any) {
      return el.company_name != "" && el.company_name != null;
    });
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <TopNav />
        <div className="main-content-div">
          <SideNav />

          <div className="main-content">
            {/* Customizable Area Start */}
            <View>
              <h2 style={{ marginTop: "10px" }}>Admin</h2>
              <Divider style={{ margin: "0px" }} />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Retailers" key="1">
                  {window.localStorage.getItem("Role") == "AccountManager" ?
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setInviteModalVisible(true)
                      }}
                    >
                      Invite new retailer
                    </Button>

                    :
                    <Button
                      type="primary"
                      onClick={() =>
                        this.setState({ vendorModalVisible: true })
                      }
                    >
                      Add new retailer
                    </Button>

                  }
                  {window.localStorage.getItem("Role") == "ChannelManager" ? <Button
                    style={{ marginLeft: "12px" }}
                    type="primary"
                    onClick={() => this.setState({ inviteAcManager: true })}
                  >
                    Invite Account manager
                  </Button> : ""}
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2
                          style={{ marginBottom: "0px", color: "#0B518E" }}
                        >
                          Invite new Account manager
                        </h2>
                        <h4
                          style={{ marginTop: "3em", textAlign: "center" }}
                        >
                          To Invite a new Account manager please provide the
                          following fields
                        </h4>
                      </>
                    }
                    centered
                    width="25%"
                    onCancel={() => this.setState({ inviteAcManager: false })}
                    onOk={() => this.setState({ inviteAcManager: false })}
                    visible={this.state.inviteAcManager}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onSubmitInviteAc}
                      id="custom-login-id"
                    >
                       <Form.Item
                        name="first_name"
                        rules={[
                          { required: true, message: "First name is required" },
                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="First name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="last_name"
                        rules={[
                          { required: true, message: "last name is required" },
                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="Last Name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="company_name"
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelCompany}
                        />
                      </Form.Item>

                      <Form.Item
                        name="email"
                        rules={[{ required: true }, { type: "email", message: "enter valid email" }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelEmail}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={2}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              onClick={() =>
                                this.setState({ inviteAcManager: false })
                              }
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={this.state.btnLoading}
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Send
                            </Button>
                          </Col>

                          <Col span={2}></Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  </Modal>
                  {window.localStorage.getItem("Role") == "AccountManager" ?"" :
                  <Button
                    type="primary"
                    onClick={() =>
                      retailerReqData && retailerReqData.length > 0 ?
                        this.setState({ editModalVisible: true }) : ""
                    }
                    style={{ marginLeft: "20px" }}
                  >
                    Pending request ({retailerReqData && retailerReqData.length > 0 ? retailerReqData && retailerReqData.length : 0})
                  </Button>}
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      backgroundColor: "#EFEFEF",
                      margin: "15px",
                    }}
                    title={
                      <h2 style={{ marginBottom: "0px" }}>
                        Retailer request
                      </h2>
                    }
                    centered
                    width="70%"
                    visible={this.state.editModalVisible}
                    footer={false}
                    maskClosable={false}
                    onOk={() => this.setState({ editModalVisible: false })}
                    onCancel={() =>
                      this.setState({ editModalVisible: false })
                    }
                  >
                    <Col style={{}}>
                      <Card
                        bodyStyle={{ display: "flex" }}
                        bordered={false}
                      >
                        <Col span={4}>{"Retailer Name"}</Col>
                        <Col span={4}>{"First Name"}</Col>
                        <Col span={4}>{"Last Name"}</Col>
                        <Col span={6}>{"Email"}</Col>
                      </Card>
                      <Form
                        onFinish={this.onRetailerRequest}
                        ref={this.formRef}
                        name="control-ref"
                        id="custom-login-id"
                      >
                        {retailerReqData &&
                          retailerReqData.map((data: any) => (
                            <>
                              <Card
                                bodyStyle={{
                                  display: "flex",
                                  fontWeight: "bold",
                                }}
                                style={{ marginBottom: "15px" }}
                              >
                                <Col span={4}>{data.username}</Col>
                                <Col span={4}>{data.first_name}</Col>
                                <Col span={4}>{data.last_name}</Col>

                                <Col span={6}>
                                  {data.email}
                                  <Form.Item
                                    name="email"
                                    initialValue={data.email}
                                  >  </Form.Item>
                                </Col>

                                <Form.Item
                                  name="status"
                                  initialValue={data.status}
                                >
                                  <Col style={{ fontSize: "20px" }}>
                                    <Radio.Group
                                      onChange={(e: any) => this.onChange(e, data)}
                                      defaultValue={data.status}
                                    >
                                      <Radio value="accept">Accept</Radio>
                                      <Radio value="decline">Decline</Radio>
                                    </Radio.Group>
                                  </Col>
                                </Form.Item>
                              </Card>
                            </>
                          ))}
                        <Form.Item>
                          <Col style={{ textAlign: "center" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={this.state.btnLoading}
                              style={{
                                width: "200px",
                                borderRadius: "10px",
                              }}
                            >
                              Save
                            </Button>
                          </Col>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Modal>

                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                    }}
                    title={
                      <>
                        <h2
                          style={{ marginBottom: "0px", color: "#0B518E" }}
                        >
                          Search for Retailer
                        </h2>

                      </>
                    }
                    onOk={() => (this.setState({ vendorModalVisible: false }), this.getRetailersList(), this.getProfilesList())}
                    onCancel={() =>
                      (this.setState({ vendorModalVisible: false }), this.getRetailersList(), this.getProfilesList())
                    }
                    centered
                    width="50%"
                    visible={this.state.vendorModalVisible}
                    maskClosable={false}
                    footer={
                      false
                    }
                  >

                    <h4
                      style={{ marginTop: "1em", marginBottom: "1em", textAlign: "center" }}
                    >
                      Enter Retailer to add
                    </h4>
                    <Col style={{ backgroundColor: "#EFEFEF" }}>
                      <Row style={{ paddingTop: "20px" }}>
                        <Col span={2}></Col>
                        <Col span={20}>
                          <Input
                            type="text"
                            onChange={(e) => this.handleSearch(e)}
                            placeholder="Search by Retailer name, Retailer email, Company name"
                            suffix={
                              <SearchOutlined
                                translate="yes"
                                style={{ color: "rgba(0,0,0,.45)" }}
                              />
                            }
                          />
                        </Col>
                        <Col span={2}></Col>{" "}
                      </Row>
                      <Form
                        onFinish={this.onRetailerFinish}
                        ref={this.formRef}
                        name="control-ref"
                        id="custom-login-id"
                      >
                        <Col
                          style={{ margin: "20px", textAlign: "center" }}
                          span={24}
                        >
                          {"Company Name"}
                        </Col>
                        <Form.Item name="retailer_id"
                          rules={[{ required: true, message: 'Input should not be empty!' }]} style={{ textAlign: "center", paddingBottom: "10px" }}>

                          <Radio.Group
                            style={{ width: "100%" }}
                          >  {filterRetailerList &&
                            filterRetailerList.map((data: any) => (
                              <>

                                <Row>
                                  <Col span={2}></Col>
                                  <Col span={20}>
                                    <Card
                                      bodyStyle={{
                                        fontWeight: "bold",
                                      }}
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <Row align="middle"
                                        style={{ textAlign: "center" }}
                                      >
                                        <Col span={2}>
                                          <Radio
                                            value={data.id}
                                          />

                                        </Col>
                                        <Col span={20}>
                                          <Row justify="center">
                                            {data.company_name}</Row><Row justify="center">{data.txn_id}</Row>
                                        </Col>
                                        <Col span={2}></Col>
                                      </Row>
                                      {/* <Row style={{ textAlign: "center" }}><Col span={2}></Col>
                                        <Col span={20}><span style={{ fontSize: "12px" }}>{data.txn_id}</span></Col>
                                        <Col span={2}></Col></Row> */}
                                    </Card>
                                  </Col>
                                  <Col span={2}></Col>
                                </Row>
                              </>
                            ))}</Radio.Group>
                        </Form.Item>
                        <Form.Item>
                          <Row
                            style={{
                              backgroundColor: "white",
                              paddingTop: "30px",
                            }}
                          >
                            <Col span={1}></Col>
                            <Col span={7} style={{ textAlign: "center" }}>
                              <Button
                                onClick={() =>
                                  (this.setState({ vendorModalVisible: false }), this.getRetailersList())
                                }
                                style={{ width: "100px", borderRadius: "10px" }}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={7} style={{ textAlign: "center" }}>
                              <Button
                                htmlType="submit"
                                loading={this.state.btnLoading}
                                type="primary"
                                style={{ width: "100px", borderRadius: "10px" }}
                              >
                                Add
                              </Button>
                            </Col>
                            <Col span={7} style={{ textAlign: "center" }}>
                              <Button
                                onClick={() => (
                                  this.setVendorModalVisible(false),
                                  this.setInviteModalVisible(true)
                                )}
                                style={{ width: "100px", borderRadius: "10px" }}
                              >
                                Invite
                              </Button>
                            </Col>
                            <Col span={1}></Col>
                          </Row>
                        </Form.Item>


                      </Form> </Col>
                  </Modal>
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2
                          style={{ marginBottom: "0px", color: "#0B518E" }}
                        >
                          Invite new Retailer
                        </h2>
                        <h4
                          style={{ marginTop: "3em", textAlign: "center" }}
                        >
                          To Invite a new retailer please provide the
                          following fields
                        </h4>
                      </>
                    }
                    centered
                    width="25%"
                    onCancel={() => this.setState({ inviteModalVisible: false })}
                    onOk={() => this.setState({ inviteModalVisible: false })}
                    visible={this.state.inviteModalVisible}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onSubmitInvite}
                      id="custom-login-id"
                    >
                         {/* <Form.Item
                        name="first_name"
                        rules={[
                          { required: true, message: "First name is required" },
                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="First name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="last_name"
                        rules={[
                          { required: true, message: "last name is required" },
                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                        ]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="Last Name"
                        />
                      </Form.Item> */}
                      <Form.Item
                        name="company_name"
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelCompany}
                        />
                      </Form.Item>

                      <Form.Item
                        name="email"
                        rules={[{ required: true }, { type: "email", message: "enter valid email" }]}
                      >
                        <Input
                          style={{
                            border: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder={this.labelEmail}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={2}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              onClick={() =>
                                this.setInviteModalVisible(false)
                              }
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col span={1}></Col>
                          <Col span={9} style={{ textAlign: "center" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={this.state.btnLoading}
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                              }}
                            >
                              Send
                            </Button>
                          </Col>

                          <Col span={2}></Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  </Modal>
                  <Card bodyStyle={{ display: "flex" }} bordered={false}>
                    <Col span={6}>{"Company Name"}</Col>
                    <Col span={6} >{"Channel"}</Col>
                    <Col span={6}>{"Tax ID"}</Col>
                    <Col span={6}>{"Address"}</Col>
                  </Card>
                  {profileList &&
                    profileList.map((data: any, index: number) => (
                      <Card
                        bodyStyle={{ display: "flex", fontWeight: "bold" }}
                        style={{ marginBottom: "15px" }}
                        bordered={true}
                      >
                        <Col span={6}>{data.company}</Col>
                        <Col span={6} style={{ paddingRight: "20px" }}>
                          {data.channel_name !== "" ? data.channel_name.join(", ") : "No Channels Assigned"}
                          <br />

                          <MultiSelect 
                            onChange={(values: any) => { this.setState({ selected: values }, () => { this.selectCh(data.id) }) }}
                            value={this.getSelectedOptions(index)}
                            options={data.channels.map((item: any) => (
                              {
                                label: item.name,
                                value: item.id,
                              }
                            ))}
                            labelledBy="name"
                            ClearSelectedIcon
                          />
                        </Col>
                        <Col span={6}>{data.txn_id}</Col>
                        <Col span={6}>{data.address}</Col>
                        <Col style={{ fontSize: "20px" }}>
                          <DeleteFilled style={{ cursor: "pointer" }}
                            translate="yes"
                            onClick={() =>
                              this.setState({
                                editId: data.request_id,
                                deleteModalVisible: true,
                              })
                            }
                          />
                        </Col>
                      </Card>
                    ))}
                  <Modal
                    destroyOnClose
                    bodyStyle={{
                      marginTop: "-20px",
                      margin: "15px",
                    }}
                    title={
                      <>
                        <h2
                          style={{ marginBottom: "0px", color: "#0B518E" }}
                        >
                          Please Confirm
                        </h2>
                      </>
                    }
                    centered
                    width="30%"
                    onCancel={() => this.setState({ deleteModalVisible: false })}
                    onOk={() => this.setState({ deleteModalVisible: false })}
                    visible={this.state.deleteModalVisible}
                    maskClosable={false}
                    footer={
                      <Row>
                        <Col span={2}></Col>
                        <Col span={9} style={{ textAlign: "center" }}>
                          <Button
                            onClick={() =>
                              this.setState({ deleteModalVisible: false })
                            }
                            style={{ width: "100px", borderRadius: "10px" }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={9} style={{ textAlign: "center" }}>
                          <Button
                            onClick={() => this.handleDeleteProfile()}
                            type="primary"
                            htmlType="submit"
                            loading={this.state.btnLoading}
                            style={{ width: "100px", borderRadius: "10px" }}
                          >
                            Delete
                          </Button>
                        </Col>
                        <Col span={2}></Col>
                      </Row>
                    }
                  >
                    <Col style={{}}>
                      <Row
                        style={{
                          fontSize: "100px",
                          justifyContent: "center",
                        }}
                      >
                        <WarningTwoTone translate="yes" />
                      </Row>
                      <Row style={{ justifyContent: "center" }}>
                        Please Confirm that you want to remove this item.
                      </Row>
                    </Col>
                  </Modal>
                </TabPane>

                {window.localStorage.getItem("Role") == "AccountManager" ? "" :
                  <>
                    {window.localStorage.getItem("Role") == "ChannelManager" ? "" :
                      <TabPane tab="Users" key="2">
                        <Button
                          type="primary"
                          onClick={() => this.setInviteUserVisible(true)}
                        >
                          Add new user
                        </Button>



                        <Modal
                          destroyOnClose
                          bodyStyle={{
                            marginTop: "-20px",
                            margin: "15px",
                          }}
                          title={
                            <>
                              <h2
                                style={{ marginBottom: "0px", color: "#0B518E" }}
                              >
                                New user
                              </h2>
                            </>
                          }
                          centered
                          width="25%"
                          visible={this.state.inviteUserVisible}
                          onCancel={() =>
                            this.setState({ inviteUserVisible: false })
                          }
                          maskClosable={false}
                          onOk={() => this.setState({ inviteUserVisible: false })}
                          footer={false}
                        >
                          <Form
                            ref={this.formRef}
                            name="control-ref"
                            onFinish={this.onSubmit}
                            id="custom-login-id"
                          >
                            <Form.Item
                              name="first_name"
                              rules={[
                                { required: true, message: "First name is required" },
                                { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                              ]}
                            >
                              <Input
                                // type="text"
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder="First name"
                              />
                            </Form.Item>
                            <Form.Item
                              name="last_name"
                              rules={[
                                { required: true, message: "last name is required" },
                                { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid last name" }
                              ]}
                            >
                              <Input type="text"
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder={this.labelLastname}
                              />
                            </Form.Item>
                            <Form.Item
                              name="email"
                              rules={[
                                { required: true },
                                { type: "email", message: "enter valid email" },
                              ]}
                            >
                              <Input
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder={this.labelEmail}
                              />
                            </Form.Item>
                            <Form.Item
                              name="password"
                              rules={[
                                { required: true },
                                {
                                  pattern: new RegExp(
                                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                                  ),
                                  message:
                                    "Password must be 8 character and contains at least one A-Z, a-z, 0-9, @$%#",
                                },
                              ]}
                            >
                              <Input
                                type="password"
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder={this.labelPassword}
                              />
                            </Form.Item>
                            <Form.Item
                              name="confirm_password"
                              dependencies={["password"]}
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: "Please confirm your password!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("password") === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        "The two passwords that you entered do not match!"
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input
                                type="password"
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder={this.labelRePassword}
                              />
                            </Form.Item>
                            <Form.Item name="role" rules={[{ required: true, message: "ROle is required" }]}>
                              <Select placeholder="select any" >
                                <Option value={6}>Channel Manager</Option>
                                <Option value={7}>Account Manager</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item>
                              <Row>
                                <Col span={2}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    onClick={() =>
                                      this.setInviteUserVisible(false)
                                    }
                                    style={{
                                      width: "100px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                      width: "100px",
                                      borderRadius: "10px",
                                    }}
                                    loading={this.state.btnLoading}
                                  >
                                    Save
                                  </Button>
                                </Col>

                                <Col span={2}></Col>
                              </Row>
                            </Form.Item>
                          </Form>
                        </Modal>

                        <Card bodyStyle={{ display: "flex" }} bordered={false}>
                          <Col span={7}>{"First Name & Last Name"}</Col>
                          <Col span={7}>{"Email"}</Col>
                          <Col span={5}>{"Password"}</Col>
                        </Card>
                        {userList &&
                          userList.map((data: any) => (
                            <Card
                              bodyStyle={{ display: "flex", fontWeight: "bold" }}
                              style={{ marginBottom: "15px" }}
                            >
                              <Col span={7}>
                                {data.first_name} {data.last_name}
                              </Col>
                              <Col span={7}>{data.email}</Col>
                              <Col span={5}>
                                <Button onClick={() => this.sendResetLink(data.id)} style={{ borderRadius: "10px" }}>
                                  Send Reset Link
                                </Button>
                              </Col>
                              <Col span={1} style={{ fontSize: "20px" }}>
                                <EditFilled
                                  onClick={() => (
                                    this.setModalVisible(true),
                                    this.getCurrentUser(data.id),
                                    this.setState({ editId: data.id })
                                  )}
                                  translate="yes"
                                />
                              </Col>
                              <Col span={1} style={{ fontSize: "20px" }}>
                                <Popconfirm
                                  title="Sure to delete?"
                                  onConfirm={() =>
                                    this.handleDeleteUsers(data.id)
                                  }
                                >
                                  <Col style={{ fontSize: "20px" }}>
                                    <DeleteFilled style={{ cursor: "pointer" }}
                                      translate="yes"
                                    />
                                  </Col>
                                </Popconfirm>
                              </Col>
                            </Card>
                          ))}
                        <Modal
                          bodyStyle={{
                            marginTop: "-20px",
                            margin: "15px",
                          }}
                          title={
                            <h2 style={{ marginBottom: "0px" }}>
                              Edit profile account
                            </h2>
                          }
                          centered
                          destroyOnClose
                          width="85%"
                          visible={this.state.modalVisible}
                          maskClosable={false}
                          footer={false}
                          onCancel={() => (
                            this.setModalVisible(false),
                            this.setState({ editProfileData: [], imagePreviewUrl: "", imgFile: "" })
                          )}
                          onOk={() => (
                            this.setModalVisible(false),
                            this.setState({ editProfileData: [], imgFile: "", imagePreviewUrl: "" })
                          )}
                        >
                          <Row>
                            <Col span={6} style={{ padding: "10px" }}>
                              <Card style={{ textAlign: "center" }}>
                                <Avatar
                                  src={editProfileData && editProfileData.image}
                                  size={100}
                                />
                                <Row style={{ justifyContent: "center" }}>
                                  <Col>
                                    <h1 style={{ marginBottom: "-5px" }}>
                                      {editProfileData.first_name}{" "}
                                      {editProfileData.last_name}
                                    </h1>
                                  </Col>
                                </Row>
                                <Row style={{ justifyContent: "center" }}>
                                  <Col>
                                    <h4 style={{ marginBottom: "-3px" }}>
                                      {editProfileData.email}
                                    </h4>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                            <Col span={18} style={{ padding: "10px" }}>
                              <Card>
                                <Tabs
                                  tabBarExtraContent={{
                                    left: (
                                      <h2
                                        style={{
                                          marginRight: "20px",
                                          color: "#1890ff",
                                        }}
                                      >
                                        PROFILE ACCOUNT
                                      </h2>
                                    ),
                                  }}
                                >
                                  <TabPane tab="Personal Info" key="1">
                                    <Form
                                      layout="vertical"
                                      ref={this.formRef}
                                      onFinish={this.onEditSubmit}
                                      size="middle"
                                    >
                                      <Form.Item
                                        label="First Name"
                                        name="first_name"

                                        rules={[
                                          { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                                        ]}
                                        initialValue={editProfileData.first_name}
                                      >
                                        <Input />
                                      </Form.Item>
                                      <Form.Item
                                        label="Last Name"
                                        name="last_name"
                                        initialValue={editProfileData.last_name}
                                      >
                                        <Input />
                                      </Form.Item>{" "}
                                      <Form.Item
                                        label="Mobile Number"
                                        name="phone_number"
                                        initialValue={
                                          editProfileData.phone_number
                                        }
                                      >
                                        <PhoneInput
                                          inputStyle={{
                                            height: "32px",
                                            width: "inherit",
                                            borderRadius: "0px",
                                          }}
                                          country={"us"}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name="role"
                                        label="Role"
                                        hasFeedback
                                        initialValue={editProfileData.role}
                                      >
                                        <Select placeholder={
                                          <span
                                            style={{ color: "black", fontWeight: "bold" }}
                                          >
                                            {editProfileData.role}
                                          </span>
                                        }>
                                          <Option value="System_Admin">System Admin</Option>
                                          <Option value="Channel_Manager">Channel Manager</Option>
                                          <Option value="Account_Manager">Account Manager</Option>
                                        </Select>
                                      </Form.Item>
                                      <Form.Item>
                                        <Button
                                          htmlType="submit"
                                          type="primary"
                                          loading={this.state.btnLoading}
                                          style={{ marginRight: "20px" }}
                                        >
                                          Save Changes
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            this.setModalVisible(false)
                                          }
                                        >
                                          Cancel
                                        </Button>
                                      </Form.Item>
                                    </Form>
                                  </TabPane>
                                  <TabPane tab="Change Photo" key="2">
                                    <Row>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Nulla blandit elit eget
                                        magna faucibus eleifend. Nam nec magna non
                                        massa dictum ultrices id id elit. Donec ac
                                        odio erat. Nam sodales sollicitudin
                                        mollis.{" "}
                                      </p>
                                    </Row>

                                    <Row>
                                      {imagePreviewUrl ? (
                                        <img src={imagePreviewUrl} width="164" height="164" />
                                      ) : (
                                        <Avatar
                                          src={editProfileData.image}
                                          shape="square"
                                          size={164}
                                          icon={<UserOutlined translate="yes" />}
                                        />
                                      )}
                                    </Row>
                                    <Row style={{ marginTop: "10px" }}>
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        name="file"
                                        onChange={this.changeHandler}
                                      />
                                    </Row>
                                    <Row style={{ marginTop: "3em" }}>
                                      <Button
                                        type="primary"
                                        onClick={this.handleSubmission}
                                        style={{ marginRight: "20px" }}
                                      >
                                        Submit
                                      </Button>
                                      <Button
                                        onClick={this.handleCancelPassword
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </Row>
                                  </TabPane>
                                  <TabPane tab="Change Password" key="3">
                                    <Form
                                      layout="vertical"
                                      onFinish={this.onChangePassword}
                                      size="middle"
                                    >
                                      <Form.Item
                                        name="current_password"
                                        label="Current Password"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please confirm your Current password!",
                                          },
                                        ]}
                                      >
                                        <Input type="password" />
                                      </Form.Item>
                                      <Form.Item
                                        name="new_password"
                                        label="New Password"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please confirm your new password!",
                                          },
                                        ]}
                                      >
                                        <Input type="password" />
                                      </Form.Item>
                                      <Form.Item
                                        name="retype_password"
                                        label="Re-type Password"
                                        dependencies={["new_password"]}
                                        hasFeedback
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please confirm your new_password!",
                                          },
                                          ({ getFieldValue }) => ({
                                            validator(_, value) {
                                              if (
                                                !value ||
                                                getFieldValue("new_password") ===
                                                value
                                              ) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject(
                                                new Error(
                                                  "New Password and Retype Password field must be same."
                                                )
                                              );
                                            },
                                          }),
                                        ]}
                                      >
                                        <Input type="password" />
                                      </Form.Item>

                                      <Form.Item>
                                        <Button
                                          htmlType="submit"
                                          type="primary"
                                          loading={this.state.btnLoading}
                                          style={{ marginRight: "20px" }}
                                        >
                                          Change password
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            this.setModalVisible(false)
                                          }
                                        >
                                          Cancel
                                        </Button>
                                      </Form.Item>
                                    </Form>
                                  </TabPane>
                                </Tabs>
                              </Card>
                            </Col>
                          </Row>
                        </Modal>
                      </TabPane>
                    }
                    <TabPane tab="Brands" key="3">
                      <div>
                        <Button
                          onClick={() => this.setState({ addBrandModal: true })}
                          type="primary"
                          style={{
                            marginBottom: 16,
                          }}
                        >
                          Add new brand
                        </Button>
                        <Modal
                          destroyOnClose
                          bodyStyle={{
                            marginTop: "-20px",
                            margin: "15px",
                          }}
                          title={
                            <>
                              <h2
                                style={{ marginBottom: "0px", color: "#0B518E" }}
                              >
                                New Brand
                              </h2>
                            </>
                          }
                          centered
                          width="25%"
                          visible={this.state.addBrandModal}
                          onCancel={() =>
                            this.setState({ addBrandModal: false })
                          }
                          maskClosable={false}
                          onOk={() => this.setState({ addBrandModal: false })}
                          footer={false}
                        >
                          <Form
                            ref={this.formRef}
                            name="control-ref"
                            onFinish={this.handleAdd}
                            id="custom-login-id"
                          >
                            <Form.Item
                              name="name"
                              rules={[{ required: true, message: "Please enter brand name" }]}
                            >
                              <Input
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder="Brand name"
                              />
                            </Form.Item>

                            <Form.Item>
                              <Row>
                                <Col span={2}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    onClick={() =>
                                      this.setState({ addBrandModal: false })
                                    }
                                    style={{
                                      width: "80px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    type="primary"
                                    loading={this.state.btnLoading}
                                    htmlType="submit"
                                    style={{
                                      width: "80px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Col>

                                <Col span={2}></Col>
                              </Row>
                            </Form.Item>
                          </Form>
                        </Modal>

                      </div>
                      <EditableTable data={this.state.brandList} />
                    </TabPane>
                    <TabPane tab="Business Unit" key="4">
                      <div>
                        <Button
                          onClick={() => this.setState({ addBuModal: true })}
                          type="primary"
                          style={{
                            marginBottom: 16,
                          }}
                        >
                          Add new business unit
                        </Button>
                        <Modal
                          destroyOnClose
                          bodyStyle={{
                            marginTop: "-20px",
                            margin: "15px",
                          }}
                          title={
                            <>
                              <h2
                                style={{ marginBottom: "0px", color: "#0B518E" }}
                              >
                                New business unit
                              </h2>
                            </>
                          }
                          centered
                          width="25%"
                          visible={this.state.addBuModal}
                          onCancel={() =>
                            this.setState({ addBuModal: false })
                          }
                          onOk={() => this.setState({ addBuModal: false })}
                          maskClosable={false}
                          footer={false}
                        >
                          <Form
                            ref={this.formRef}
                            name="control-ref"
                            onFinish={this.handleAddBusiness}
                            id="custom-login-id"
                          >
                            <Form.Item
                              name="name"
                              rules={[{ required: true, message: "Please enter business name" }]}
                            >
                              <Input
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder="Business name"
                              />
                            </Form.Item>

                            <Form.Item>
                              <Row>
                                <Col span={2}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    onClick={() =>
                                      this.setState({ addBuModal: false })
                                    }
                                    style={{
                                      width: "80px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.btnLoading}
                                    style={{
                                      width: "80px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Col>

                                <Col span={2}></Col>
                              </Row>
                            </Form.Item>
                          </Form>
                        </Modal>

                      </div>
                      <BEditableTable data={this.state.businessUnitList} />
                    </TabPane>
                    <TabPane tab="Channels" key="5">
                      <div>
                        <Button
                          onClick={() => this.setState({ addChannelModal: true })}
                          type="primary"
                          style={{
                            marginBottom: 16,
                          }}
                        >
                          Add new channel
                        </Button>
                        {window.localStorage.getItem("Role") == "AccountManager" ? "" : <Button
                          type="primary"
                          onClick={() =>
                            channelReqData && channelReqData.length > 0 ?
                              this.setState({ channelModalVisible: true }) : ""
                            // : this.setState({ vendorModalVisible: true })
                          }
                          style={{ marginLeft: "20px" }}
                        >
                          Pending request ({channelReqData && channelReqData.length > 0 ? channelReqData && channelReqData.length : 0})
                        </Button>}
                        <Modal
                          destroyOnClose
                          bodyStyle={{
                            marginTop: "-20px",
                            backgroundColor: "#EFEFEF",
                            margin: "15px",
                          }}
                          title={
                            <h2 style={{ marginBottom: "0px" }}>
                              Channel request
                            </h2>
                          }
                          centered
                          width="70%"
                          visible={this.state.channelModalVisible}
                          footer={false}
                          maskClosable={false}
                          onOk={() => this.setState({ channelModalVisible: false })}
                          onCancel={() =>
                            this.setState({ channelModalVisible: false })
                          }
                        >
                          <Col style={{}}>
                            <Card
                              bodyStyle={{ display: "flex" }}
                              bordered={false}
                            >
                              <Col span={4}>{"Retailer Name"}</Col>
                              <Col span={4}>{"First Name"}</Col>
                              <Col span={4}>{"Last Name"}</Col>
                              <Col span={6}>{"Email"}</Col>
                            </Card>
                            <Form
                              // name="validate_other"
                              // {...formItemLayout}
                              onFinish={this.onChannelRequest}
                              // style={{ marginTop: "20px" }}
                              ref={this.formRef}
                              name="control-ref"
                              id="custom-login-id"
                            >
                              {channelReqData &&
                                channelReqData.map((data: any) => (
                                  <>
                                    <Card
                                      bodyStyle={{
                                        display: "flex",
                                        fontWeight: "bold",
                                      }}
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <Col span={4}>{data.username}</Col>
                                      <Col span={4}>{data.first_name}</Col>
                                      <Col span={4}>{data.last_name}</Col>

                                      <Col span={6}>
                                        {data.email}
                                        <Form.Item
                                          name="email"
                                          initialValue={data.email}
                                        >  </Form.Item>
                                      </Col>

                                      <Form.Item
                                        name="status"
                                        initialValue={data.status}
                                      >
                                        <Col style={{ fontSize: "20px" }}>
                                          <Radio.Group
                                            onChange={(e: any) => this.onChange(e, data)}
                                            defaultValue={data.status}
                                          >
                                            <Radio value="accept">Accept</Radio>
                                            <Radio value="decline">Decline</Radio>
                                          </Radio.Group>
                                        </Col>
                                      </Form.Item>
                                    </Card>
                                  </>
                                ))}
                              <Form.Item>
                                <Col style={{ textAlign: "center" }}>
                                  <Button
                                    // onClick={() => (
                                    //   this.setModalVisible(false),
                                    //   // this.setVendorModalVisible(true)
                                    // )}
                                    type="primary"
                                    loading={this.state.btnLoading}
                                    htmlType="submit"
                                    style={{
                                      width: "200px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Form.Item>
                            </Form>
                            {/* <Card
                            bodyStyle={{ display: "flex", fontWeight: "bold" }}
                            style={{ marginBottom: "15px" }}
                          >
                            <Col span={4}>{"Adele"}</Col>
                            <Col span={4}>{"Laurie"}</Col>
                            <Col span={4}>{"blue"}</Col>
                            <Col span={6}>{"alblue@mail.com"}</Col>
                            <Col style={{ fontSize: "20px" }}>
                              <Checkbox>Acept</Checkbox>
                              <Checkbox>Decline</Checkbox>
                            </Col>
                          </Card>
                          <Card
                            bodyStyle={{ display: "flex", fontWeight: "bold" }}
                            style={{ marginBottom: "15px" }}
                          >
                            <Col span={4}>{"lindsay"}</Col>
                            <Col span={4}>{"Dee"}</Col>
                            <Col span={4}>{"Lohan"}</Col>
                            <Col span={6}>{"ldlohan@mail.com"}</Col>
                            <Col style={{ fontSize: "20px" }}>
                              <Checkbox>Acept</Checkbox>{" "}
                              <Checkbox>Decline</Checkbox>
                            </Col>
                          </Card>
                        */}
                          </Col>
                        </Modal>

                        <Modal
                          destroyOnClose
                          bodyStyle={{
                            marginTop: "-20px",
                            margin: "15px",
                          }}
                          title={
                            <>
                              <h2
                                style={{ marginBottom: "0px", color: "#0B518E" }}
                              >
                                New Channel
                              </h2>
                            </>
                          }
                          centered
                          width="25%"
                          visible={this.state.addChannelModal}
                          onCancel={() =>
                            this.setState({ addChannelModal: false })
                          }
                          maskClosable={false}
                          onOk={() => this.setState({ addChannelModal: false })}
                          footer={false}
                        >
                          <Form
                            ref={this.formRef}
                            onFinish={this.handleAddChannels}
                            name="control-ref"
                            id="custom-login-id"
                          >
                            <Form.Item
                              name="name"
                              rules={[{ required: true, message: "Please enter channel name" }]}
                            >
                              <Input
                                style={{
                                  border: "none",
                                  borderBottom: "1px solid black",
                                }}
                                placeholder="Channel name"
                              />
                            </Form.Item>

                            <Form.Item>
                              <Row>
                                <Col span={2}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    onClick={() =>
                                      this.setState({ addChannelModal: false })
                                    }
                                    style={{
                                      width: "80px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={9} style={{ textAlign: "center" }}>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.btnLoading}
                                    style={{
                                      width: "80px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Col>

                                <Col span={2}></Col>
                              </Row>
                            </Form.Item>
                          </Form>
                        </Modal>
                      </div>
                      <CEditableTable data={this.state.channelList} />
                    </TabPane>
                  </>
                }
              </Tabs>
            </View>
          </div>
        </div>
      </KeyboardAvoidingView>
    );
  }
  checkAuth = () => {
    const role = window.localStorage.getItem("Role");
    if (role == "Retailer") {
      this.props.navigation.history.goBack();
    }
    else if (role == "AdminRetailer") {
      this.props.navigation.history.goBack();
    }
  };
  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.checkAuth()
    this.getBrandsList();
    this.getProfilesList();
    this.getRetailersList();
    this.getRetailerdata();
    this.getRetailersRequestData();
    this.getBusinessUnitsList();
    this.getChannels();
    this.isAdminLogin();
    this.getChannel();
    this.getChannelRequestdata()
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  // Customizable Area End
});
