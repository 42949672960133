import React from "react";
import {
  Divider,
  Col,
  Button,
  Modal,
  Checkbox,
  Row,
  Table,
  Form,
  Select,
  Input,
  Radio,
  Pagination,
} from "antd";
import { FilterFilled, PlayCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { CSVLink } from "react-csv";
// Customizable Area Start
import { StyleSheet, View, Platform } from "react-native";
import "../../header/style.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Customizable Area End
export const getChannels = require("../../dashboard/src/Service.web.js");
import MultiSelect from "react-multi-select-component";
import DeletedPromotionControllerWeb, {
  Props,
} from "./DeletedPromotionController.Web";
import EditPromotion from "./EditPromotion";
export const deletedFilterData = require("./Service.web");
import SideNav from "../../../components/src/SideNav";
const TopNav = require("../../../components/src/TopNav").default;
// import ReactDragListView from "react-drag-listview"
const ReactDragListView = require("react-drag-listview").default;
export const getDeletedPromotion = require("./Service.web");
const DragColumn = ReactDragListView?.DragColumn;
import { FormInstance } from "antd/lib/form";
import {
  openNotification,
  openErrorNotification,
} from "../../../components/src/Notification.web";
const { Option } = Select;
export const publishTemp = require("./Service.web");
export const downloadTemp = require("./Service.web");
export const getPromoDataById = require("./Service.web");
export const unpublishedPromotion = require("./Service.web");
class DeletedPromotionWeb extends DeletedPromotionControllerWeb {
  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex: any, toIndex: any) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns,
        });
      },
      nodeSelector: "th",
    };
  }

  // Customizable Area Start

  formRef = React.createRef<FormInstance>();

  csvLinkEl: any = React.createRef();

  onFinishFilter = async (values: any) => {
    const sort = values.sort;
    const Id = localStorage.getItem("promo_id");
    if (values.Year) {
      const data: any = {
        sortType: sort,
        column_name: "year",
        selected_filter: values.Year,
      };
      const res = await deletedFilterData.deletedFilterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length });
      }
    } else if (values.Quarter) {
      const data: any = {
        sortType: sort,
        column_name: "quarter",
        selected_filter: values.Quarter,
      };
      const res = await deletedFilterData.deletedFilterData(data, "quarter");
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length });
      }
    } else if (values["Promo Type"]) {
      const data: any = {
        sortType: sort,
        column_name: "promo_type",
        selected_filter: values["Promo Type"],
      };
      const res = await deletedFilterData.deletedFilterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length });
      }
    } else if (values["Published"]) {
      const data: any = {
        sortType: sort,
        column_name: "is_published",
        selected_filter: values["Published"],
      };
      const res = await deletedFilterData.deletedFilterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length });
      }
    } else if (values["Promotional Id"] == undefined) {
      const data: any = {
        sortType: sort,
        column_name: "promo_id",
        selected_filter: [],
      };
      const res = await deletedFilterData.deletedFilterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length });
      }
    } else if (values["Promotional Name"] == undefined) {
      const data: any = {
        sortType: sort,
        column_name: "promotional_deck_name",
        selected_filter: [],
      };
      const res = await deletedFilterData.deletedFilterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length });
      }
    }
  };

  getTempHeaderData = async () => {
    const data = await getPromoDataById.getPromoDataById();
    if (data.success) {
      this.setState({ tempHeaderData: data.Data, tempHeaderLoading: false });
    }
  };

  // state:any = { showing: true }
  render() {
    const {
      filterBrand,
      filterData,
      filterBusiness,
      tempHeaderData,
    }: any = this.state;
    
    return (
      <>
        <TopNav />
        <div className="main-content-div">
          <SideNav />

          <div className="main-content">
            {/* Customizable Area Start */}
            <View>
              <Modal
                closable={false}
                centered
                width="25%"
                visible={this.state.modalVisible}
                onOk={() => this.setState({ modalVisible: false })}
                onCancel={() => this.setState({ modalVisible: false })}
                maskClosable={false}
                footer={false}
              >
                <Form
                  layout="vertical"
                  ref={this.formRef}
                  name="control-ref"
                  onFinish={this.onFinishFilter}
                >
                  {" "}
                  <Col style={{}}>
                    <Row>
                      {
                        <h2>
                          <PlayCircleOutlined
                            translate="yes"
                            style={{ marginRight: "5px" }}
                          />
                          {this.state.title}
                        </h2>
                      }
                    </Row>
                    <Divider style={{ margin: "0px" }} />
                    <Form.Item name="sort">
                      <Radio.Group>
                        <Row
                          style={{
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <Radio value="A-Z" style={{ marginRight: "5px" }}>
                            {"Sort A-Z"}
                          </Radio>
                        </Row>
                        <Row
                          style={{
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <Radio value="Z-A" style={{ marginRight: "5px" }}>
                            {"Sort Z-A"}
                          </Radio>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name={this.state.title}
                      style={{
                        display:
                          this.state.title == "Year"
                            ? ""
                            : this.state.title == "Quarter"
                              ? ""
                              : this.state.title == "Promo Type"
                                ? ""
                                : this.state.title == "Published"
                                  ? ""
                                  : "none",
                      }}
                    >
                      <Checkbox.Group>
                        {this.state.title == "Year" ? (
                          <>
                            <Divider style={{ margin: "0px" }} />
                            <Row>
                              {
                                <h2>
                                  <FilterFilled
                                    translate="yes"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Filter By
                                </h2>
                              }
                            </Row>
                            {filterData.year &&
                              filterData.year.map((data: any) => (
                                <Row
                                  style={{
                                    marginBottom: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <Checkbox
                                    value={data}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {data}
                                </Row>
                              ))}
                          </>
                        ) : this.state.title == "Quarter" ? (
                          <>
                            <Divider style={{ margin: "0px" }} />
                            <Row>
                              {
                                <h2>
                                  <FilterFilled
                                    translate="yes"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Filter By
                                </h2>
                              }
                            </Row>{" "}
                            {filterData.quarter &&
                              filterData.quarter.map((data: any) => (
                                <Row
                                  style={{
                                    marginBottom: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <Checkbox
                                    value={data}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {data}
                                </Row>
                              ))}
                          </>
                        ) : this.state.title == "Promo Type" ? (
                          <>
                            <Divider style={{ margin: "0px" }} />
                            <Row>
                              {
                                <h2>
                                  <FilterFilled
                                    translate="yes"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Filter By
                                </h2>
                              }
                            </Row>
                            {filterData.promo_type &&
                              filterData.promo_type.map((data: any) => (
                                <Row
                                  style={{
                                    marginBottom: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <Checkbox
                                    value={data}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {data}
                                </Row>
                              ))}
                          </>
                        ) : this.state.title == "Published" ? (
                          <>
                            <Divider style={{ margin: "0px" }} />
                            <Row>
                              {
                                <h2>
                                  <FilterFilled
                                    translate="yes"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Filter By
                                </h2>
                              }
                            </Row>
                            {filterData.is_published &&
                              filterData.is_published.map((data: any) => (
                                <Row
                                  style={{
                                    marginBottom: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <Checkbox
                                    value={data}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {data ? "Yes" : "No"}
                                </Row>
                              ))}
                          </>
                        ) : (
                          ""
                        )}
                      </Checkbox.Group>
                    </Form.Item>
                    <Form.Item>
                      <Row style={{ display: "inherit" }}>
                        <Button
                          onClick={() => this.setModalVisible(false)}
                          style={{ width: "80px", borderRadius: "10px" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            width: "80px",
                            borderRadius: "10px",
                            float: "right",
                          }}
                        >
                          Apply
                        </Button>
                      </Row>
                    </Form.Item>
                  </Col>
                </Form>
              </Modal>
              {ReactDragListView?.DragColumn && (
                <ReactDragListView.DragColumn {...this.dragProps}>
                  <Table
                    columns={this.state.columns}
                    pagination={false}
                    dataSource={this.state.data}
                    bordered
                    loading={this.state.tableLoading}
                    scroll={{ x: 500 }}
                  />
                  <Pagination
                    style={{ float: "right", marginTop: "15px" }}
                    defaultCurrent={1}
                    total={this.state.totalPage}
                    onChange={this.pageChange}
                  />
                </ReactDragListView.DragColumn>
              )}
            </View>
            {/* Customizable Area End */}
          </div>
        </div>
      </>
    );
  }
  pageChange = (page: any) => {
    this.setState({ page: page }, () => {
      this.getCData(page);
    });
  };
  async componentDidUpdate(prevProps: any, prevState: any) {

    if (prevProps.searchResult.search.search !== this.props.searchResult.search.search) {
      this.props.searchResult.search.search == "" ? this.getCData(1) :
        this.getSearch(this.props.searchResult.search.search)
    }
  }
  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getCData(1);
    this.getFilters()

    // Customizable Area End
  }
}
const mapStateToProps = (state: any) => ({
  searchResult: state
});

export default connect(mapStateToProps, {})(DeletedPromotionWeb);

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  // Customizable Area End
});
