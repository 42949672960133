import React from "react";
import { Input, Col, Button, Form, Modal, Row, Table } from "antd";
import { FilterFilled, PlayCircleOutlined } from "@ant-design/icons";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import "../../header/style.css";
// Customizable Area End
import EditTemplatesControllerWeb, {
  Props,
} from "./EditTemplatesController.Web";
import { FormInstance } from "antd/lib/form";
import SideNav from "../../../components/src/SideNav";
// import TopNav from "../../../components/src/TopNav";
// import ReactDragListView from "react-drag-listview"
// export const TopNav = require("../../../components/src/TopNav");

const ReactDragListView = require("react-drag-listview").default;
const TopNav = require("../../../components/src/TopNav").default;
export const getBlanketPercent = require("./Service.web");
const DragColumn = ReactDragListView?.DragColumn;

export default class EditTemplatesWeb extends EditTemplatesControllerWeb {
  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex: any, toIndex: any) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns,
        });
      },
      nodeSelector: "th",
    };
  }
  formRef = React.createRef<FormInstance>();
  onSubmit = async (values: any) => {
   
  };
  handleCancel = async (evt: any) => {
    this.props.navigation.history.push("/DownloadTemplatesWeb");
  };
  getData = async () => {
    const BlanketPercent = await getBlanketPercent.getBlanketPercent();
    if (BlanketPercent.data !== "") {
      this.setState({ blanketPercent: BlanketPercent.data });
    } else {
      console.log("error from userSignUp:", BlanketPercent.error);
    }
  };
  render() {
    const { showing } = this.state;
    return (
      // <KeyboardAvoidingView
      //   behavior={this.isPlatformiOS() ? "padding" : undefined}
      //   style={styles.keyboardPadding}
      // >
      <> 
       <TopNav />
        <div className="main-content-div">
          <div className="main-content">
            <ScrollView keyboardShouldPersistTaps="always">
              <TouchableWithoutFeedback
                testID={"Background"}
                onPress={() => {
                  this.hideKeyboard();
                }}
              >
                {/* Customizable Area Start */}
                <View>
                  <Form
                    // {...layout}
                    style={{ padding: "20px" }}
                    layout="vertical"
                    ref={this.formRef}
                    name="control-ref"
                    onFinish={this.onSubmit}
                    id="custom-login-id"
                    size="middle"
                  >
                    <Row>
                      <Col span={8}>
                        <Form.Item name="template_title" label="Template title">
                          <Input
                            style={{
                              border: "none",
                              borderBottom: "1px solid black",
                            }}
                            // placeholder={this.labeltaxID}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} offset={1}></Col>
                      <Col offset={1}>
                        {" "}
                        <Form.Item>
                          <Button
                            style={{
                              borderColor: "#008cff",
                              // width: "200px",
                              color: "#008cff",
                              marginRight: "3em",
                            }}
                            onClick={this.handleCancel}
                          >
                            Cancel
                          </Button>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Form.Item name="promo_name" label="Name of promotion">
                          <Input
                            style={{
                              border: "none",
                              borderBottom: "1px solid black",
                            }}
                            // placeholder={this.labeltaxID}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} offset={1}>
                        <Form.Item
                          name="dealer_exe_details"
                          label="Dealer Execution Details"
                        >
                          <Input
                            style={{
                              border: "none",
                              borderBottom: "1px solid black",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        
                      </Col>
                      <Col span={8} offset={1}>
                        <Form.Item name="txn_id" label="End Execution Detail">
                          <Input
                            style={{
                              border: "none",
                              borderBottom: "1px solid black",
                            }}
                            // placeholder={this.labeltaxID}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <Modal
                    closable={false}
                    centered
                    width="25%"
                    title={<h3>New Column</h3>}
                    visible={this.state.addColumn}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      // {...layout}
                      style={{ padding: "20px" }}
                      layout="vertical"
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onSubmit}
                      id="custom-login-id"
                      size="middle"
                    >
                      <Col style={{}}>
                        <Row>
                          {" "}
                          <Form.Item name="template_title" label="Column name">
                            <Input
                              style={{
                                border: "none",
                                borderBottom: "1px solid black",
                              }}
                              // placeholder={this.labeltaxID}
                            />
                          </Form.Item>
                        </Row>

                        <Row style={{ display: "inherit" }}>
                          <Form.Item>
                            {" "}
                            <Button
                              onClick={() => this.setAddColumn(false)}
                              // type="primary"
                              style={{ width: "80px", borderRadius: "10px" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => this.setAddColumn(false)}
                              type="primary"
                              style={{
                                width: "80px",
                                borderRadius: "10px",
                                float: "right",
                              }}
                            >
                             Save
                            </Button>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Form>
                  </Modal>
                 <Modal
                    closable={false}
                    centered
                    width="25%"
                    title={<h3>Rename Column</h3>}
                    visible={this.state.modalVisible}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      // {...layout}
                      style={{ padding: "20px" }}
                      layout="vertical"
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onSubmit}
                      id="custom-login-id"
                      size="middle"
                    >
                      <Col style={{}}>
                        <Row>
                          {" "}
                          <Form.Item name="template_title" label="Column name">
                            <Input
                              style={{
                                border: "none",
                                borderBottom: "1px solid black",
                              }}
                              // placeholder={this.labeltaxID}
                            />
                          </Form.Item>
                        </Row>

                        <Row style={{ display: "inherit" }}>
                          <Form.Item>
                            {" "}
                            <Button
                              onClick={() => this.setModalVisible(false)}
                              // type="primary"
                              style={{ width: "80px", borderRadius: "10px" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => this.setModalVisible(false)}
                              type="primary"
                              style={{
                                width: "80px",
                                borderRadius: "10px",
                                float: "right",
                              }}
                            >
                             Save
                            </Button>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Form>
                  </Modal>
                  <Modal
                    closable={false}
                    centered
                    width="25%"
                    title={<h3>Insert Column</h3>}
                    visible={this.state.insertColumn}
                    maskClosable={false}
                    footer={false}
                  >
                    <Form
                      // {...layout}
                      style={{ padding: "20px" }}
                      layout="vertical"
                      ref={this.formRef}
                      name="control-ref"
                      onFinish={this.onSubmit}
                      id="custom-login-id"
                      size="middle"
                    >
                      <Col style={{}}>
                        <Row>
                          {" "}
                          <Form.Item name="template_title" label="Column name">
                            <Input
                              style={{
                                border: "none",
                                borderBottom: "1px solid black",
                              }}
                              // placeholder={this.labeltaxID}
                            />
                          </Form.Item>
                        </Row>

                        <Row style={{ display: "inherit" }}>
                          <Form.Item>
                            {" "}
                            <Button
                              onClick={() => this.setInsertColumn(false)}
                              // type="primary"
                              style={{ width: "80px", borderRadius: "10px" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => this.setInsertColumn(false)}
                              type="primary"
                              style={{
                                width: "80px",
                                borderRadius: "10px",
                                float: "right",
                              }}
                            >
                             Save
                            </Button>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Form>
                  </Modal>
                  {ReactDragListView?.DragColumn &&
                  <ReactDragListView.DragColumn {...this.dragProps}>
                      <Table
                        columns={this.state.columns}
                        pagination={false}
                        dataSource={this.state.data}
                        bordered
                        scroll={{ x: 500 }}
                      />
                    </ReactDragListView.DragColumn>
                  }
                </View>
                {/* Customizable Area End */}
              </TouchableWithoutFeedback>
            </ScrollView>
          </div>
        </div>
     </>
     
     // </KeyboardAvoidingView>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getData();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  // Customizable Area End
});
