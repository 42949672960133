import axios from "axios";
export const configJSON = require("./config");
import {
  openNotification,
  openErrorNotification,
} from "../../../components/src/Notification.web";
const getBrandList = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_catalogue/brands`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};
const getProfileList = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}retailer_vendor_details/get_all_profiles`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};
const getRetailerList = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}retailer_vendor_details/get_retailers`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};
const getRetailerReqData = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${
        configJSON.serverURL
      }retailer_vendor_details/get_retailer_request_data`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};
const retailerRequest = async function(val) {
  const token = localStorage.getItem("token"); 
  try {
    const response = await axios.patch(
      `${
        configJSON.serverURL
      }retailer_vendor_details/retailer_request_status?email=${
        val.email
      }&status=${val.status}`,
      val,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};
const channelRequest = async function(val) {
  const token = localStorage.getItem("token");
  // const data ="Blanket Dollar Off"
  try {
    const response = await axios.patch(
      `${
        configJSON.serverURL
      }channels/approve_channels_requests`,
      val,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const getVendorReqData = async function() {
  const token = localStorage.getItem("token");
  // const data ="Blanket Dollar Off"
  try {
    const response = await axios.get(
      `${
        configJSON.serverURL
      }retailer_vendor_details/get_retailer_request_data`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getChannelReqdata = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}channels/get_retailer_channel_requests`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};

const vendorRequest = async function(val) {
  const token = localStorage.getItem("token"); 
  try {
    const response = await axios.patch(
      `${
        configJSON.serverURL
      }retailer_vendor_details/retailer_request_status?email=${
        val.email
      }&status=${val.status}`,
      val,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const inviteRetailer = async function(val) {
  const token = localStorage.getItem("token");
  const value = {
    data: {
      attributes: val,
    },
  };
  try {
    const response = await axios.post(
      `${configJSON.serverURL}retailer_vendor_details/add_new_retailer`,
      value,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const inviteAcManager = async function(val) {
  const token = localStorage.getItem("token");
  const value = {
    data: {
      attributes: val,
    },
  };
  try {
    const response = await axios.post(
      `${
        configJSON.serverURL
      }retailer_vendor_details/invite_new_account_manager`,
      value,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const inviteCaUser = async function (val) {
  const token = localStorage.getItem("token");
  const value = {
    data: {
      attributes: val,
    },
  };
  try {
    const response = await axios.post(
      `${configJSON.serverURL}retailer_vendor_details/invite_company_user`,
      value,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const inviteVendor = async function (val) {
  const token = localStorage.getItem("token");
  const value = {
    data: {
      attributes: val,
    },
  };
  try {
    const response = await axios.post(
      `${configJSON.serverURL}retailer_vendor_details/invite_new_vendor`,
      value,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const selectRetailer = async function(val) {
  const token = localStorage.getItem("token");
  const value = {
    data: {
      attributes: val,
    },
  };
  try {
    const response = await axios.patch(
      `${configJSON.serverURL}retailer_vendor_details/add_retailer_user`,
      value,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const searchRetailer = async function(val) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${
        configJSON.serverURL
      }retailer_vendor_details/search_retailers?search=${val}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const vendorToggle = async function(val) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${configJSON.serverURL}retailer_vendor_details/${val}/toggle_vendor`,
      "",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const searchVendor = async function(val) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${
        configJSON.serverURL
      }retailer_vendor_details/search_vendors?search=${val}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const deleteProfile = async function(id) {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.delete(
      `${configJSON.serverURL}retailer_vendor_details/profiles/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const getVendersList = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}retailer_vendor_details/get_vendors`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return response;
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const getUsersList = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}retailer_vendor_details`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return response.data;
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const selectUser = async function(data) {
  const token = localStorage.getItem("token");
  const value = {
    data: {
      attributes: data,
    },
  };
  try {
    const response = await axios.post(
      `${configJSON.serverURL}retailer_vendor_details`,
      value,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const selectVendor = async function(data) {
  const token = localStorage.getItem("token");
  const value = {
    data: {
      attributes: {
        vender_id: data,
      },
    },
  };
  try {
    const response = await axios.post(
      `${configJSON.serverURL}retailer_vendor_details/add_new_vender`,
      value,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, response };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const deleteVendor = async function(id) {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.delete(
      `${configJSON.serverURL}retailer_vendor_details/profiles/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const deleteRUser = async function(id) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${configJSON.serverURL}retailer_vendor_details/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const getBusinessUnitList = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${configJSON.serverURL}business_units`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        token: token,
      },
    });
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const addUser = async function(data, type) {
  const token = localStorage.getItem("token");
  const val = {
    data: {
      type: "brand",
      attributes: data,
    },
  };
  try {
    const response =
      type == "add"
        ? await axios.post(`${configJSON.serverURL}get_all_profiles`, val, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          })
        : await axios.put(`${configJSON.serverURL}get_all_profiles/${data}`, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          });
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const edituser = async function(data, id, type) {
  const token = localStorage.getItem("token");
  const val = {
    data: {
      attributes: data,
    },
  };
  try {
    const response =
      type == "add"
        ? await axios.post(
            `${configJSON.serverURL}retailer_vendor_details`,
            val,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                token: token,
              },
            }
          )
        : await axios.put(
            `${configJSON.serverURL}retailer_vendor_details/${id}`,
            val,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                token: token,
              },
            }
          );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const updateUserAvatar = async (values, id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${configJSON.serverURL}retailer_vendor_details/user_avatar/${id}`,
      values,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};
const updateUserPassword = async (values, id) => {
  const token = localStorage.getItem("token");
  try {
    const data = {
      data: {
        attributes: values,
      },
    };
    const response = await axios.put(
      `${
        configJSON.serverURL
      }retailer_vendor_details/user_change_password/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e.response.data.message };
  }
};
const deleteUser = async function(id) {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.delete(
      `${configJSON.serverURL}retailer_vendor_details/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e && e.response && e.response };
  }
};
const getCurrentUserD = async function(data, type) {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${configJSON.serverURL}retailer_vendor_details/${data}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const addBrand = async function(data, type) {
  const token = localStorage.getItem("token");
  const val1 = {
    data: {
      type: "brand",
      attributes: data,
    },
  };
  const val2 = {
    data: {
      attributes: {
        name: data.name,
      },
    },
  };
  try {
    const response =
      type == "add"
        ? await axios.post(
            `${configJSON.serverURL}bx_block_catalogue/brands`,
            val1,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                token: token,
              },
            }
          )
        : await axios.put(
            `${configJSON.serverURL}bx_block_catalogue/brands/${data.id}`,
            val2,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                token: token,
              },
            }
          );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};
const deleteBrand = async function(id) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${configJSON.serverURL}bx_block_catalogue/brands/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const addBusiness = async function(data, type) {
  const token = localStorage.getItem("token");
  const val1 = {
    data: {
      type: "brand",
      attributes: data,
    },
  };
  const val2 = {
    data: {
      attributes: {
        name: data.name,
      },
    },
  };
  try {
    const response =
      type == "add"
        ? await axios.post(`${configJSON.serverURL}business_units`, val1, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          })
        : await axios.patch(
            `${configJSON.serverURL}business_units/${data.id}`,
            val2,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                token: token,
              },
            }
          );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const getChannels = async function() {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("Role");

  try {
    const response =
      role == "AccountManager"
        ? await axios.get(`${configJSON.serverURL}channels/admin_channels`, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          })
        : await axios.get(`${configJSON.serverURL}channels`, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          });
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};

const addChannels = async function(data, type) {
  const token = localStorage.getItem("token");
  const val1 = {
    data: {
      type: "channel",
      attributes: data,
    },
  };
  const val2 = {
    data: {
      attributes: {
        name: data.name,
      },
    },
  };
  try {
    const response =
      type == "add"
        ? await axios.post(`${configJSON.serverURL}channels`, val1, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          })
        : await axios.put(`${configJSON.serverURL}channels/${data.id}`, val2, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              token: token,
            },
          });
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const deleteChannel = async function(id) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${configJSON.serverURL}channels/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const assignedChannelToRetailer = async function(val) {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("Role");
  try {
    const response =
      role == "AccountManager"
        ? await axios.post(
            `${
              configJSON.serverURL
            }channels/invitation_send_for_channel_assign_approve`,
            val,
            {
              headers: {
                token: token,
              },
            }
          )
        : await axios.patch(
            `${
              configJSON.serverURL
            }retailer_vendor_details/assign_channel_to_retailers`,
            val,
            {
              headers: {
                token: token,
              },
            }
          );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const deleteBusiness = async function(id) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${configJSON.serverURL}business_units/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const getRetailerTempData = async function(page) {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${
        configJSON.serverURL
      }retailer_vendor_details/get_all_vendor_promotions?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    
    return { success: false, error: e };
  }
};

const userResetPassLink = async function(payload) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${
        configJSON.serverURL
      }bx_block_forgot_password/passwords/send_password_reset_link_email?id=${payload}`,
      "",
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};

export {
  addBrand,
  updateUserAvatar,
  getRetailerTempData,
  userResetPassLink,
  getChannels,
  deleteBrand,
  getCurrentUserD,
  updateUserPassword,
  deleteRUser,
  deleteUser,
  addBusiness,
  addChannels,
  deleteChannel,
  deleteVendor,
  getVendersList,
  selectVendor,
  getBusinessUnitList,
  getUsersList,
  addUser,
  selectUser,
  getBrandList,
  deleteBusiness,
  getProfileList,
  getRetailerList,
  getRetailerReqData,
  deleteProfile,
  retailerRequest,
  vendorRequest,
  getVendorReqData,
  inviteRetailer,
  inviteVendor,
  inviteAcManager,
  inviteCaUser,
  edituser,
  selectRetailer,
  searchRetailer,
  searchVendor,
  vendorToggle,
  assignedChannelToRetailer,
  getChannelReqdata,channelRequest
};
