import axios from "axios";
import { openNotification, openErrorNotification } from "../../../components/src/Notification.web";
export const configJSON = require("./config");
const companyList = async function () {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}account_block/accounts`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(e && e.response && e.response.data && e.response.data.message,
      "error"
    ); 
    return { success: false, error: e };
  }
};

export { companyList };
