import axios from "axios";
import {
  openNotification,
  openErrorNotification,
} from "../../../components/src/Notification.web";
// import { useHistory } from 'react-router-dom';
export const configJSON = require("./config");
const userSignUp = async function(payload) {
  try {
    const data = {
      data: {
        type: "email_account",
        attributes: payload,
      },
    };
    const response = await axios.post(
      `${configJSON.serverURL}account_block/accounts`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const updateUser = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const data = {
      data: {
        type: "profile",
        attributes: values,
      },
    };
    const response = await axios.put(
      `${configJSON.serverURL}bx_block_profile_bio/profiles/modify`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const updateUserAvatar = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${configJSON.serverURL}bx_block_profile_bio/profiles/avatar`,
      values,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const updateUserPassword = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const data = {
      data: {
        attributes: values,
      },
    };
    const response = await axios.put(
      `${configJSON.serverURL}bx_block_profile_bio/profiles/change_password`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const userSignIn = async function(payload) {
  const token = localStorage.getItem("token");
  try {
    const data = {
      data: {
        type: "email_account",
        attributes: payload,
      },
    };
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_login/login`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getProfile = async () => {
  const token = localStorage.getItem("token");
  try {
    // const data= {
    //   "data": {
    //     "type": "profile",
    //     "attributes": values
    //   }
    // }
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_profile_bio/profiles/profile_detail`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const searchData = async (data, deleted) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      deleted
        ? `${
            configJSON.serverURL
          }bx_block_import_promotion/import_promotion/search_promotions?promotional_deck_name=${data}&deleted_promo=true`
        : `${
            configJSON.serverURL
          }bx_block_import_promotion/import_promotion/search_promotions?promotional_deck_name=${data}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};

export {
  userSignIn,
  userSignUp,
  updateUserAvatar,
  updateUserPassword,
  updateUser,
  getProfile,
  searchData,
};
