import React from "react";

// Customizable Area Start
import {
  // StyleSheet,
  // Text,
  View,
  // TextInput,
  // Button,
  // ScrollView,
  // Platform
  // Image,
  // TouchableOpacity,
  // TouchableWithoutFeedback,
  // KeyboardAvoidingView
} from "react-native";
import { Row,Col } from "antd";
import "../../header/style.css";
// Customizable Area End
const TopNavSignIn = require("../../../components/src/TopNavSignIn").default;
import AcceptInvitationControllerWeb, {
  Props,
} from "./AcceptInvitationController.Web";
import { Spin } from "antd";
// import { FormInstance } from "antd/lib/form";
import { openNotification } from "../../../components/src/Notification.web";
// import {userForgotPass} from "./AcceptInvitationService.web.js"
export const invitedAcUser = require("./AcceptInvitationService.web");
// import axios from "axios";
// const { Option } = Select;
export default class AcceptInvitationWeb extends AcceptInvitationControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  verifyAcc = async () => {
    const email = this.props.navigation.history.location.pathname
      .split("/")
      .pop();
    const token = this.props.navigation.history.location.pathname.split("/")[2];

    const result = await invitedAcUser.invitedAcUser(email, token);
    if (result.success) {
      openNotification(result.message, "success");
      this.props.navigation.history.push("/");
    } else {
      this.props.navigation.history.push("/");
    }
  };
  // formRef = React.createRef<FormInstance>();
  // onSubmit = async (values: any) => {
  //   const result = await userForgotPass.userForgotPass(values);
  //   if (result.success) {
  //     this.props.navigation.history.push("/");
  //     openNotification(result.message,"success")
  //   } else {
  //     console.log("error from userSignUp:", result.error);
  //     // setBtnLoading(false);
  //     // addToast(result.error, ERROR_TOAST);
  //   }
  // };
  render() {
    return (
      <>
        {/* <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      > */}
        <TopNavSignIn />
        {/* <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID={"Background"}
            onPress={() => {
              this.hideKeyboard();
            }}
          > */}
        {/* Customizable Area Start */}
        <Row justify="center" align="middle">
          <Col>
            <Spin style={{ marginTop: "20%" }} />
          </Col>
        </Row>
        {/* Customizable Area End */}
        {/* </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView> */}
      </>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.verifyAcc();
    // Customizable Area End
  }
}
