import axios from "axios";
import {
  openNotification,
  openErrorNotification,
  openErrorNotificationForPromotions,
} from "../../../components/src/Notification.web";
export const configJSON = require("./config");

const importTempt = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion`,
      values,
      {
        headers: {
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    // // openErrorNotification(
    // //   e && e.error && e.error.response && e.error.response.data&& e.error.response.data.message,
    // //   "error"
    // // );
    return { success: false, error: e };
  }
};

const getProfileList = async function() {
  const token = localStorage.getItem("token");
  // const data ="Blanket Dollar Off"
  try {
    const response = await axios.get(
      `${configJSON.serverURL}retailer_vendor_details/get_all_profiles`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const updateTempt = async (values) => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("promotion_id");
  try {
    const response = await axios.patch(
      `${configJSON.serverURL}/bx_block_import_promotion/import_promotion/${id}`,
      values,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotificationForPromotions(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const filterData = async (values) => {
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("promo_type");
  try {
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/filter_data`,
      values,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const deletedFilterData = async (values) => {
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("promo_type");
  try {
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/filter_data?deleted_promo=true`,
      values,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getPromoName = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/get_all_promo_names`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getPId = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/get_all_promo_ids`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getYear = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/get_all_years`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getFilterData = async function(data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/get_all_filter_data?promoId=${data}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getDeleteFilterData = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/get_all_filter_data?promoId=0&deleted_promo=true`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getQuarter = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/get_all_quarters`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const downloadTemp = async (type) => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("promotion_id");
  try {
    if (type == "csv") {
      const response = await axios.get(
        `${configJSON.serverURL}bx_block_import_promotion/import_promotion/download_ppt`,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            token: token,
          },
        }
      );
      return { success: true, ...response.data };
    } else if (type == "pdf") {
      const response = await axios.get(
        `${configJSON.serverURL}bx_block_import_promotion/import_promotion/${id}/download_attachment_file`,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            token: token,
          },
        }
      );
      return { success: true, ...response.data };
    } else {
      const response = await axios.get(
        `${configJSON.serverURL}/bx_block_import_promotion/import_promotion/${id}/download_attachment_and_csv_file`,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            token: token,
          },
        }
      );
      return { success: true, ...response.data };
    }
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};

const getBuyXGetY = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const getHomeTamplateData = async function(page) {
  const token = localStorage.getItem("token");
  // const data ="Blanket Dollar Off"
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getAccountManagerPromotions = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}retailer_vendor_details/get_vendor_promotions`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getTamplateData = async function(type, id, page) {
  const token = localStorage.getItem("token");
  // const data ="Blanket Dollar Off"
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_xmlcsvtemplatebuilder/template_builder/get_templates_data?template_type=${type}&promo_id=${id}&page=${page}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getTamplateData1 = async function(type, id, page) {
  const token = localStorage.getItem("token");
  // const data ="Blanket Dollar Off"
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/list_unpublished_promotions`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const downloadTempData = async function(type) {
  const token = localStorage.getItem("token");
  // const data ="Blanket Dollar Off"
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_xmlcsvtemplatebuilder/template_builder/download_csv_template?template_type=${type}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const publishTemp = async function(data) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("promotion_id");
  try {
    const response = await axios.patch(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/${id}/publish_promotion`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const getPromoDataById = async function() {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("promotion_id");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getBlanketDollar = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};
const getBlanketPercent = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getNewLowerPrice = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getPromotionalSKU = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getTieredDoscount = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getTradeIn = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getWarranty = async function(token) {
  // const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const getDeletedPromotion = async function(page, token) {
  // const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/list_deactivated_promotions?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const deletePromotionData = async function(id) {
  try {
    const token = localStorage.getItem("token");
    const data = { deactivate: true };
    const response = await axios.patch(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/${id}/update_deactivate_promotion`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
const unpublishedPromotion = async function(val) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.patch(
      `${configJSON.serverURL}bx_block_import_promotion/import_promotion/list_unpublished_promotions${val.message}&status=${val.status}`,
      val,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );

    return { success: false, error: e };
  }
};
const getMailOnlineRebate = async function() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_prom_decks_account/prom_decks_accounts/find_companies`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(
      e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    return { success: false, error: e };
  }
};
export {
  getBuyXGetY,
  getMailOnlineRebate,
  getNewLowerPrice,
  getBlanketDollar,
  getTamplateData,
  getTamplateData1,
  getBlanketPercent,
  getWarranty,
  getDeletedPromotion,
  deletePromotionData,
  getQuarter,
  getYear,
  getFilterData,
  getDeleteFilterData,
  getPromoName,
  getPId,
  getTradeIn,
  getPromoDataById,
  unpublishedPromotion,
  getTieredDoscount,
  getPromotionalSKU,
  importTempt,
  updateTempt,
  filterData,deletedFilterData,
  getHomeTamplateData,
  downloadTempData,
  publishTemp,
  downloadTemp,
  getProfileList,
  getAccountManagerPromotions,
};
