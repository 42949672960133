import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { withRouter, NavLink } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
import { CSVLink } from "react-csv";
import {
  Modal,
  // Form,
  Input,
  Button,
  Row,
  Select,
  Radio,
  Col,
  Upload,
  message,
  notification
} from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect } from "react";
import { openNotification, openErrorNotification, openErrorNotificationForPromotions } from "../../../components/src/Notification.web";
export const updateTempt = require("./Service.web.js");
export const downloadTempData = require("./Service.web.js");
export const getPromoDataById = require("./Service.web");
export const getChannels = require("../../dashboard/src/Service.web.js");

const EditPromotion = (props: any) => {
  // const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, setValue] = useState("attachment");
  const [excelData, setExcelData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [duplicateHeaders, setDuplicateHeaders] = useState<any[]>([]);
  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    setIsModalVisible(props.show);
  }, [props.show]);
  const handleOk = () => {
    setSelectedFile(null);
    setSelectedFile1(null);
    setFileName("");
    setFileName1("");
    // setShowDiscard(false);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setSelectedFile1(null);
    setFileName("");
    // setShowDiscard(false);
    setFileName1("");
    setIsModalVisible(false);
  };

  const onFinish = async (values: any, { resetForm }: any) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    // const formData = new FormData();
    formData.append("attachment", selectedFile1);
    formData.append("promotional_deck_name", values.promotional_deck_name);
    formData.append("promo_id", values.promo_id);
    formData.append("promo_type", values.promo_type);
    formData.append("dealer_execution_detail", values.dealer_execution_detail);
    formData.append("end_execution_detail", values.end_execution_detail);
    formData.append("channel", selected.map((data: any) => data.value));

    const result = await updateTempt.updateTempt(formData);

    if (result.success) {
      openNotification(result.message, "success");
      resetForm({
        values: {
          promotional_deck_name: "",
          promo_id: "",
          promo_type: "",
          dealer_execution_detail: "",
          end_execution_detail: "",
        },
      });
      setIsModalVisible(false);
      setSelectedFile1(null);
      setSelectedFile(null);
      // setShowDiscard(false);
      setFileName("");
      setFileName1("");
      setValue("attachment");
      // getCData&&getCData();
      formData.delete("file");
      formData.delete("attachment");
      formData.delete("promotional_deck_name");
      formData.delete("promo_id");
      formData.delete("promo_type");
      formData.delete("channel");

      formData.delete("dealer_execution_detail");
      formData.delete("end_execution_detail");
      localStorage.setItem("UPDATE", "true");
      props.history.push("/HomeLoginMenuWeb");
    } else {
      // setErrorMsg(true);
      // openErrorNotificationForPromotions(result && result.error && result.error.response && result.error.response.data && result.error.response.data.message,
      //   "error"
      // );
      console.log("error from promotion:", result.error);
      downloadExcelData(
        result &&
        result.error &&
        result.error.response &&
        result.error.response.data
      );
      setExcelData(
        result &&
        result.error &&
        result.error.response &&
        result.error.response.data &&
        result.error.response.data.data
      );
    }
  };

  const onChange = (e: any) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFile1, setSelectedFile1] = useState<any>();
  const [fileName, setFileName] = useState("");
  const [fileName1, setFileName1] = useState("");
  // const [showDiscard, setShowDiscard] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  useEffect(() => {
    getData();
    getAllChannels();
  }, []);
  const getData = async () => {
    const data = await getPromoDataById.getPromoDataById();
    setInitialFormValues(data && data.Data);
  };
  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setErrorMsg(false);
  };
  const discardFile = (val: any) => {
    val == "attachment" ? setSelectedFile1(null) : setSelectedFile(null);
    val == "file" ? setFileName("") : setFileName1("");
    // setShowDiscard(false);
  };
  const changeHandler1 = (event: any) => {
    setSelectedFile1(event.target.files[0]);
    setFileName1(event.target.files[0].name);
    // setShowDiscard(true);
  };
  const [errorMsg, setErrorMsg] = useState(false);
  const [selected, setSelected] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [channelList, setchannelList] = useState([]);
  const getAllChannels = async () => {
    const datas = await getChannels.getChannels();
    if (datas) {
      setchannelList(datas && datas.Data ? datas.Data : []);
      setOptions(
        datas &&
        datas.Data.map((data: any) => ({ label: data.name, value: data.id }))
      );
    }

  };
  const downloadExcelData = async (result: any) => {
    if (result.status == "401") {
      await excelDownloadHandler(result);
    }
  };
  const excelDownloadHandler = async (data: any) => {
    // eslint-disable-line
    let datas = [];
    if (data && data?.data?.length > 0) {
      datas =
        data &&
        data.data.map(function (item: any) {
          return { ...item[0], error1: item[1], error2: item[2] };
        });
    } else {
      console.log("Pleas pass the data list");
    }
    let array = datas.map((obj: any) => Object.values(obj));
    const headers = [...data.columns];
    setCsvData(array);
    setHeaders(headers);
    setDuplicateHeaders(headers);
  };
  React.useEffect(() => {
    // debugger;
    if (csvData.length > 0) {
      // @ts-ignore
      document.getElementById("downloadedit").click();
    }
  }, [duplicateHeaders]);
  const promoType = props.type
  return (
    <>
      <CSVLink
        data={csvData}
        headers={headers}
        style={{ display: "none" }}
        filename={`${promoType}.csv`}
        id="downloadedit"
        target="_blank"
      >
        download
      </CSVLink>
      <Modal
        destroyOnClose
        title={<h2 style={{ color: "#1890ff" }}>Edit promotion</h2>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
        footer={false}
      >
        <Formik
          initialValues={initialFormValues}
          enableReinitialize
          // validate={validate}
          onSubmit={onFinish}
        >
          {({ touched, errors, submitForm, values }) => {
            return (
              <Form translate className="needs-validation">
                <Row>
                  <Col span={24}>
                    <label
                      htmlFor="email"
                      style={{ display: "block", marginTop: "15px" }}
                    >
                      Promotional Deck Name
                    </label>{" "}
                    <Field
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        width: "100%",
                      }}
                      type="text"
                      autoComplete="off"
                      name="promotional_deck_name"
                    />
                  </Col>
                </Row>
                {/* </Form.Item>
              <Form.Item label="Select the promo type" name="promo_type"> */}
                <Row>
                  <Col span={24}>
                    {" "}
                    <label
                      htmlFor="email"
                      style={{
                        display: "block",
                        marginTop: "15px",
                        marginBottom: "5px",
                      }}
                    >
                      Select the promo Type
                    </label>
                    <Field
                      style={{
                        // border: "none",
                        // borderBottom: "1px solid black",
                        width: "100%",
                        height: "30px",
                      }}
                      as="select"
                      name="promo_type"
                    >
                      <option value=" ">Select promo type</option>
                      <option value="Blanket Percent Off">
                        Blanket Percent Off
                      </option>
                      <option value="Blanket Dollar Off">
                        Blanket Dollar Off
                      </option>
                      <option value="Buy X Get Y">Buy X Get Y</option>
                      <option value="Mail/Online Rebate">
                        Mail/Online Rebate
                      </option>
                      <option value="New Lower Price">New Lower Price</option>
                      <option value="Promotional SKU">Promotional SKU</option>
                      <option value="Tiered Discounts">Tiered Discounts</option>
                      <option value="Trade In">Trade In</option>
                      <option value="Warranty">Warranty</option>
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <label
                      htmlFor="email"
                      style={{
                        display: "block",
                        marginTop: "15px",
                        marginBottom: "5px",
                      }}
                    >
                      Select the channel
                    </label>
                    <MultiSelect
                      onChange={setSelected}
                      value={selected}
                      // onChange={setSelected}
                      // labelledBy="Select"
                      // onSelect={onSelectOrder}
                      // onRemove={onRemoveOrder}
                      options={options}
                      labelledBy="name"
                    // displayValue="name"
                    // showCheckbox={true}
                    // placeholder="Select channels"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <label style={{ display: "block", marginTop: "15px" }}>
                      Manufacturer Promo ID
                    </label>{" "}
                    <Field
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        width: "100%",
                      }}
                      type="text"
                      autoComplete="off"
                      name="promo_id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {" "}
                    <label
                      htmlFor="email"
                      style={{ display: "block", marginTop: "15px" }}
                    >
                      End Execution Detail
                    </label>
                    <Field
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        width: "100%",
                      }}
                      type="text"
                      autoComplete="off"
                      // placeholder="Enter Full Name"
                      name="end_execution_detail"
                    // className={`form-control site-input ${
                    //  touched.full_name && errors.full_name ? 'is-invalid' : ''
                    // }`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {" "}
                    <label
                      htmlFor="email"
                      style={{ display: "block", marginTop: "15px" }}
                    >
                      Dealer Execution Detail
                    </label>
                    <Field
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        width: "100%",
                      }}
                      type="text"
                      autoComplete="off"
                      // placeholder="Enter Full Name"
                      name="dealer_execution_detail"
                    />
                  </Col>
                </Row>
                <h3 style={{ fontWeight: "bolder", marginTop: "20px" }}>
                  Import File options
                </h3>
                <Row>
                  <span>Click Browser to find the file to import</span>
                </Row>
                <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <span>Select type of file to upload</span>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={15}>Upload Promo Visual file(PPT/PDF)</Col>
                      <Col span={9}>
                        <input
                          type="file"
                          name="attachment"
                          // name="attachment"
                          className="custom-file-input"
                          style={{ width: "100%" }}
                          id="upload-photo"
                          onChange={(e) => { changeHandler1(e); e.target.value = "" }}
                          accept=".pptx,.ppt,.pdf,.ppsx"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col span={15}>Upload Promo Data File (XLS/CSV)</Col>
                      <Col span={9}>
                        <input
                          type="file"
                          name="file"
                          style={{ width: "100%" }}
                          className="custom-file-input"
                          id="upload-photo"
                          onChange={(e) => { changeHandler(e); e.target.value = "" }}
                          accept=".xls,.xlsx,.csv"
                        />
                      </Col>
                    </Row>
                  </Col>
                  {errorMsg ? (
                    <Col>
                      <h3 style={{ color: "red" }}>
                        User must choose csv/xls file
                      </h3>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <h4 style={{ marginTop: "20px" }}>
                  Selected file:{" "}
                  <Row>
                    {fileName}{" "}
                    {fileName ? (
                      <Button
                        type="primary"
                        onClick={() => discardFile("file")}
                        ghost
                        style={{ marginRight: "10px" }}
                      >
                        Discard
                      </Button>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row>
                    {fileName1}{" "}
                    {fileName1 ? (
                      <Button
                        type="primary"
                        onClick={() => discardFile("attachment")}
                        ghost
                        style={{ marginRight: "10px" }}
                      >
                        Discard
                      </Button>
                    ) : (
                      ""
                    )}
                  </Row>
                </h4>

                <Row style={{ marginTop: "30px" }}>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      ghost
                      style={{ marginRight: "10px" }}
                      onClick={() => (
                        setIsModalVisible(false),
                        setFileName(""),
                        setFileName1(""),
                        // setShowDiscard(false),
                        setSelectedFile(null),
                        setSelectedFile1(null)
                      )}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={12} style={{ textAlign: "left" }}>
                    {/* {createPromo ? ( */}
                    <Button
                      type="primary"
                      // htmlType="submit"
                      onClick={submitForm}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal></>
  );
};

export default withRouter(EditPromotion);
