import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  Modal,
  Col,
  Card,
  Row,
  Tabs,
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Select,
} from "antd";
import "react-phone-input-2/lib/style.css";
import logo from './assests/logoTop.png'
const { Option } = Select;
const TopNav = (props) => {
  return (
    <>
      <Row className="main-header" style={{ alignItems: "center", marginBottom: "5rem" }}>

        <Col span={18} className="header-logo cursor_pointer" onClick={()=>props.history.push("/")}>
          <img src={logo} height="30" width="30" style={{ marginRight: "10px" }} />
          <strong>PromoDecks</strong>
        </Col>
        <Col span={3} className="header-search" style={{ textAlign: "end" }}>
          {props.location.pathname == "/" ?"" 
          // <h3
          //   style={{ color: "white", cursor: "pointer" }}
          //   onClick={() => props.history.push("/RequestUser")}
          // >
          //   REQUEST
          // </h3> 
          : <h3
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => (props.history.push("/"), window.localStorage.clear())}
          >
            SIGN IN
          </h3>}
        </Col>
      </Row>
    </>
  );
};

const styles = StyleSheet.create({
  main: {
    backgroundColor: "#0b518e",
  },
});

export default withRouter(TopNav);
