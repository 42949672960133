// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { Row } from 'antd';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import SideNav from '../../components/src/SideNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Sorting from '../../blocks/sorting/src/Sorting';
import XmlCsvTemplateBuilder from '../../blocks/XmlCsvTemplateBuilder/src/XmlCsvTemplateBuilder';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailLoginRegistrationWeb from '../../blocks/email-account-registration/src/EmailLoginRegistration.Web';
import PromoDecksSignUpWeb from '../../blocks/email-account-registration/src/PromoDecksSignUp.Web';
import RequestUserWeb from '../../blocks/email-account-registration/src/RequestUser.Web';
import CompanyProfileWeb from '../../blocks/email-account-registration/src/CompanyProfile.Web';
import MultipleCompanyWeb from '../../blocks/email-account-registration/src/MultipleCompany.Web';
import HomeLoginMenuWeb from '../../blocks/email-account-login/src/HomeLoginMenu.Web';
import VendorAdminDashboardWeb from '../../blocks/dashboard/src/VendorAdminDashboard.Web';
import RetailerAdminDashboardWeb from '../../blocks/dashboard/src/RetailerAdminDashboard.Web';
import RetailerAdminAdministrationWeb from '../../blocks/dashboard/src/RetailerAdminAdministration.Web';
import DownloadTemplatesWeb from '../../blocks/XmlCsvTemplateBuilder/src/DownloadTemplates.Web';
import EditTemplatesWeb from '../../blocks/XmlCsvTemplateBuilder/src/EditTemplates.Web';
import ViewTemplatesWeb from '../../blocks/XmlCsvTemplateBuilder/src/ViewTemplates.Web';
import DeletedPromotionWeb from '../../blocks/XmlCsvTemplateBuilder/src/DeletedPromotion.Web'
import AlertWeb from '../../blocks/XmlCsvTemplateBuilder/src/AlertWeb'
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import DataVisualization from '../../blocks/DataVisualization/src/DataVisualization';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import MatchAlgorithm from '../../blocks/MatchAlgorithm/src/MatchAlgorithm';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.Web.tsx';
import ResetPassword from '../../blocks/forgot-password/src/ResetPassword.Web';
import VerifyEmail from '../../blocks/email-account-registration/src/VerifyEmail.Web';
import AcceptInvitation from '../../blocks/email-account-registration/src/AcceptInvitation.Web';

import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import ProjectTemplates from '../../blocks/ProjectTemplates/src/ProjectTemplates';
import Search from '../../blocks/Search/src/Search';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import DataScraper from '../../blocks/DataScraper/src/DataScraper';
import BlanketDollarOffWeb from '../../blocks/XmlCsvTemplateBuilder/src/BlanketDollarOff.Web';
import TradeInWeb from '../../blocks/XmlCsvTemplateBuilder/src/TradeIn.Web';
import BlanketPercentOffWeb from '../../blocks/XmlCsvTemplateBuilder/src/BlanketPercentOff.Web';
import NewLowerPriceWeb from '../../blocks/XmlCsvTemplateBuilder/src/NewLowerPrice.Web';
import WarrantyWeb from '../../blocks/XmlCsvTemplateBuilder/src/Warranty.Web';
import MailOnlineRebateWeb from '../../blocks/XmlCsvTemplateBuilder/src/MailOnlineRebate.Web';
import PromotionalSKUWeb from '../../blocks/XmlCsvTemplateBuilder/src/PromotionalSKU.Web';
import BuyXGetYWeb from '../../blocks/XmlCsvTemplateBuilder/src/BuyXGetY.Web';
import TieredDiscountsWeb from '../../blocks/XmlCsvTemplateBuilder/src/TieredDiscounts.Web';

const routeMap = {
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  XmlCsvTemplateBuilder: {
    component: XmlCsvTemplateBuilder,
    path: '/XmlCsvTemplateBuilder'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
    exact: true
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Dashboard: { component: Dashboard, path: '/Dashboard' },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  EmailLoginRegistrationWeb: {
    component: EmailLoginRegistrationWeb,
    path: ["/", "/signup"],
    exact: true
  },
  PromoDecksSignUpWeb: {
    component: PromoDecksSignUpWeb,
    path: '/PromoDecksSignUpWeb'
  },
  RequestUserWeb: {
    component: RequestUserWeb,
    path: '/RequestUser'
  },
  VendorAdminDashboardWeb: {
    component: VendorAdminDashboardWeb,
    path: '/VendorAdminDashboardWeb'
  },
  CompanyProfileWeb: {
    component: CompanyProfileWeb,
    path: '/CompanyProfileWeb'
  },
  MultipleCompanyWeb: {
    component: MultipleCompanyWeb,
    path: '/MultipleCompanyWeb'
  },
  HomeLoginMenuWeb: {
    component: HomeLoginMenuWeb,
    path: '/HomeLoginMenuWeb'
  },
  DeletedPromotionWeb: {
    component: DeletedPromotionWeb,
    path: '/DeletedPromotionWeb'
  },
  AlertWeb: {
    component: AlertWeb,
    path: '/AlertWeb'
  },
  BlanketDollarOffWeb: {
    component: BlanketDollarOffWeb,
    path: '/BlanketDollarOffWeb'
  },
  BlanketPercentOffWeb: {
    component: BlanketPercentOffWeb,
    path: '/BlanketPercentOffWeb'
  },
  NewLowerPriceWeb: {
    component: NewLowerPriceWeb,
    path: '/NewLowerPriceWeb'
  },
  WarrantyWeb: {
    component: WarrantyWeb,
    path: '/WarrantyWeb'
  },
  MailOnlineRebateWeb: {
    component: MailOnlineRebateWeb,
    path: '/MailOnlineRebateWeb'
  },
  PromotionalSKUWeb: {
    component: PromotionalSKUWeb,
    path: '/PromotionalSKUWeb'
  },
  TradeInWeb: {
    component: TradeInWeb,
    path: '/TradeInWeb'
  },
  BuyXGetYWeb: {
    component: BuyXGetYWeb,
    path: '/BuyXGetYWeb'
  },
  RetailerAdminDashboardWeb: {
    component: RetailerAdminDashboardWeb,
    path: '/RetailerAdminDashboardWeb'
  },
  RetailerAdminAdministrationWeb: {
    component: RetailerAdminAdministrationWeb,
    path: '/RetailerAdminAdministrationWeb'
  },
  DownloadTemplatesWeb: {
    component: DownloadTemplatesWeb,
    path: '/DownloadTemplatesWeb'
  },
  EditTemplatesWeb: {
    component: EditTemplatesWeb,
    path: '/EditTemplatesWeb'
  },
  ViewTemplatesWeb: {
    component: ViewTemplatesWeb,
    path: '/ViewTemplatesWeb'
  },
  TieredDiscountsWeb: {
    component: TieredDiscountsWeb,
    path: '/TieredDiscountsWeb'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  DataVisualization: {
    component: DataVisualization,
    path: '/DataVisualization'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  MatchAlgorithm: {
    component: MatchAlgorithm,
    path: '/MatchAlgorithm'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/ForgotPassword'
  },
  ResetPassword: {
    component: ResetPassword,
    path: `/reset-password/:token`
  },
  VerifyEmail: {
    component: VerifyEmail,
    path: `/verify-account/:token`
  },
  AcceptInvitation: {
    component: AcceptInvitation,
    path: `/accept-invitation/:token/:email`
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: '/ProjectTemplates'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  DataScraper: {
    component: DataScraper,
    path: '/DataScraper'
  },

  Home: {
    component: HomeScreen,
    path: '/kkk',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: '*/AlertWeb',
  //   modal: true
  // }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded'); 
    return (
      <Row>
        {/* <TopNav /> */}

        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </Row>
    );
  }
}

export default App;
