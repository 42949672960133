import axios from "axios";
import { openNotification, openErrorNotification } from "../../../components/src/Notification.web";
export const configJSON = require("./config");

const userResetPass = async function (payload, token) {
  try {
    const data = {
      "data": payload,
    };
    const response = await axios.put(
      `${configJSON.serverURL}bx_block_forgot_password/passwords/reset_password?token=${token}`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          // token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(e && e.response && e.response.data && e.response.data.message,
      "error"
    );
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

export { userResetPass };
