const defaultState = {
  search: ''
};

const searchReducer = (state = defaultState, action) => {
   
  switch (action.type) {
    case 'GET_SEARCH':
      return {
        ...state,
        search: action.payload
      };
    default:
      return state;
  }
};

export default searchReducer;
