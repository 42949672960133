import React from "react";
import {
  Divider,
  Col,
  Button,
  Modal,
  Checkbox,
  Row,
  Table,
  Input,
  Form,
  Tabs,
  Select,
  Card,
  Avatar,
  Radio,
  Pagination,
} from "antd";
import {
  FilterFilled,
  PlayCircleOutlined,
  SearchOutlined, LoginOutlined,
  UserOutlined, BellFilled
} from "@ant-design/icons";
const { TabPane } = Tabs;
export const logo = require("../assets/logoTop.png");
const { Option } = Select;
import PhoneInput from "react-phone-input-2";
import "../../header/style.css";
import { openNotification } from "../../../components/src/Notification.web";
// Customizable Area Start
import {
  View,
  ScrollView,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import "react-phone-input-2/lib/style.css";
// Customizable Area End
import HomeLoginMenuControllerWeb, {
  Props,
} from "./HomeLoginMenuController.Web";
import { FormInstance } from "antd/lib/form";
import SideNav from "../../../components/src/SideNav";
const TopNav = require("../../../components/src/TopNav").default;
const ReactDragListView = require("react-drag-listview").default;
export const updateUser = require("../../email-account-registration/src/EmailLoginRegistrationService.Web");
export const updateUserAvatar = require("../../email-account-registration/src/EmailLoginRegistrationService.Web");
export const updateUserPassword = require("../../email-account-registration/src/EmailLoginRegistrationService.Web");
export const getProfile = require("../../email-account-registration/src/EmailLoginRegistrationService.Web");
export const searchData = require("../../email-account-registration/src/EmailLoginRegistrationService.Web");
export const filterData = require("../../XmlCsvTemplateBuilder/src/Service.web");

export default class HomeLoginMenuWeb extends HomeLoginMenuControllerWeb {
  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex: any, toIndex: any) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns,
        });
      },
      nodeSelector: "th",
    };
  }
  setModalVisible(modalVisible: any) {
    this.setState({ modalVisible });
  }
  setEditModalVisible(editModalVisible: any) {
    this.setState({ editModalVisible });
  }
  formRef: any = React.createRef<FormInstance>();
  handleSearch = async (e: any) => {
    e.target.value !== "" ? this.getSearch(e.target.value) : this.getCData(1);
  };
  getProfileData = async () => {
    const result = await getProfile.getProfile();
    if (result.success) {
      this.setState({ userData: result.data });
      this.formRef.current?.setFieldsValue({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
      });
      result && result.status == 401 ? this.props.navigation.history.push(`/`) : "";
    } else {
      result.error.response && result.error.response.status == 400
        ? this.props.navigation.history.push(`/`)
        : "";
    }
  };
  onSubmit = async (values: any) => {
    const result = await updateUser.updateUser(values);
    if (result.success) {
      this.getProfileData()
      openNotification("personal info saved successfully", "success");
      this.setEditModalVisible(false);
      this.formRef.current!.resetFields();
    } else {
      console.log("error from updateUser:", result.error);
    }
  };
  onChangePassword = async (values: any) => {
    const result = await updateUserPassword.updateUserPassword(values);
    if (result.success) {
      this.getProfileData()
      this.setEditModalVisible(false);
      this.formRef.current!.resetFields();
    } else {
      console.log("error from updateUserPassword:", result.error);
    }
  };

  changeHandler = (event: any) => {
    this.setState({ selectedFile: event.target.files[0] });
    let reader: any = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({ imagePreviewUrl: reader.result, imgFile: file })
    };

    reader.readAsDataURL(file);
  };

  handleSubmission = async () => {
    const formData = new FormData();
    const data: any = this.state.selectedFile;
    formData.append("attachment", data);
    const result = await updateUserAvatar.updateUserAvatar(formData);
    if (result.success) {
      this.getProfileData()
      this.setEditModalVisible(false);
      this.formRef.current!.resetFields();
    } else {
      console.log("error from updateUserPassword:", result.error);
    }
  };
  // Customizable Area Start
  handleShowOk = () => {
    this.setState({ editModalVisible: false, imagePreviewUrl: "" });
  };
  setPage = (current: any) => {
    this.setState({ page: current })
  }
  handleShowCancel = () => {
    this.setState({ editModalVisible: false, imagePreviewUrl: "" });
  };

  // Customizable Area End
  onFinishFilter = async (values: any) => {
    const sort = values.sort;
    if (values.Year) {
      const data: any = {
        sortType: sort,
        column_name: "year",
        selected_filter: values.Year
      };
      const res = await filterData.filterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length })
      }
    } else if (values.Quarter) {
      const data: any = {
        sortType: sort,
        column_name: "quarter",
        selected_filter: values.Quarter
      };
      const res = await filterData.filterData(data, "quarter");
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length })
      }
    } else if (values["Promo Type"]) {
      const data: any = {
        sortType: sort,
        column_name: "promo_type",
        selected_filter: values["Promo Type"]
      };
      const res = await filterData.filterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length })
      }
    }
    else if (values["Published"]) {
      const data: any = {
        sortType: sort,
        column_name: "is_published",
        selected_filter: values["Published"]
      };
      const res = await filterData.filterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length })
      }
    }
    else if (values["Promotional Id"] == undefined) {
      const data: any = {
        sortType: sort,
        column_name: "promo_id",
        selected_filter: []
      };
      const res = await filterData.filterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length })
      }
    }
    else if (values["Promotional Name"] == undefined) {
      const data: any = {
        sortType: sort,
        column_name: "promotional_deck_name",
        selected_filter: []
      };
      const res = await filterData.filterData(data);
      if (res) {
        this.setModalVisible(false);
        this.setState({ data: res.Data, totalPage: res.length })
      }
    }
  };
  pageChange = (page: any) => {
    this.setState({ page: page }, () => { this.getCData(this.state.page) })
  }
  render() {
    const { userData, filterData, filterQuarter, filterByPromoId, imagePreviewUrl }: any = this.state;

    return (
      <Row style={{ width: "100%", display: "block" }} >
        <Row align="middle" className="main-header flex-nowrap">
          <Col xs={2} sm={0}></Col> <Col
            xs={8}
            sm={6}
            md={8}
            lg={10}
            xl={10}
            xxl={10}
            className="header-logo"
          >
            <Row>
              <div className="logo-container">
                <img src={logo} height="30" width="30" style={{ marginRight: "10px" }} />
                <div>
                  <strong>PromoDecks</strong>
                </div>
              </div>
            </Row>
          </Col>
          <Col
            xs={10}
            sm={15}
            md={15}
            lg={13}
            xl={13}
            xxl={12}
            className="header-account"
          >
            <Row>
              <Col
                xs={0}
                sm={6}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
                className="header-search"
              >
                <Input
                  onChange={(e) => this.handleSearch(e)}
                  name="search"
                  placeholder="Search Promotions"
                  suffix={
                    <SearchOutlined
                      translate={"yes"}
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  }
                />
              </Col>
              <Col
                xs={6}
                sm={6}
                md={10}
                lg={10}
                xl={10}
                xxl={8}
                className="name_container"
                onClick={() => this.setEditModalVisible(true)}
              >
                <Row className="profile_items">
                  <Col span={16}>
                    Hello {userData?.first_name} {userData?.last_name}{" "}
                  </Col>
                  <Col span={4}>
                    <Avatar
                      src={userData?.image}
                      icon={
                        <UserOutlined
                          translate="yes"
                        />
                      }

                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={1}
                sm={1}
                md={2}
                lg={2}
                xl={2}
                xxl={2}
              >
                <BellFilled translate style={{ fontSize: "25px" }} onClick={() => this.props.navigation.history.push("/AlertWeb")} />
              </Col>
              <Col xs={6} sm={6} md={4} lg={4} xl={4} xxl={4}>
                <Button type="text" onClick={() => (localStorage.clear(), this.props.navigation.history.push("/"))} style={{ color: "white", borderColor: "transparent" }}><LoginOutlined translate="yes" />Log Out</Button>
              </Col>
            </Row>
          </Col>
          <Modal
            destroyOnClose
            bodyStyle={{
              marginTop: "-20px",
              margin: "15px",
            }}
            title={
              <h2 style={{ marginBottom: "0px" }}>Edit profile account</h2>
            }
            centered
            width="85%"
            visible={this.state.editModalVisible}
            maskClosable={false}
            footer={false}
            onCancel={this.handleShowCancel}
            onOk={this.handleShowOk}
          >
            <Row>
              <Col span={6} style={{ padding: "10px" }}>
                <Card style={{ textAlign: "center" }}>
                  <Avatar src={userData?.image} size={100} icon={<UserOutlined translate="yes" />} />
                  <Row style={{ justifyContent: "center" }}>
                    <Col>
                      <h1 style={{ marginBottom: "-5px" }}>
                        {userData?.first_name} {userData?.last_name}
                      </h1>
                    </Col>
                  </Row>
                  <Row style={{ justifyContent: "center" }}>
                    <Col>
                      <h4 style={{ marginBottom: "-3px" }}>{userData?.email}</h4>
                    </Col>
                  </Row>
                  <Row style={{ justifyContent: "center" }}>
                    <Col>User Occupation</Col>
                  </Row>
                </Card>
              </Col>

              <Col span={18} style={{ padding: "10px" }}>
                <Card>
                  <Tabs
                    tabBarExtraContent={{
                      left: (
                        <h2 style={{ marginRight: "20px", color: "#1890ff" }}>
                          PROFILE ACCOUNT
                        </h2>
                      ),
                    }}
                  >
                    <TabPane
                      tab={<span className="profileTabs">Personal Info</span>}
                      key="1"
                    >
                      <Form
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={this.onSubmit}
                        size="middle"
                      >
                        <Form.Item
                          label={
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              First Name
                            </span>
                          }
                          name="first_name"
                          rules={[
                            { pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/), message: "Please enter valid first name" }
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder={userData?.first_name}
                          />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Last Name
                            </span>
                          }
                          name="last_name"
                        >
                          <Input
                            type="text"
                            placeholder={userData?.last_name}
                          />
                        </Form.Item>{" "}

                        <Form.Item
                          name="phone_number"
                          initialValue={userData?.phone_number}
                          label={
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Phone Number
                            </span>
                          }
                          rules={[
                            {
                              message: "Please enter your phone number!",
                            },
                          ]}
                        >
                          <PhoneInput
                            value={userData?.phone_number}
                            inputStyle={{
                              height: "32px",
                              width: "inherit",
                              borderRadius: "0px",
                            }}
                            country={"us"}
                          />
                        </Form.Item>
                        <Form.Item
                          name="role"
                          label={
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Role
                            </span>
                          }
                          hasFeedback
                          style={{ width: "60%" }}
                        >
                          <Select disabled
                            placeholder={
                              <span
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                {userData?.role}
                              </span>
                            }
                          >
                            <Option value="Manufacturer">Manufacturer</Option>
                            <Option value="Retailer">Retailer</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            type="primary"
                            style={{ marginRight: "20px" }}
                          >
                            Save Changes
                          </Button>
                          <Button
                            onClick={() => this.setEditModalVisible(false)}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </Form>
                    </TabPane>
                    <TabPane
                      tab={<span className="profileTabs">Change Photo</span>}
                      key="2"
                    >
                      <Row>
                        <p style={{ color: "black" }}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nulla blandit elit eget magna faucibus eleifend.
                          Nam nec magna non massa dictum ultrices id id elit.
                          Donec ac odio erat. Nam sodales sollicitudin mollis.{" "}
                        </p>
                      </Row>

                      <Row>
                        {imagePreviewUrl ? (
                          <img src={imagePreviewUrl} width="164" height="164" />
                        ) : (
                          <Avatar
                            src={userData?.image}
                            shape="square"
                            size={164}
                            icon={<UserOutlined translate="yes" />}
                          />
                        )}

                      </Row>
                      <Row
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                        className="uploadImg"
                      >

                        <input
                          type="file"
                          className="custom-file-input"
                          name="file"
                          onChange={this.changeHandler}
                        />

                      </Row>
                      <Row>
                        <Button
                          type="primary"
                          onClick={this.handleSubmission}
                          style={{ marginRight: "20px" }}
                        >
                          Submit
                        </Button>
                        <Button onClick={() => this.setEditModalVisible(false)}>
                          Cancel
                        </Button>
                      </Row>
                    </TabPane>
                    <TabPane
                      key="3"
                      tab={<span className="profileTabs">Change password</span>}
                    >
                      <Form
                        id="custom-login-id"
                        ref={this.formRef}
                        name="control-ref"
                        layout="vertical"
                        onFinish={this.onChangePassword}
                        size="middle"
                      >
                        <Form.Item
                          label={<span
                            style={{ color: "black", fontWeight: "bold" }}
                          >Current Password</span>}
                          name="current_password"
                          rules={[
                            {
                              required: true,
                              message: "Current Password is required",
                            },
                          ]}
                        >
                          <Input type="password" />
                        </Form.Item>
                        <Form.Item
                          label={<span
                            style={{ color: "black", fontWeight: "bold" }}
                          >New Password</span>}
                          name="new_password"
                          rules={[
                            {
                              required: true,
                              message: "New Password is required",
                            },
                          ]}>
                          <Input type="password" />
                        </Form.Item>
                        <Form.Item
                          label={<span
                            style={{ color: "black", fontWeight: "bold" }}
                          >Re-type Password</span>}
                          name="retype_password"
                          rules={[
                            {
                              required: true,
                              message: "Re-type Password is required",
                            },
                          ]}
                        >
                          <Input type="password" />
                        </Form.Item>

                        <Form.Item>
                          <Button
                            htmlType="submit"
                            type="primary"
                            style={{ marginRight: "20px" }}
                          >
                            Change password
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({ editModalVisible: false })
                            }
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </Form>
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </Modal>
        </Row>
        <div className="main-content-div">
          <SideNav />

          <div className="main-content">
            <Modal
              closable={false}
              centered
              width="25%"
              visible={this.state.modalVisible}
              onOk={() => this.setState({ modalVisible: false })}
              onCancel={() => this.setState({ modalVisible: false })}
              maskClosable={false}
              footer={false}
            >
              <Form
                layout="vertical"
                ref={this.formRef}
                name="control-ref"
                onFinish={this.onFinishFilter}
              >
                {" "}
                <Col style={{}}>
                  <Row>
                    {
                      <h2>
                        <PlayCircleOutlined
                          translate="yes"
                          style={{ marginRight: "5px" }}
                        />
                        {this.state.title}
                      </h2>
                    }
                  </Row>
                  <Divider style={{ margin: "0px" }} />
                  <Form.Item name="sort">
                    <Radio.Group>
                      <Row
                        style={{
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Radio value="A-Z" style={{ marginRight: "5px" }}>
                          {"Sort A-Z"}
                        </Radio>
                      </Row>
                      <Row
                        style={{
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Radio value="Z-A" style={{ marginRight: "5px" }}>
                          {"Sort Z-A"}
                        </Radio>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name={this.state.title}
                    style={{ display: this.state.title == "Year" ? "" : this.state.title == "Quarter" ? "" : this.state.title == "Promo Type" ? "" : this.state.title == "Published" ? "" : "none" }}>
                    <Checkbox.Group>
                      {this.state.title == "Year"
                        ? (<><Divider style={{ margin: "0px" }} /><Row>
                          {
                            <h2>
                              <FilterFilled
                                translate="yes"
                                style={{ marginRight: "5px" }}
                              />
                              Filter By
                            </h2>
                          }
                        </Row>
                          {filterData.year &&
                            filterData.year.map((data: any) => (
                              <Row
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <Checkbox
                                  value={data}
                                  style={{ marginRight: "5px" }}
                                />
                                {data}
                              </Row>
                            ))}</>)
                        : this.state.title == "Quarter" ? (<><Divider style={{ margin: "0px" }} /><Row>
                          {
                            <h2>
                              <FilterFilled
                                translate="yes"
                                style={{ marginRight: "5px" }}
                              />
                              Filter By
                            </h2>
                          }
                        </Row> {filterData.quarter &&
                          filterData.quarter.map((data: any) => (
                            <Row
                              style={{
                                marginBottom: "15px",
                                marginTop: "15px",
                              }}
                            >
                              <Checkbox
                                value={data}
                                style={{ marginRight: "5px" }}
                              />
                              {data}
                            </Row>
                          ))}</>) : this.state.title == "Promo Type" ? (<><Divider style={{ margin: "0px" }} /><Row>
                            {
                              <h2>
                                <FilterFilled
                                  translate="yes"
                                  style={{ marginRight: "5px" }}
                                />
                                Filter By
                              </h2>
                            }
                          </Row>{filterData.promo_type &&
                            filterData.promo_type.map((data: any) => (
                              <Row
                                style={{
                                  marginBottom: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                <Checkbox
                                  value={data}
                                  style={{ marginRight: "5px" }}
                                />
                                {data}
                              </Row>
                            ))}</>) : this.state.title == "Published" ? (<><Divider style={{ margin: "0px" }} /><Row>
                              {
                                <h2>
                                  <FilterFilled
                                    translate="yes"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Filter By
                                </h2>
                              }
                            </Row>{filterData.is_published &&
                              filterData.is_published.map((data: any) => (
                                <Row
                                  style={{
                                    marginBottom: "15px",
                                    marginTop: "15px",
                                  }}
                                >
                                  <Checkbox
                                    value={data}
                                    style={{ marginRight: "5px" }}
                                  />
                                  {data ? "Yes" : "No"}
                                </Row>
                              ))}</>) : ""}
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item>
                    <Row style={{ display: "inherit" }}>
                      <Button
                        onClick={() => this.setModalVisible(false)}
                        style={{ width: "80px", borderRadius: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "80px",
                          borderRadius: "10px",
                          float: "right",
                        }}
                      >
                        Apply
                      </Button>
                    </Row>
                  </Form.Item>
                </Col>
              </Form>
            </Modal>
            <ReactDragListView.DragColumn {...this.dragProps}>
              <Table
                columns={this.state.columns}
                pagination={false}
                dataSource={this.state.data}
                bordered
                loading={this.state.tableLoading}
                scroll={{ x: 500 }}
              />
              <Pagination style={{ float: "right", marginTop: "15px" }} defaultCurrent={1} total={this.state.totalPage} onChange={this.pageChange} />
            </ReactDragListView.DragColumn>
          </div>
        </div>
      </Row>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getCData(1);
    this.getProfileData();
    this.getFilters()
    this.isAdminLogin();
    // Customizable Area End
  }

  async componentDidUpdate() {
    window.localStorage.getItem("UPDATE") == "true"
      ? (this.getCData(1), window.localStorage.setItem("UPDATE", "false"))
      : "";
  }
}
