Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.serverURL="https://promdecks-23137-ruby.b23137.dev.eastus.az.svc.builder.cafe/";
// exports.serverURL="https://775328b1feac.ngrok.io/";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "XmlCsvTemplateBuilder";
exports.labelBodyText = "XmlCsvTemplateBuilder Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End