import React from "react";

// Customizable Area Start
import { StyleSheet, Text, View, Platform } from "react-native";
import "../../header/style.css";
// Customizable Area End
const TopNavSignIn = require("../../../components/src/TopNavSignIn").default;
import PromoDecksSignUpControllerWeb, {
  Props,
} from "./PromoDecksSignUpController.Web";
import { Form, Input, Button, Select, Row, Col } from "antd";
import { FormInstance } from "antd/lib/form";
import { openNotification } from "../../../components/src/Notification.web";
export const userSignUp = require("./PromoDecksSignUpService.web");
export const findCompany = require("./PromoDecksSignUpService.web");

export default class PromoDecksSignUpWeb extends PromoDecksSignUpControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  formRef = React.createRef<FormInstance>();

  onSubmit = async (values: any) => {
    this.setState({ btnLoading: true });
    const result = await userSignUp.userSignUp(values);
    if (result.success) {
      this.setState({ btnLoading: false });
      openNotification(result.message, "success");
      window.localStorage.removeItem("cmpName");
      window.localStorage.removeItem("companyName");
      window.localStorage.removeItem("first_name");
      window.localStorage.removeItem("last_name");
      this.props.navigation.history.push("/");
      window.localStorage.clear();
      window.localStorage.setItem("Role", "");
    } else {
      this.setState({ btnLoading: false });
      console.log("error from userSignUp:", result.error);
    }
  };
  handleChangeFName = async (evt: any) => {
    window.localStorage.setItem(`${evt.target.name}`, evt.target.value);
  };
  handleChangeLName = async (evt: any) => {
    window.localStorage.setItem(`${evt.target.name}`, evt.target.value);
  };
  handleCompanyKeyUp = async (evt: any) => {
    if (evt.target.value.length > 1) {
      window.localStorage.setItem("companyName", evt.target.value);
      setTimeout(async () => {
        this.findCmp();
      }, 3000);
    }
  };
  findCmp = async () => {
    const name = window.localStorage.getItem("companyName");
    const findComp = await findCompany.findCompany(name);
    if (findComp.success) {
      this.props.navigation.history.push("/MultipleCompanyWeb");
    } else {
      this.props.navigation.history.push("/CompanyProfileWeb");
    }
  };
  render() {
    return (
      <>
        {/* <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      > */}
        <TopNavSignIn />
        {/* <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID={"Background"}
            onPress={() => {
              this.hideKeyboard();
            }}
          > */}
        {/* Customizable Area Start */}
        <View style={{ width: "35%", marginLeft: "auto", marginRight: "auto" }}>
          <Row style={{ display: "block" }}>
            <Col style={{ textAlign: "center" }}>
              {this.isPlatformWeb() ? (
                <Text style={styles.signUpText}>Promo Decks Sign Up</Text>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "center", marginTop: "20px" }}>
              <Text style={styles.titleWhySignUp}>{this.labelPromoHeader}</Text>
            </Col>
          </Row>
          <View>
            <Form
              // {...layout}
              ref={this.formRef}
              name="control-ref"
              onFinish={this.onSubmit}
              id="custom-login-id"
              style={{ width: "80%", margin: "auto" }}
            >
              <Form.Item
                name="first_name"
                initialValue={this.state.fName}
                rules={[
                  { required: true, message: "First name is required" },
                  {
                    pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/),
                    message: "Please enter valid first name",
                  },
                ]}
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  name="first_name"
                  onChange={this.handleChangeFName}
                  placeholder={this.labelFirstName}
                />
              </Form.Item>

              <Form.Item
                name="last_name"
                initialValue={this.state.lName}
                rules={[
                  { required: true, message: "Last name is required" },
                  {
                    pattern: (/^(?!.*'.*'.*)[a-zA-Z']+$/) ,
                    message: "Please enter valid last name",
                  },
                ]}
              >
                <Input
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  name="last_name"
                  onChange={this.handleChangeLName}
                  placeholder={this.labelLastname}
                />
              </Form.Item>
              <Form.Item
                name="company_name"
                initialValue={this.state.companyName}
                rules={[
                  { required: true, message: "Company name is required" },
                ]}
              >
                <Input
                  name="company_name"
                  style={{
                    border: "none",
                    borderBottom: "1px solid black",
                  }}
                  placeholder={this.labelCompany}
                  onChange={this.handleCompanyKeyUp}
                />
              </Form.Item>

              <Form.Item
                name="role"
                initialValue={
                  localStorage.getItem("Role") == "retailer"
                    ? 3
                    : localStorage.getItem("Role") == "vendor"
                    ? 4
                    : null
                }
                rules={[{ required: true, message: "role is required" }]}
              >
                <Select
                  disabled={
                    localStorage.getItem("Role") == "" &&
                    window.localStorage.getItem("cmpName")
                      ? false
                      : true
                  }
                  placeholder={
                    <span style={{ color: "black" }}>Select one</span>
                  }
                >
                  <Select.Option value={4}>Manufacturer </Select.Option>
                  <Select.Option value={3}>Retailer </Select.Option>
                </Select>
              </Form.Item>
              {/* <Form.Item
                    name="role"
                    // rules={[{ required: true }]}
                  >
                    <select className="select-dropdown">
                      <option>Select Role</option>
                      <option>Admin</option>
                    </select>
                  </Form.Item> */}
              <Form.Item
              // name="email"
              // rules={[{ required: true }]}
              >
                <Button
                  type="primary"
                  name="Continue"
                  htmlType="submit"
                  loading={this.state.btnLoading}
                  style={{ width: "100%" }}
                >
                  Continue
                </Button>
              </Form.Item>
              {/* <Button
                    // testID={"btnSignUp"}
                    title={this.btnTxtContinue}
                    color="#008cff"
                    {...this.btnContinueProps}
                  /> */}
            </Form>
          </View>
        </View>
        {/* Customizable Area End */}
        {/* </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView> */}
      </>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    // marginBottom: 32,
    fontSize: 16,
    width: 300,
    // marginLeft: "auto",
    // marginRight: "auto",
    textAlign: "center",
    marginVertical: 8,
    // marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    // marginTop: "100px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signInText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    marginRight: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 10,
  },
  signUpText: {
    fontSize: 32,
    color: "#008cff",
    fontWeight: "bold",
    // marginLeft: 50,
    borderBottomWidth: 2,
    borderColor: "#008cff",
    paddingBottom: 5,
  },
  signInTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginRight: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  signUpTextInactive: {
    fontSize: 32,
    color: "#105289",
    fontWeight: "bold",
    marginLeft: 50,
    paddingBottom: 10,
    opacity: 0.5,
  },
  // Customizable Area End
});
