import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import "../../header/style.css";
// Customizable Area End
import DownloadTemplatesControllerWeb, {
  Props,
} from "./DownloadTemplatesController.Web";
// import ReactDragListView from "react-drag-listview";
const ReactDragListView = require("react-drag-listview").default;
import {
  Tabs,
  Divider,
  Card,
  Col,
  Button,
  Modal,
  Checkbox,
  Row,
  Switch,
  Input,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  EyeFilled,
  HomeFilled,
  CarryOutFilled,
  SearchOutlined,
} from "@ant-design/icons";
const TopNav = require("../../../components/src/TopNav").default;
const { TabPane } = Tabs;
const DragColumn = ReactDragListView?.DragColumn;
const { Search } = Input;
export default class DownloadTemplatesWeb extends DownloadTemplatesControllerWeb {
  dragProps: {
    onDragEnd(fromIndex: any, toIndex: any): void;
    nodeSelector: string;
  };
  constructor(props: Props) {
    super(props);
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex: any, toIndex: any) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns,
        });
      },
      nodeSelector: "th",
    };
  }
  setModalVisible(modalVisible: any) {
    this.setState({ modalVisible });
  }
  setDeleteModalVisible(deletemodalVisible: any) {
    this.setState({ deletemodalVisible });
  }
  setVendorModalVisible(vendorModalVisible: any) {
    this.setState({ vendorModalVisible });
  }
  // this.state = {
  //   columns
  // };

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { showing } = this.state;

    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <TopNav />

        <div className="main-content-div">
          {showing ? (
            <div className="sidebar">
              <ul className="sidebar-ui">
                <div>
                  <div>
                    <li className="menuLabel ">
                      <HomeFilled
                        translate="yes"
                        style={{ marginRight: "10px" }}
                      />
                      Home
                    </li>
                  </div>

                  <div style={{ marginTop: "60vh" }}>
                    <li className="menuLabel active">
                      <CarryOutFilled
                        translate="yes"
                        style={{ marginRight: "10px" }}
                      />
                      Administration
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          ) : (
            ""
          )}

          <div className="main-content">
            <ScrollView keyboardShouldPersistTaps="always">
              <TouchableWithoutFeedback
                testID={"Background"}
                onPress={() => {
                  this.hideKeyboard();
                }}
              >
                {/* Customizable Area Start */}
                <View>
                  <h2 style={{ marginTop: "10px" }}>Templates</h2>
                  <Divider style={{ margin: "0px" }} />

                  <Card bodyStyle={{ display: "flex" }} bordered={false}>
                    <Col span={1}>
                      <Checkbox />
                    </Col>
                    <Col span={1}>{"#"}</Col>
                    <Col span={10}>{"Name"}</Col>
                  </Card>
                  <Card
                    bodyStyle={{ display: "flex", fontWeight: "bold" }}
                    style={{ marginBottom: "15px" }}
                  >
                    <Col span={1}>
                      <Checkbox />
                    </Col>
                    <Col span={1}>{"1"}</Col>
                    <Col span={10}>{"Lorem Ipsum"}</Col>
                    <Col span={3}>
                      <Button type="primary">Download</Button>{" "}
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <EyeFilled onClick={() =>
                          this.props.navigation.history.push(
                            "/ViewTemplatesWeb"
                          )
                        } translate="yes" />
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <EditFilled
                        onClick={() =>
                          this.props.navigation.history.push(
                            "/EditTemplatesWeb"
                          )
                        }
                        translate="yes"
                      />
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <DeleteFilled
                        translate="yes"
                        onClick={() => this.setDeleteModalVisible(true)}
                      />
                    </Col>
                  </Card>
                  <Card
                    bodyStyle={{ display: "flex", fontWeight: "bold" }}
                    style={{ marginBottom: "15px" }}
                  >
                    <Col span={1}>
                      <Checkbox />
                    </Col>
                    <Col span={1}>{"2"}</Col>
                    <Col span={10}>{"Lorem Ipsum"}</Col>
                    <Col span={3}>
                      <Button type="primary">Download</Button>{" "}
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <EyeFilled translate="yes" />
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <EditFilled
                        onClick={() =>
                          this.props.navigation.history.push(
                            "/EditTemplatesWeb"
                          )
                        }
                        translate="yes"
                      />
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <DeleteFilled
                        translate="yes"
                        onClick={() => this.setDeleteModalVisible(true)}
                      />
                    </Col>
                  </Card>
                  <Card
                    bodyStyle={{ display: "flex", fontWeight: "bold" }}
                    style={{ marginBottom: "15px" }}
                  >
                    <Col span={1}>
                      <Checkbox />
                    </Col>
                    <Col span={1}>{"3"}</Col>
                    <Col span={10}>{"Lorem Ipsum"}</Col>
                    <Col span={3}>
                      <Button type="primary">Download</Button>{" "}
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <EyeFilled translate="yes" />
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <EditFilled
                        onClick={() =>
                          this.props.navigation.history.push(
                            "/EditTemplatesWeb"
                          )
                        }
                        translate="yes"
                      />
                    </Col>
                    <Col span={1} style={{ fontSize: "20px" }}>
                      <DeleteFilled
                        translate="yes"
                        onClick={() => this.setDeleteModalVisible(true)}
                      />
                    </Col>
                  </Card>
                </View>
                {/* Customizable Area End */}
              </TouchableWithoutFeedback>
            </ScrollView>
          </div>
        </div>
      </KeyboardAvoidingView>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "center",
    marginVertical: 8,
    marginTop: 40,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  // Customizable Area End
});
